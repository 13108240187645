import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

export enum ChipColorClass {
  SOLID_GREEN = 'solid_green',
  OPAQUE_GREEN = 'opaque_green',
  SOLID_YELLOW = 'solid_yellow',
  OPAQUE_YELLOW = 'opaque_yellow',
  SOLID_RED = 'solid_red',
  OPAQUE_RED = 'opaque_red',
  SOLID_GRAY = 'solid_gray',
  OPAQUE_GRAY = 'opaque_gray',
  SOLID_BLUE = 'solid_blue',
  OPAQUE_BLUE = 'opaque_blue',
  SOLID_ACCENT = 'solid_accent',
  OPAQUE_ACCENT = 'opaque_accent',
  SOLID_ORANGE = 'solid_orange',
  OPAQUE_ORANGE = 'opaque_orange',
}
export interface TilledChipConfig {
  text: string;
  toolTip: string;
  color: ChipColorClass;
}
@Component({
  selector: 'tilled-chip',
  templateUrl: './tilled-chip.component.html',
  styleUrls: ['./tilled-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TilledChipComponent implements OnInit, OnChanges {
  @Input() chipConfig: TilledChipConfig;

  public displayStatus: string;
  public colorClass: string;
  public tooltipText: string;

  constructor() {}

  ngOnInit(): void {
    this.computeDisplayStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.computeDisplayStatus();
  }

  private computeDisplayStatus(): void {
    this.colorClass = this.chipConfig?.color as string;
    this.displayStatus = this.chipConfig?.text;
    this.tooltipText = this.chipConfig?.toolTip;
  }
}
