<div class="flex flex-col flex-auto sm:min-w-100">
    <div class="flex justify-end">
        <mat-icon
            style="cursor: pointer"
            (click)="closeDialog()"
            svgIcon="mat_outline:close"
        ></mat-icon>
    </div>
    <form
        data-test-id="invite-form"
        class="w-full"
        [formGroup]="userInviteForm"
    >
        <div class="flex flex-col w-full items-center px-8 mb-4">
            <!-- Title -->
            <tilled-heading-h2 class="pb-6">{{title}}</tilled-heading-h2>
            <!-- Content -->
            <tilled-paragraph-p3
            >{{inviteText}}
            </tilled-paragraph-p3
            >
        </div>
        <div class="grid grid-cols-1 w-full px-8 gap-x-6">
            <div class="flex flex-col col-span-1">
                <tilled-input
                    data-test-id="invite-email"
                    name="email"
                    label="EMAIL ADDRESS"
                    tilledLabel="true"
                    errors="{{ userInviteForm.get('email').hasError('email') }}"
                    errorMessage="Email Address must be valid."
                >
                </tilled-input>
            </div>
        </div>

        <!-- Add Actions -->
        <div class="flex w-full justify-end px-4">
            <tilled-button *ngIf="showCopyUrlButton"
                           class="mr-3"
                           data-test-id="copy-url-button"
                           bgColor="white"
                           buttonText="Copy url"
                           [disabled]="disabled"
                           [whiteText]="false"
                           (buttonClick)="inviteUser('copy')"
            ></tilled-button>
            <tilled-button
                data-test-id="share-via-email-button"
                buttonText="{{ submitButtonText  }}"
                whiteText="true"
                [disabled]="disabled"
                (buttonClick)="inviteUser('email')"
            ></tilled-button>
        </div>
    </form>
    <div *ngIf="showLinkToUsers && accountUserCount > 0"
         class="flex flex-row pt-6 justify-center cursor-pointer underline"
         (click)="navigateToUsers()">
        <tilled-paragraph-p3 secondary="true">view previously invited users</tilled-paragraph-p3>
    </div>
    <div *ngIf="displayAlert$ | async" class="flex justify-center w-full pt-6 px-4">
        <fuse-alert
            [type]="'warn'"
            [appearance]="'outline'"
            [dismissible]="'true'"
            [dismissed]="false"
            [name]="'invitationDialogAlertBox'">
            <span fuseAlertTitle>Error inviting collaborator</span>
            {{alertMessage}}
        </fuse-alert>
    </div>
</div>
