export class ColumnModel {
  key: string;
  name: string;
  order: number;
  propertyType: any;
  canSort: boolean;
  isChip: boolean;
  isProgress: boolean;
  isCopyLink: boolean;
  copyLinkDisplayText: string;
  isAction: boolean;
  isMultiline: boolean;
  isActionList: boolean;
  isCurrency: boolean;
  isDownload: boolean;
  // a more robust solution to fitting columns in the future would be nice, autosizing, etc.
  styling: string;
  headerTooltip: string;
  isShareLink: boolean;
  cellTooltip: (param: any) => string;
  showCellTooltipIcon: boolean;

  constructor(options: Partial<ColumnModel> = {}) {
    this.key = options.key;
    this.name = options.name;
    this.order = options.order || 0;
    this.propertyType = options.propertyType;
    this.canSort = options.canSort || false;
    this.isChip = options.isChip || false;
    this.isProgress = options.isProgress || false;
    this.isCopyLink = options.isCopyLink || false;
    this.copyLinkDisplayText = options.copyLinkDisplayText || '';
    this.isAction = options.isAction || false;
    this.isMultiline = options.isMultiline || false;
    this.isActionList = options.isActionList || false;
    this.isCurrency = options.isCurrency || false;
    this.isDownload = options.isDownload || false;
    this.styling = options.styling;
    this.headerTooltip = options.headerTooltip;
    this.isShareLink = options.isShareLink || false;
    this.cellTooltip = options.cellTooltip;
    this.showCellTooltipIcon = options.showCellTooltipIcon;
  }
}
