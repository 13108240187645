<fuse-card
    data-test-id="metadata-card"
    *ngIf="metadata"
    class="tilled-card-styling col-span-1"
    #fuseCard
>
    <div class="w-full">
        <div class="grid grid-cols-2 w-full">
            <!-- Title-->
            <tilled-heading-h4 class="text-lg font-semibold"
                >Metadata</tilled-heading-h4
            >
            <!-- Edit Metadata -->
            <div class="justify-self-end" *ngIf="displayView">
                <button data-test-id="edit-metadata" [disabled]="disableEdit" (click)="editMetadata()">
                    <mat-icon
                        [matTooltip]="editToolTip"
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:pencil'"
                    ></mat-icon>
                </button>
            </div>
        </div>
        <mat-divider class="w-full mt-3 mb-4"></mat-divider>
        <!-- Metadata Items -->
        <ng-container *ngIf="displayView; else editView">
            <div data-test-id="metadata-list" class="w-full">
                <div
                    *ngFor="let metadata of metadata | keyvalue"
                    class="grid sm:grid-cols-2 grid-cols-1 w-full"
                >
                    <tilled-paragraph-p3
                        class="sm:justify-self-start justify-self-center"
                        [secondary]="true"
                    >
                        {{ metadata.key }}</tilled-paragraph-p3
                    >

                    <tilled-paragraph-p3
                        class="sm:justify-self-end justify-self-center"
                    >
                        {{ metadata.value }}
                    </tilled-paragraph-p3>
                </div>
            </div>
        </ng-container>

        <!-- built in edit -->
        <ng-template #editView>
            <!-- Tasks -->
            <ng-container>
                <div class="mx-2 mt-4 space-y-1.5">
                    <form [formGroup]="metadataForm">
                        <div
                            formArrayName="items"
                            *ngFor="
                                let metadata of metadataForm.get('items')[
                                    'controls'
                                ];
                                let i = index
                            "
                        >
                            <div [formGroupName]="i" class="flex flex-row">
                                <div
                                    class="
                                        group
                                        flex flex-row
                                        w-full
                                        items-center
                                        mr-6
                                    "
                                >
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="
                                            flex flex-col
                                            w-full
                                            px-1
                                            py-0.5
                                            mr-6
                                        "
                                    >
                                        <mat-label class="text-lg font-medium"
                                            >Key</mat-label
                                        >
                                        <input
                                            class="text-md text-secondary"
                                            matInput
                                            formControlName="key"
                                            placeholder="Key"
                                            data-test-id="metadata-key"
                                        />
                                    </mat-form-field>
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="
                                            flex flex-col
                                            w-full
                                            px-1
                                            py-0.5
                                            mr-6
                                        "
                                    >
                                        <mat-label class="text-lg font-medium"
                                            >Value</mat-label
                                        >
                                        <input
                                            class="text-md text-secondary"
                                            matInput
                                            formControlName="value"
                                            placeholder="Value"
                                            data-test-id="metadata-value"
                                        />
                                    </mat-form-field>
                                    <mat-icon
                                        (click)="removeMetadataRow(i)"
                                        data-test-id="metadata-delete"
                                        >delete</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>

            <!-- Add Actions -->
            <ng-container>
                <div class="flex flex-row items-center justify-between mt-4">
                    <!-- Add Item -->
                    <tilled-button
                        data-test-id="metadata-addBtn"
                        bgColor="accent"
                        [rounded]="true"
                        buttonText="Add Item"
                        (buttonClick)="addMetadataItem()"
                    ></tilled-button>
                    <div clas="flex flex-col justify-end">
                        <!-- Cancel -->
                        <tilled-button
                            class="mr-3"
                            data-test-id="metadata-cancelBtn"
                            bgColor="white"
                            textColor="grey-300"
                            [rounded]="true"
                            buttonText="Cancel"
                            (buttonClick)="closeMetadata()"
                        ></tilled-button>

                        <!-- Save -->
                        <tilled-button
                            data-test-id="metadata-saveBtn"
                            bgColor="primary"
                            [rounded]="true"
                            buttonText="Save"
                            (buttonClick)="closeMetadata(metadataForm)"
                        ></tilled-button>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</fuse-card>
<div class="flex flex-auto w-full">
    <!-- No templates -->
    <div *ngIf="!metadata" class="w-full">
        <!-- Admin no templates-->
        <dashed-line-container>
            <div
                data-test-id="metadata-placeholder-card"
                class="tracking-tight text-center my-[18%]"
            >
                <div class="mt-4 mb-4 mx-4 text-2xl font-bold">
                    Metadata has not been created.
                </div>

                <tilled-button
                    data-test-id="add-metadata"
                    bgColor="primary"
                    (buttonClick)="editMetadata()"
                    buttonText="Add Metadata"
                    [disabled]="disableAdd"
                >
                </tilled-button>
            </div>
        </dashed-line-container>
    </div>
</div>
