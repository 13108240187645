<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div 
    class="fixed m-auto left-0 right-0 top-20 z-[99999] sm:px-0 px-6 sm:w-fit w-full sm:max-w-[50%] max-w-full"
>
    <tilled-alert></tilled-alert>
</div>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

</div>
