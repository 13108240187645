import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Params } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';
import { DateRangeHeaderComponent } from './date-range-header/date-range-header.component';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit {
  @Input() initialStart?: Date;
  @Input() initialEnd?: Date;
  @Input() hasDate?: boolean;
  @Output() setDateRange: EventEmitter<DatePickerChangedEvent> = new EventEmitter();
  public minDate?: Date; // Rename to 'start'?
  public maxDate?: Date; // Rename to 'end'?

  readonly DateRangeHeaderComponent = DateRangeHeaderComponent;

  // Add these to <mat-date-range-input [max]="absoluteMax" [min]="absoluteMin">
  // To limit dates
  // public absoluteMax = new Date();
  // public absoluteMin = new Date(2020, 1, 1);

  filterForm = new FormGroup({
    minDate: new FormControl(),
    maxDate: new FormControl(),
  });

  private dateChanged = new Subject<DatePickerChangedEvent>();

  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // const tomorrow = new Date();
    // tomorrow.setDate(tomorrow.getDate() + 1);
    // this.absoluteMax = tomorrow; // Tomorrow to ensure we handle UTC timezones

    const dateChangedDebounced = this.dateChanged.pipe(debounceTime(50));
    dateChangedDebounced.subscribe((event: DatePickerChangedEvent) => {
      this.setDateRange.emit(event);
    });

    if (this.initialStart) {
      this.filterForm.patchValue({ minDate: this.initialStart });
    }
    if (this.initialEnd) {
      this.filterForm.patchValue({ maxDate: this.initialEnd });
    }

    this.processQueryParams(this._activatedRoute.snapshot.queryParams);
  }

  startDateChanged(e: MatDatepickerInputEvent<Date, unknown>): void {}

  endDateChanged(e: MatDatepickerInputEvent<Date, unknown>): void {
    this.minDate = this.filterForm.value?.minDate
      ? new Date(new Date(this.filterForm.value.minDate).setHours(0, 0, 0, 0)) //start of day
      : null;
    this.maxDate = this.filterForm.value?.maxDate
      ? new Date(new Date(this.filterForm.value.maxDate).setHours(23, 59, 59, 59)) // end of day
      : null;

    const event: DatePickerChangedEvent = {
      minDate: this.minDate,
      maxDate: this.maxDate,
    };

    // Strange behavior:
    // 1. If maxDate is not null, when the TimePeriodPanel selects dates,
    //    this endDateChanged event fires with a 'null' value first
    //    and then fires *again* with a non-null value.
    // 2. If maxDate is null, when the TimePeriodPanel selects dates,
    //    this endDateChanged event fires only once, correctly.
    // We are solving (1) with a 50ms debounce.
    this.dateChanged.next(event);

    this.hasDate = true;
  }

  clearDateRange(): void {
    this.filterForm.reset();
    this.minDate = null;
    this.maxDate = null;
    this.setDateRange.emit({
      minDate: this.minDate,
      maxDate: this.maxDate,
    });
    this.hasDate = false;
  }

  /**
   * Takes query params and updates the form input fields and then updates the route after we've
   * filtered the params
   *
   * @param params
   * @returns
   */
  processQueryParams(params: Params): void {
    if (Object.keys(params).length > 0) {
      if (params.minDate && params.maxDate) {
        this.minDate = new Date(params.minDate);
        this.maxDate = new Date(params.maxDate);
        this.filterForm.patchValue({ minDate: this.minDate });
        this.filterForm.patchValue({ maxDate: this.maxDate });
        this.hasDate = true;
      }
    }
  }
}
export interface DatePickerChangedEvent {
  minDate?: Date;
  maxDate?: Date;
}
