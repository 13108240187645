<div class="overflow-y-auto max-h-[100vh] sm:min-w-120 w-fit px-4">
    <form-card
        (exitClick)="closeDialog()"
        [backButton]="false"
        [submitButton]="true"
        (submitClick)="generatePaymentLink()"
        [submitDisabled]="paymentLinkForm.invalid || (creatingPaymentLink$ | async)"
        submitText="Generate Payment Link"
    >
        <form
            [formGroup]="paymentLinkForm"
        >
            <div data-test-id="modal-header" class="grid w-full justify-items-center text-center pb-2">
                <!-- Title -->
                <tilled-heading-h2 class="pb-2">Create a Payment Link</tilled-heading-h2>
                <tilled-paragraph-p3 class="pb-6"
                    >Enter payment details below to generate a unique payment link.</tilled-paragraph-p3>
            </div>
            <div>
                <ng-container *ngIf="!isMerchant">
                    <div class="pt-8 w-full">
                        <tilled-label-l1 secondary="true"
                            >Merchant</tilled-label-l1
                        >
                    </div>
                    <app-merchant-selector
                        class="flex flex-col w-full -mt-3"
                        includeAllOption="true"
                        inputAppearance="standard"
                        [includeSearch]="false"
                        (setMerchantAccount)="parseAccountData($event)"
                    >
                    </app-merchant-selector>
                </ng-container>
                <div *ngIf="!disableCurrency && availableCurrencies">
                    <tilled-label-l1 secondary="true"
                            >Currency</tilled-label-l1
                        >
                    <mat-form-field floatLabel="always" appearance="standard" class="w-full pb-4 -mt-4">
                        
                        <mat-select
                            class="text-p3 text-secondary"
                            formControlName="currency"
                            [(value)]="selectedCurrency"
                            
                            placeholder="Select a currency"
                            data-test-id="currency"
                        >
                        <mat-option *ngFor="let currency of availableCurrencies | keyvalue" [value]="currency.key"> 
                            {{currency.value}}
                        </mat-option>
                        </mat-select>
                        <mat-error class="text-sm" color="warn" *ngIf="paymentLinkForm.get('currency').hasError('required')">A currency is required</mat-error>
                    </mat-form-field>
                </div>
                
                <div
                    *ngIf="availablePaymentMethods"
                    class="flex flex-col"
                    formGroupName="paymentMethodCheckboxGroup">
                    <tilled-label-l1 secondary="true" class="pb-3"
                        >Payment Options</tilled-label-l1
                    >
                    <mat-checkbox
                        [ngClass]="disableCard ? 'hidden' : ''"
                        data-test-id="card-checkbox"
                        class="text-p3 pb-2"
                        [color]="'primary'"
                        [formControlName]="'cardCheckbox'"
                    >
                        <tilled-paragraph-p3>Credit and debit card</tilled-paragraph-p3>
                    </mat-checkbox>
                    <mat-checkbox
                        [ngClass]="disableAch ? 'hidden' : ''"
                        data-test-id="ach-checkbox"
                        class="text-p3 pb-2"
                        [color]="'primary'"
                        [formControlName]="'achCheckbox'"
                    >
                        <tilled-paragraph-p3>ACH debit</tilled-paragraph-p3>
                    </mat-checkbox>
                    <mat-checkbox
                        [ngClass]="disableEft ? 'hidden' : ''"
                        data-test-id="eft-checkbox"
                        class="text-p3 pb-2"
                        [color]="'primary'"
                        [formControlName]="'eftCheckbox'"
                    >
                        <tilled-paragraph-p3>EFT debit</tilled-paragraph-p3>
                    </mat-checkbox>
                    <mat-error class="text-sm" color="warn" *ngIf="paymentLinkForm.get('paymentMethodCheckboxGroup').hasError('requiresCheckbox')">
                        At least one payment method is required</mat-error>
                </div>

                <mat-form-field
                    floatLabel="always"
                    appearance="standard"
                    class="flex flex-col w-full pb-2"
                >
                    <tilled-label-l1 secondary="true"
                        >Payment Description</tilled-label-l1>
                    <input
                        data-test-id="payment-description"
                        class="text-p3 text-tilled-primary pt-4"
                        matInput
                        formControlName="description"
                        placeholder="Enter the name of the product or service"
                    />
                    <mat-error class="text-sm" color="warn" *ngIf="paymentLinkForm.get('description').hasError('required')"
                        >A payment description is required</mat-error>
                </mat-form-field>
                <div class="-mb-4">
                    <outline-input
                        name="amount"
                        showCurrency="'true'"
                        [errors]="paymentLinkForm.get('amount').hasError('required') && paymentLinkForm.get('amount').touched"
                        label="Amount"
                        inputTextStyle="mb-2 text-tilled-primary py-1"
                        mask="separator.2"
                        data-test-id="payment-amount"
                    >
                        <div
                            class="w-full text-sm text-warn sm:pt-8"
                            *ngIf="paymentLinkForm.get('amount').invalid"
                        >
                            A valid payment amount is required
                        </div>
                    </outline-input>
                    <tilled-paragraph-p3 styles="text-tilled-neutral-400" class="relative sm:-top-10 -top-4">
                        Enter amount for the product or service</tilled-paragraph-p3>
                </div>
            </div>
        </form>
    </form-card>
</div>
