<div class="overflow-y-auto max-h-[100vh] sm:min-w-120 w-fit -mb-8">
    <mat-stepper #stepper cdk-scrollable labelPosition="bottom">
        <mat-step label="New Payment" color="primary">
            <form-card
                (exitClick)="closeDialog()"
                (nextClick)="nextPage()"
                [backButton]="false"
                [nextButton]="true"
                [nextDisabled]="newPaymentForm.invalid"
            >
                <form
                    [formGroup]="newPaymentForm"
                >
                    <div data-test-id="modal-header" class="grid w-full justify-items-center text-center pb-2">
                        <!-- Title -->
                        <tilled-heading-h2 class="pb-2">Create New Payment</tilled-heading-h2>
                        <tilled-paragraph-p3 class="pb-2"
                            >Enter payment details below.</tilled-paragraph-p3>
                    </div>
                    <div>
                        <ng-container *ngIf="!isMerchant">
                            <div class="pt-8 w-full">
                                <tilled-label-l1 secondary="true"
                                    >Merchant</tilled-label-l1
                                >
                            </div>
                            <app-merchant-selector
                                class="flex flex-col w-full -mt-3"
                                includeAllOption="true"
                                inputAppearance="standard"
                                [includeSearch]="false"
                                (setMerchantAccount)="
                                    merchantAccountChanged($event)
                                "
                            >
                            </app-merchant-selector>
                        </ng-container>

                        <div [ngClass]="disablePaymentMethod ? 'hidden' : ''">
                            <mat-form-field floatLabel="always" appearance="standard" class="w-full -mt-4">
                                <tilled-label-l1 secondary="true"
                                    >Payment Method</tilled-label-l1
                                >
                                <mat-select
                                    class="text-p3 text-secondary pt-4"
                                    formControlName="paymentMethod"
                                    [(value)]="selectedPaymentMethod"
                                    [disabled]="disablePaymentMethod"
                                    placeholder="Select a payment method"
                                    data-test-id="payment-method"
                                >
                                    <mat-option *ngFor="let paymentMethod of availablePaymentMethods | keyvalue" [value]="paymentMethod.key"> 
                                        {{paymentMethod.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="text-sm" color="warn" *ngIf="newPaymentForm.get('paymentMethod').hasError('required')">A payment method is required</mat-error>
                            </mat-form-field>
                        </div>

                        <div [ngClass]="disableCurrency ? 'hidden' : ''">
                            <mat-form-field floatLabel="always" appearance="standard" class="w-full -mt-4 pb-4">
                                <tilled-label-l1 secondary="true" class=""
                                    >Currency</tilled-label-l1
                                >
                                <mat-select
                                    class="text-p3 text-secondary pt-4"
                                    formControlName="currency"
                                    [(value)]="selectedCurrency"
                                    [disabled]="disableCurrency"
                                    placeholder="Select a currency"
                                    data-test-id="currency"
                                >
                                <mat-option *ngFor="let currency of availableCurrencies | keyvalue" [value]="currency.key"> 
                                    {{currency.value}}
                                </mat-option>
                                </mat-select>
                                <mat-error class="text-sm" color="warn" *ngIf="newPaymentForm.get('currency').hasError('required')">A currency is required</mat-error>
                            </mat-form-field>
                        </div>

                        <outline-input
                            name="amount"
                            showCurrency="'true'"
                            [errors]="newPaymentForm.get('amount').hasError('required') && newPaymentForm.get('amount').touched"
                            label="Payment Amount"
                            inputTextStyle="mb-2 text-tilled-primary py-1"
                            mask="separator.2"
                            data-test-id="payment-amount"
                        >
                            <div
                                class="text-sm text-warn pt-1"
                                *ngIf="newPaymentForm.get('amount').invalid"
                            >
                                A valid payment amount is required
                            </div>
                        </outline-input>
                    </div>
                </form>
            </form-card>
        </mat-step>

        <mat-step label="Collect Payment Details">
            <!-- Credit Card -->
            <div
                
                [ngClass]="selectedPaymentType == 'card' ? 
                    'grid sm:min-w-[700px] min-w-100 visible' : 
                    'hidden'"
            >
                <form-card
                    (exitClick)="closeDialog()"
                    (backClick)="prevPage()"
                    [backButton]="true"
                    [submitButton]="true"
                    (submitClick)="collectCardPayment()"
                    [submitDisabled]="cardPaymentForm.invalid || tilledCardForm?.invalid || (submittingPayment$ | async)"
                    [submitText]="'Collect Payment'"
                    [displayAlert]="alertDialogError$ | async"
                    [showAlert$]="alertDialogError$"
                    [alertTitle]="errorTitle"
                    [alertMessage]="errorMessage"
                >
                    <form
                        [formGroup]="cardPaymentForm"
                    >
                        <div data-test-id="credit-card-header" class="grid min-w-fit justify-items-center px-4 pb-10">
                            <!-- Title -->
                            <div class="flex flex-cols pb-2">
                            <tilled-heading-h2>{{pageTitle}}</tilled-heading-h2>
                            <tilled-heading-h2 primaryHighlight="true">&nbsp;{{newPaymentForm.get('amount').value * 100 | minorUnitsToCurrency}}</tilled-heading-h2>
                            </div>
                            <tilled-paragraph-p3
                                >Enter the customer’s payment method information</tilled-paragraph-p3>
                        </div>
                        <div class="flex flex-col">
                            <div class="grid sm:grid-cols-4 grid-cols-2 w-[full] gap-x-6">
                                <div class="flex flex-col col-span-2 -mb-2">
                                    <div
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true" class="pb-1.5"
                                            >Credit Card Number</tilled-label-l1>
                                        <div class="flex flex-row">
                                            <div class="inputField" id="card-number-element"></div>
                                            <div id="card-suffix" class="flex w-[40px] h-[40px] items-center -ml-10">
                                                <mat-icon>credit_card</mat-icon>
                                            </div>
                                        </div>
                                        <div class="text-sm text-warn pt-1" *ngIf="showCardNumberError; else noCardNumberError" @fadeIn
                                            >A valid credit card number is required</div>
                                        <ng-template #noCardNumberError>
                                            <div class="text-sm pt-1">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="flex flex-col col-span-1 -mb-2 w-[170px]">
                                    <div
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true" class="pb-1.5"
                                            >Expiration</tilled-label-l1>
                                        <div class="inputField" id="card-expiration-element"></div>
                                        <div class="text-sm text-warn pt-1" *ngIf="showExpirationError; else noExpirationError" @fadeIn
                                            >A valid expiration date is required</div>
                                        <ng-template #noExpirationError>
                                            <div class="text-sm pt-1">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="flex flex-col col-span-1 -mb-2 w-[170px]">
                                    <div
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true" class="pb-1.5"
                                            >CVV</tilled-label-l1>
                                        <div class="inputField" id="card-cvv-element"></div>
                                        <div class="text-sm text-warn pt-1" *ngIf="showCvvError; else noCvvError" @fadeIn
                                            >A valid CVV number is required</div>
                                        <ng-template #noCvvError>
                                            <div class="text-sm pt-1">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </div>
                                <!-- new row -->
                                <div class="flex flex-col col-span-2 -mt-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Name on Card</tilled-label-l1>
                                        <input
                                            data-test-id="cardholder-name"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="cardholderName"
                                            placeholder=""
                                        />
                                        <mat-error class="text-sm" color="warn" *ngIf="cardPaymentForm.get('cardholderName').hasError('required')"
                                            >A name on card is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex flex-col col-span-2 -mt-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Billing Zip</tilled-label-l1>
                                        <input
                                            data-test-id="billing-zip"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="postalCode"
                                            placeholder="12345"
                                            maxlength="10"
                                        />
                                        <mat-error class="text-sm" color="warn" *ngIf="cardPaymentForm.get('postalCode').hasError('required')"
                                            >A billing ZIP is required</mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                            <!-- end of Card Info 4 col grid-->

                            <!-- start of Billing Address toggle -->
                            <div class="pt-6">
                                <mat-slide-toggle
                                    color="primary"
                                    (change)="cardAddressToggled($event)"
                                    [(ngModel)]="cardAddressIsChecked"
                                    [ngModelOptions]="{ standalone: true }"
                                    data-test-id="billing-address-toggle"
                                >
                                    <tilled-paragraph-p3
                                        >Billing Address</tilled-paragraph-p3
                                    >
                                </mat-slide-toggle>
                                <tilled-paragraph-p4>Optional</tilled-paragraph-p4>
                                <div *ngIf="cardAddressIsChecked" @expandCollapse>
                                    <div class="grid sm:grid-cols-4 grid-cols-1 w-full gap-x-6">
                                        <div class="flex flex-col col-span-2 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Address line 1</tilled-label-l1>
                                                <input
                                                    data-test-id="billing-address-1"
                                                    class="text-p3 text-tilled-primary pt-4"
                                                    matInput
                                                    formControlName="street1"
                                                    placeholder=""
                                                />
                                            </mat-form-field>
                                        </div>
                                        <div class="flex flex-col col-span-2 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Address line 2</tilled-label-l1>
                                                <input
                                                    data-test-id="billing-address-2"
                                                    class="text-p3 text-tilled-primary pt-4"
                                                    matInput
                                                    formControlName="street2"
                                                    placeholder=""
                                                />
                                            </mat-form-field>
                                        </div>
                                        <!-- new row -->
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Country</tilled-label-l1>
                                                <mat-select
                                                    data-test-id="billing-country"
                                                    class="text-p3 text-secondary pt-4"
                                                    formControlName="country"
                                                    placeholder=""
                                                >
                                                    <mat-option *ngFor="let countryAndCode of countryCodeMap | keyvalue: originalOrder" [value]="countryAndCode.value"> 
                                                        {{countryAndCode.key}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >State</tilled-label-l1>
                                                <mat-select
                                                    data-test-id="billing-state"
                                                    class="text-p3 text-secondary pt-4"
                                                    formControlName="state"
                                                    placeholder=""
                                                >
                                                    <mat-option *ngFor="let stateAndCode of stateCodeMap | keyvalue" [value]="stateAndCode.key"> 
                                                        {{stateAndCode.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex flex-col col-span-2 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >City</tilled-label-l1>
                                                <input
                                                    data-test-id="billing-city"
                                                    class="text-p3 text-tilled-primary pt-4"
                                                    matInput
                                                    formControlName="city"
                                                    placeholder=""
                                                />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- end of Billing Address 4 column grid -->
                                </div>
                            </div>
                            <!-- end of Billing Address toggle -->

                            <!-- start of Level 3 toggle -->
                            <div class="pt-6" formGroupName="level3Form">
                                <mat-slide-toggle
                                    data-test-id="level-3-toggle"
                                    color="primary"
                                    (change)="cardLevel3Toggled($event)"
                                    [(ngModel)]="cardLevel3IsChecked"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <tilled-paragraph-p3
                                        >Level II/III Data</tilled-paragraph-p3
                                    >
                                </mat-slide-toggle>
                                <tilled-paragraph-p4>Optional</tilled-paragraph-p4>
                                <div *ngIf="cardLevel3IsChecked" @expandCollapse>
                                    <div class="pt-6">
                                        <tilled-heading-h5
                                            >Shipping Details</tilled-heading-h5
                                        >
                                    </div>
                                    <div class="grid grid-cols-3 w-full gap-x-6">
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Ship to Country</tilled-label-l1>
                                                <mat-select
                                                    data-test-id="level-3-country"
                                                    class="text-p3 text-secondary pt-4"
                                                    formControlName="shipToCountry"
                                                    placeholder=""
                                                >
                                                    <mat-option *ngFor="let countryAndCode of countryCodeMap | keyvalue: originalOrder" [value]="countryAndCode.value"> 
                                                        {{countryAndCode.key}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Ship to Zip</tilled-label-l1>
                                                <input
                                                    data-test-id="level-3-ship-to-zip"
                                                    class="text-p3 text-tilled-primary pt-4"
                                                    matInput
                                                    formControlName="shipToZip"
                                                    placeholder="12345"
                                                    maxlength="10"
                                                />
                                            </mat-form-field>
                                        </div>
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Ship from Zip</tilled-label-l1>
                                                <input
                                                    data-test-id="level-3-ship-from-zip"
                                                    class="text-p3 text-tilled-primary pt-4"
                                                    matInput
                                                    formControlName="shipFromZip"
                                                    placeholder="12345"
                                                    maxlength="10"
                                                />
                                            </mat-form-field>
                                        </div>
                                        <!-- new row -->
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Shipping Amount</tilled-label-l1>
                                                <div class="flex">
                                                    <span matPrefix class="text-p3 text-tilled-primary pt-3.5">$&nbsp;</span>
                                                    <input
                                                        data-test-id="level-3-shipping-amount"
                                                        class="text-p3 text-tilled-primary pt-4"
                                                        matInput
                                                        formControlName="shipAmount"
                                                        placeholder=""
                                                        mask="separator.2"
                                                    />
                                                </div>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex flex-col col-span-1 -mb-2">
                                            <mat-form-field
                                                floatLabel="always"
                                                appearance="standard"
                                                class="flex flex-col w-full"
                                            >
                                                <tilled-label-l1 secondary="true"
                                                    >Duty Amount</tilled-label-l1>
                                                <div class="flex">
                                                    <span matPrefix class="text-p3 text-tilled-primary pt-3.5">$&nbsp;</span>
                                                    <input
                                                        data-test-id="level-3-duty-amount"
                                                        class="text-p3 text-tilled-primary pt-4"
                                                        matInput
                                                        formControlName="dutyAmount"
                                                        placeholder=""
                                                        mask="separator.2"
                                                    />
                                                </div>
                                            </mat-form-field>
                                        </div>
                                        
                                    </div>
                                    <!-- end of Level 3 - 3 column grid -->

                                    <div class="flex flex-col w-full pt-8">
                                        <app-line-items
                                            [lineItems]="lineItems"
                                            [updateLineItemsCallback]="updateLineItems"
                                        ></app-line-items>
                                    </div>
                                </div>
                            </div>
                            <!-- end of Level 3 toggle -->

                        </div>
                    </form>
                </form-card>
            </div>


            <!-- ACH Debit -->
            <div
                [ngClass]="selectedPaymentType == 'ach_debit' || selectedPaymentType == 'eft_debit' ? 
                    'grid sm:min-w-[700px] min-w-100 visible' : 
                    'hidden'"
            >
                <form-card
                    (exitClick)="closeDialog()"
                    (backClick)="prevPage()"
                    [backButton]="true"
                    [submitButton]="true"
                    (submitClick)="collectBankPayment()"
                    [submitDisabled]="bankPaymentForm.invalid || tilledBankForm?.invalid || (submittingPayment$ | async)"
                    [submitText]="'Collect Payment'"
                    [displayAlert]="alertDialogError$ | async"
                    [showAlert$]="alertDialogError$"
                    [alertTitle]="errorTitle"
                    [alertMessage]="errorMessage"
                >
                    <form
                        [formGroup]="bankPaymentForm"
                    >
                        <div data-test-id="ach-header" class="grid min-w-fit justify-items-center px-4 pb-2">
                            <!-- Title -->
                            <div class="flex flex-cols pb-2">
                            <tilled-heading-h2>{{pageTitle}}</tilled-heading-h2>
                            <tilled-heading-h2 primaryHighlight="true">&nbsp;{{newPaymentForm.get('amount').value * 100 | minorUnitsToCurrency}}</tilled-heading-h2>
                            </div>
                            <tilled-paragraph-p3
                                >Enter the customer’s payment method information</tilled-paragraph-p3>
                        </div>
                        <div class="flex flex-col">
                            <div class="grid sm:grid-cols-4 grid-cols-1 w-[full] gap-x-6">
                                <div class="flex flex-col col-span-2 pt-8">
                                    <div
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true" class="pb-1.5"
                                            >Account Number</tilled-label-l1>
                                        <div class="inputField" id="bank-account-number-element"></div>
                                        <div class="text-sm text-warn pt-1" *ngIf="showAccountNumberError; else noAchAccountNumberError" @fadeIn
                                            >A valid account number is required</div>
                                        <ng-template #noAchAccountNumberError>
                                            <div class="text-sm pt-1">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div 
                                    [ngClass]="selectedPaymentType == 'ach_debit' ? 
                                    'flex flex-col col-span-1 pt-8 w-[170px]' : 
                                    'flex flex-col col-span-2 pt-8' "
                                >
                                    <div
                                        class="flex flex-col w-full"
                                    >
                                        <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else eftLabel">
                                            <tilled-label-l1 secondary="true" class="pb-1.5"
                                                >Routing Number</tilled-label-l1>
                                        </ng-container>
                                        <ng-template #eftLabel>
                                            <tilled-label-l1 secondary="true" class="pb-1.5"
                                                >Transit Number and Institution Id</tilled-label-l1>
                                        </ng-template>
                                        <div class="inputField" id="bank-routing-number-element"></div>
                                        <div class="text-sm text-warn pt-1" *ngIf="showRoutingNumberError; else noRoutingNumberError" @fadeIn
                                            >A valid {{selectedPaymentType == 'ach_debit' ? 'routing number' : 'transit number and institution ID'}} is required</div>
                                        <ng-template #noRoutingNumberError>
                                            <div class="text-sm pt-1">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div *ngIf="selectedPaymentType == 'ach_debit'" class="flex flex-col col-span-1 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Account Type</tilled-label-l1>
                                        <mat-select
                                            data-test-id="ach-account-type"
                                            class="text-p3 text-secondary pt-4"
                                            formControlName="accountType"
                                        >
                                            <mat-option [value]="'checking'">Checking</mat-option>
                                            <mat-option [value]="'savings'">Savings</mat-option>
                                        </mat-select>
                                    <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('accountType').hasError('required')">An account type is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- new row -->
                                <div class="flex flex-col col-span-4 -mt-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Account Holder Name</tilled-label-l1>
                                        <input
                                            data-test-id="ach-account-holder"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="accountholderName"
                                            placeholder=""
                                        />
                                        <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('accountholderName').hasError('required')"
                                            >An account holder name is required</mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- billing details -->
                                <div class="flex flex-col col-span-2 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Address line 1</tilled-label-l1>
                                        <input
                                            data-test-id="ach-address-1"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="street1"
                                            placeholder=""
                                        />
                                        <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('street1').hasError('required')"
                                            >An address line 1 is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex flex-col col-span-2 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Address line 2</tilled-label-l1>
                                        <input
                                            data-test-id="ach-address-2"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="street2"
                                            placeholder=""
                                        />
                                    </mat-form-field>
                                </div>
                                <!-- new row -->
                                <div class="flex flex-col col-span-1 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >Country</tilled-label-l1>
                                        <mat-select
                                            data-test-id="ach-country"
                                            class="text-p3 text-secondary pt-4"
                                            formControlName="country"
                                            placeholder=""
                                        >
                                            <mat-option *ngFor="let countryAndCode of countryCodeMap | keyvalue: originalOrder" [value]="countryAndCode.value"> 
                                                {{countryAndCode.key}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('country').hasError('required')"
                                            >A country is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex flex-col col-span-1 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >State</tilled-label-l1>
                                        <mat-select
                                            data-test-id="ach-state"
                                            class="text-p3 text-secondary pt-4"
                                            formControlName="state"
                                            placeholder=""
                                        >
                                            <mat-option *ngFor="let stateAndCode of stateCodeMap | keyvalue" [value]="stateAndCode.key"> 
                                                {{stateAndCode.value}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('state').hasError('required')"
                                            >A state is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex flex-col col-span-1 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <tilled-label-l1 secondary="true"
                                            >City</tilled-label-l1>
                                        <input
                                            data-test-id="ach-city"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="city"
                                            placeholder=""
                                        />
                                        <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('city').hasError('required')"
                                            >A city is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="flex flex-col col-span-1 -mb-2">
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full"
                                    >
                                        <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else postalLable">
                                            <tilled-label-l1 secondary="true"
                                                >Zip</tilled-label-l1>
                                        </ng-container>
                                        <ng-template #postalLable>
                                            <tilled-label-l1 secondary="true"
                                                >Postal Code</tilled-label-l1>
                                        </ng-template>
                                        <input
                                            data-test-id="ach-zip"
                                            class="text-p3 text-tilled-primary pt-4"
                                            matInput
                                            formControlName="postalCode"
                                            placeholder="12345"
                                            maxlength="10"
                                        />
                                        <mat-error class="text-sm" color="warn" *ngIf="bankPaymentForm.get('postalCode').hasError('required')"
                                            >A {{selectedPaymentType == 'ach_debit' ? 'zip' : 'postal code'}} is required</mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                            <!-- end of ACH Info 4 col grid-->

                        </div>
                    </form>
                </form-card>
            </div>

        </mat-step>
    </mat-stepper>
</div>
