import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { ReportRun } from '../../../../../projects/tilled-api-client/src';

/**
 * Takes a ReportRun object
 */
@Pipe({
  name: 'reportRunStatus',
})
export class ReportRunStatusPipe implements PipeTransform {
  /**
   *
   * @param reportRun ReportRun object
   * @param solid is the chip color solid (or opaque)
   * @returns
   */
  transform(reportRun: ReportRun, solid: boolean = false): TilledChipConfig {
    let chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: '',
      toolTip: '',
    };

    if (reportRun.status === ReportRun.StatusEnum.FINISHED) {
      chipConfig.color = ChipColorClass.OPAQUE_GREEN;
      chipConfig.text = 'Successful';
      chipConfig.toolTip = '';
    } else if (reportRun.status === ReportRun.StatusEnum.FAILED) {
      chipConfig.color = ChipColorClass.OPAQUE_RED;
      chipConfig.text = 'Failed';
      chipConfig.toolTip = reportRun.failure_message;
    } else {
      chipConfig.color = ChipColorClass.OPAQUE_ORANGE;
      chipConfig.text = 'Processing';
      chipConfig.toolTip = '';
    }
    return chipConfig;
  }
}
