<tilled-table
    [data]="viewModels$ | async"
    [getPageCallback]="getConnectedAccounts"
    [dataLength]="connectedAccountsCount$ | async"
    [noDataMainText]="'No merchants found'"
    [noDataSecondaryText]="secondaryReasonText"
    [queryString]="query"
    [capabilityStatuses]="capabilityStatuses"
    [getClickCallback]="rowClickedCallback"
    data-test-id="merchants-table"
></tilled-table>
