<span
    [matTooltip]="disabledTooltip"
    [matTooltipDisabled]="!disabled"
>
    <button
        class="bg-{{ backgroundColor }} bg-opacity-{{ bgOpacity }} text-{{
            textDisplayColor
        }} min-w-0 {{ styles }}"
        [ngClass]="{ 'rounded-sm': !rounded, 'rounded-3xl': rounded, 'outline outline-{{ outlineColor }}': outlineColor}"

        mat-flat-button
        (click)="onButtonClick()"
        [disabled]="disabled"
    >
        <tilled-button-b2 [white]="displayWhite" [secondary]="secondary">{{ buttonText }}</tilled-button-b2>
        <!-- This ng-content allows us to inject icons or whatever at the end of a button text-->
        <ng-content></ng-content>
    </button>
</span>
