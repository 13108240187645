import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TilledTextGeneric } from '../tilled-text-generic.component';

@Component({
  selector: 'tilled-paragraph-generic',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TilledParagraphGeneric implements TilledTextGeneric {
  @Input() bold: boolean = false;
  @Input() italic: boolean = false;
  @Input() medium: boolean = false;
  @Input() styles: string = '';
  @Input() secondary: boolean = false;
  @Input() primaryHighlight: boolean = false;
  @Input() neutral: boolean = false;
  @Input() accent: boolean = false;
  constructor() {}

  ngOnInit() {
    this.styles += ' font-openSans';
    if (this.italic) {
      this.styles += ' italic';
    }
    if (this.bold) {
      this.styles += ' font-semibold';
    }
    if (this.medium) {
      this.styles += ' font-medium';
    }
    if (this.secondary) {
      this.styles += ' text-tilled-secondary';
    } else if (this.primaryHighlight) {
      this.styles += ' text-primary';
    } else if (this.accent) {
      this.styles += ' text-tilled-accent';
    } else if (!this.neutral) {
      this.styles += ' text-tilled-primary';
    }
  }
}
