<merchant-app-card
    [stepNumber]="!forConsole ? '1' : null"
    [title]="!forConsole ? 'Business Details' : null"
    [description]="!forConsole ? 'Tell us about your business' : null"
    [backButton]="false"
    (continueClick)="onContinueClicked($event)"
    [continueButton]="!forConsole && !(disabled$ | async)"
>
    <form
        data-test-id="details-step"
        class="w-full"
        [formGroup]="businessDetailsForm"
    >
        <tilled-input
            data-test-id="details-legal-name"
            class="w-full"
            name="legalName"
            placeholder="Full Legal Business Name"
            label="LEGAL BUSINESS NAME"
            tilledLabel="true"
        ></tilled-input>
        <tilled-input
            data-test-id="details-dba"
            class="w-full"
            name="dba"
            placeholder="Business “DBA” or Trading Name"
            label="DOING BUSINESS AS"
            tilledLabel="true"
            toolTip="The business name you present to customers"
        ></tilled-input>
        <div class="pt-5">
            <div class="flex flex-row items-center">
                <tilled-label-l1 secondary="true">BUSINESS ENTITY TYPE</tilled-label-l1>
                <mat-icon
                    class="icon-size-4 ml-2 z-10"
                    [svgIcon]="'heroicons_solid:information-circle'"
                    matTooltip="Your legal business structure as registered for tax filing"
                ></mat-icon>
            </div>
            <mat-form-field appearance="standard" class="w-full -mt-4">
                <mat-select
                    data-test-id="details-entity-type"
                    class="italic"
                    placeholder="Choose One"
                    [formControlName]="'type'"
                >
                    <mat-option *ngFor="let type of entityTypes" [value]="type.name"> {{ type.desc }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <tilled-input
            data-test-id="details-business-identifier"
            class="w-full"
            name="businessIdentifier"
            placeholder="Tax ID #, Canadian Business #, Social Security # or EIN"
            label="BUSINESS IDENTIFIER"
            tilledLabel="true"
            mask="000000000"
            toolTip="ie: Tax ID#, Canadian Business #, SSN # or EIN"
            errors="{{ businessDetailsForm.get('businessIdentifier').hasError('minLength') }}"
            errorMessage="Business Identifier must be 9 digits."
        ></tilled-input>
        <div class="pt-5">
            <tilled-label-l1 secondary="true">Industry</tilled-label-l1>
            <mat-form-field appearance="standard" class="w-full -mt-4">
                <mat-select
                    data-test-id="details-business-category"
                    class="italic"
                    placeholder="Choose One"
                    [formControlName]="'category'"
                >
                    <mat-option *ngFor="let code of codes" [value]="code.name"> {{ code.desc }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <tilled-input
            data-test-id="details-statement-descriptor"
            class="w-full pb-2.5"
            name="statementDescriptor"
            placeholder=""
            label="What name do you want to appear on customer credit card statements?"
            tilledLabel="true"
            maxLength="20"
        ></tilled-input>
        <div class="pt-6">
            <tilled-label-l1 secondary="true">DESCRIBE THE PRODUCT AND SERVICES YOU OFFER</tilled-label-l1>
            <mat-form-field appearance="outline" class="w-full">
            <textarea
                class="text-field"
                data-test-id="details-description"
                [formControlName]="'description'"
                matInput
                maxLength="240"
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="5"
            ></textarea>
            </mat-form-field>
        </div>
    </form>
</merchant-app-card>
