import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountCapability } from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If it is a partner, then proceed. This only impacts merchant users
    if (this.authService.user && !this.authService.isMerchantUser()) {
      return true;
    }

    let canProceed = this.authService.getAccount().then((result) => {
      if (result.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.ACTIVE)) {
        return true;
      } else if (
        result.capabilities.find((cap) =>
          [
            AccountCapability.StatusEnum.SUBMITTED,
            AccountCapability.StatusEnum.REJECTED,
            AccountCapability.StatusEnum.WITHDRAWN,
          ].includes(cap.status),
        )
      ) {
        // redirect to submitted page.
        this.router.navigate(['/onboarding/submitted']);
      } else if (result.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.CREATED)) {
        this.router.navigate(['/onboarding/get-started']);
      } else {
        // Authorized user that has not yet completed a merchant application
        // Redirect to merch app.
        this.router.navigate(['/onboarding/application']);
      }

      return false;
    });

    return canProceed;
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If it is a partner,then proceed. This only impacts merchant users
    if (this.authService.user && !this.authService.isMerchantUser()) {
      return true;
    }

    let canProceed = this.authService.getAccount().then((result) => {
      if (result.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.ACTIVE)) {
        return true;
      } else if (
        result.capabilities.find((cap) =>
          [
            AccountCapability.StatusEnum.SUBMITTED,
            AccountCapability.StatusEnum.REJECTED,
            AccountCapability.StatusEnum.WITHDRAWN,
          ].includes(cap.status),
        )
      ) {
        // redirect to submitted page.
        this.router.navigate(['/onboarding/submitted']);
      } else if (result.capabilities.find((cap) => cap.status === AccountCapability.StatusEnum.CREATED)) {
        this.router.navigate(['/onboarding/get-started']);
      } else {
        // Authorized user that has not yet completed a merchant application
        // Redirect to merch app.
        this.router.navigate(['/onboarding/application']);
      }
      return false;
    });

    return canProceed;
  }
}
