<div class="min-h-10 flex flex-col justify-center">
    <div *ngIf="!editStatus && !loading" class="flex">
        <div class="mr-10">
            <tilled-heading-h4>
                {{ nickname }}
            </tilled-heading-h4>
        </div>
        <mat-icon
            color="primary"
            [svgIcon]="'heroicons_solid:pencil'"
            (click)="onStatusToggle()"
        ></mat-icon>
    </div>
    <div *ngIf="editStatus && !loading" class="flex my-[-10px]">
        <mat-form-field [ngStyle]="{ 'width.px': width }">
            <input
                matInput
                #elasticInput
                class="text-h4 font-semibold font-openSans"
                [ngModel]="nameInput"
                (ngModelChange)="onNameInputChange($event)"
            />
        </mat-form-field>
        <div class="flex flex-col justify-center">
            <mat-icon
                color="primary"
                [svgIcon]="'heroicons_solid:check'"
                (click)="onNameEdit()"
            >
            </mat-icon>
        </div>
        <div class="flex flex-col justify-center">
            <mat-icon 
                color="primary"
                [svgIcon]="'heroicons_solid:x'"
                (click)="onEditCancel()">
            </mat-icon>
        </div>
    </div>
    <div *ngIf="loading">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
</div>
