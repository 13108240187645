import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { UserRoles } from 'app/core/data/user-roles';
import { AuthService } from 'app/core/services/auth.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { ActionListItem } from 'app/shared/action-list/action-list.model';
import { Column } from 'app/shared/tilled-table/decorators/column';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DeleteUserInvitationRequestParams,
  ListAllUserInvitationsRequestParams,
  ResendUserInvitationRequestParams,
  UserInvitation,
} from '../../../../../projects/tilled-api-client/src';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from '../../../core/services/alert.service';
import { TilledAlert } from '../../../core/models/tilled-alert';

@Component({
  selector: 'app-user-invitation-list',
  templateUrl: './user-invitation-list.component.html',
  styleUrls: ['./user-invitation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserInvitationListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accountId: string;

  public userInvitations$: Observable<UserInvitation[]>;
  public userInvitesViewModel$: Observable<UserViewModel[]>;
  public userInvitationsCount$: Observable<number>;

  public isLoading = true;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public sortInfo = null;
  public noDataMainText: string = 'No pending user invitations';
  public hasPermissionToView: boolean = true;

  public dialogRef: any;

  constructor(
    private _usersAppService: UsersAppService,
    private _authService: AuthService,
    private _clipboard: Clipboard,
    private _alertService: AlertService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInvitations$ = this._usersAppService.userInvitations$;
    this.userInvitationsCount$ = this._usersAppService.userInvitationsCount$;
    this.userInvitesViewModel$ = this.userInvitations$.pipe(
      map((users) => this.getViewModelsFromUserInvitations(users)),
    );
    if (!this._authService.isScopeAble('user_invitations:read')) {
      this.noDataMainText = 'Not authorized to view invitations';
      this.hasPermissionToView = false;
      this.userInvitationsCount$ = of(0);
    } else {
      this.getUserInvitations(this.pageSize, this.pageIndex);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this._usersAppService.unsubscribeFromUserService();
  }

  getUserInvitations = (size: number, index: number): void => {
    const usersParams: ListAllUserInvitationsRequestParams = {
      tilledAccount: this.accountId,
      offset: this.pageIndex * this.pageSize,
      limit: this.pageSize,
    };
    this._usersAppService.getAllUserInvitations(usersParams);
  };

  getViewModelsFromUserInvitations(userInvitations: UserInvitation[]): UserViewModel[] {
    const viewModels: UserViewModel[] = [];
    if (!userInvitations || userInvitations.length === 0) {
      let temp: UserViewModel = new UserViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const userInv of userInvitations) {
      let temp: UserViewModel = new UserViewModel();
      temp.user_name = [];
      temp.user_name.push(userInv.email);
      temp.last_login = 'Invitation Sent';
      temp.role = UserRoles.DisplayText.get(userInv.role);

      temp.actionList = [];
      let disabled = false;
      if (userInv.role === UserInvitation.RoleEnum.OWNER || !this._authService.isScopeAble('user_invitations:write')) {
        disabled = true;
      }
      temp.actionList.push(
        new ActionListItem({
          name: 'Resend Invitation',
          callback: (): void => {
            this.resendUserInvite(userInv);
          },
          disabled: disabled,
        }),
      );
      temp.actionList.push(
        new ActionListItem({
          name: 'Copy Invitation URL',
          callback: (): void => {
            this._clipboard.copy(userInv.invitation_url);
            const message: TilledAlert = {
              message: `User invitation for ${userInv.email} was copied`,
              title: 'User invitation link copied',
              type: 'success',
              timer: 8000,
            };
            this._alertService.showAlert(message);
          },
          disabled: disabled,
        }),
      );
      temp.actionList.push(
        new ActionListItem({
          name: 'Delete Invitation',
          callback: (): void => {
            this.deleteUserInvite(userInv);
          },
          disabled: disabled,
        }),
      );

      viewModels.push(temp);
    }
    return viewModels;
  }

  deleteUserInvite = (userData: UserInvitation): void => {
    const params: DeleteUserInvitationRequestParams = {
      tilledAccount: this.accountId,
      id: userData.id,
    };
    this._usersAppService.deleteUserInvitation(params);
  };

  resendUserInvite = (userData: UserInvitation): void => {
    const params: ResendUserInvitationRequestParams = {
      tilledAccount: this.accountId,
      id: userData.id,
    };
    this._usersAppService.resendUserInvitation(params);
  };
}

export class UserViewModel {
  @Column({
    order: 0,
    name: 'User Name',
    isMultiline: true,
  })
  user_name: string[];

  @Column({
    order: 1,
    name: 'Role',
  })
  role: string;

  @Column({
    order: 2,
    name: 'Last Login',
  })
  last_login: string;

  @Column({
    order: 3,
    isActionList: true,
  })
  actionList: ActionListItem[];
}
