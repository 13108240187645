<ng-container *ngIf="displayAlert$ | async">
    <fuse-alert
        [type]="alertType"
        [appearance]="'outline'"
        [dismissible]="true"
        [dismissed]="false"
        [name]="'tilledAlertBox'"
    >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        <span style="white-space: pre-wrap" class="break-all">{{alertMessage}}</span>
    </fuse-alert>
</ng-container>
