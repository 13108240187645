<div>
    <tilled-table
        [data]="(paymentsViewModel$ | async)!"
        [getPageCallback]="getPaymentIntents"
        [dataLength]="paymentsCount$ | async"
        data-test-id="payments-table"
        [noDataMainText]="'No payments found'"
        [noDataSecondaryText]="secondaryReasonText"
        [getClickCallback]="rowClickedCallback"
        [hideColumns]="hideColumns"
        [scrollable]="true"
        [showDisplayedColumnsDropdown]="false"
    ></tilled-table>
</div>
