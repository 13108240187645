import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseAlertService } from '@fuse/components/alert';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { Observable, Subject, Subscription } from 'rxjs';

const ALERT_NAME: string = 'tilledAlertBox';

@Component({
    selector: 'tilled-alert',
    templateUrl: './tilled-alert.component.html',
})

export class TilledAlertComponent implements OnInit, OnDestroy {
    public alert$: Observable<TilledAlert>;
    private displaySubscription: Subscription;
    private _displayAlert$ = new Subject<boolean>();
    public displayAlert$ = this._displayAlert$.asObservable();
    public alertMessage: string;
    public alertTitle: string;
    public alertType: string;
    private intervalId: any;

    constructor(
        private _alertService: AlertService,
        private _fuseAlertService: FuseAlertService,
    ) { }

    ngOnInit() {
        this.alert$ = this._alertService.newAlert$;

        this.displaySubscription = this.alert$.subscribe({
            next: (alert) => {
                if (!alert) {
                    return;
                }
                this.alertMessage = alert.message;
                this.alertTitle = alert.title;
                this.alertType = alert.type;
                this._displayAlert$.next(true);
                this._fuseAlertService.show(ALERT_NAME);

                let time = alert.timer/1000;
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }
                if (time > 0) {
                    this.intervalId = setInterval(() => {
                        time = time - 1;
                        if (time <= 0) {
                            clearInterval(this.intervalId);
                            this._fuseAlertService.dismiss(ALERT_NAME);
                        }
                    }, 1000);
                }
            },
        });
    }

    ngOnDestroy(): void {
        if (this.displaySubscription) {
            this.displaySubscription.unsubscribe();
        }
    }
}
