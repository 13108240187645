import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup
} from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { ApplicationStep } from 'app/core/models/application-step';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isRoutingNumber } from 'app/shared/validators/routing-number.validator';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { BusinessLegalEntityBankAccount, MerchantApplication, ProductCode } from '../../../../../projects/tilled-api-client/src';

@Component({
    selector: 'bank-account-step',
    templateUrl: './bank-account-step.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountStepComponent extends ComponentBase implements OnInit, OnDestroy {
    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    @Input() forConsole: boolean = false;
    @Input() disabled$: Observable<boolean> = null;
    @Input() saveApp$: Observable<string> = null;
    @Input() checkUnsavedApp$: Observable<string> = null;
    @Input() resetApp$: Observable<boolean> = null;
    @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
    private subscriptions: Subscription[] = [];
    public bankAccountForm: FormGroup;
    public steps: ApplicationStep[];
    public merchantApp: MerchantApplication;
    public routingNumberLabel: string = 'Routing Number';

    constructor(
        private _formBuilder: FormBuilder,
        private _merchantAppService: MerchantAppService
    ) {
        super();
        this._merchantAppService.merchantAppSteps$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (steps) => (this.steps = steps)
            );
        this._merchantAppService.merchantApplicationResponse$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (application) => (this.merchantApp = cloneDeep(application))
            );
    }

    ngOnInit(): void {
        this.bankAccountForm = this._formBuilder.group({
            accountType: new FormControl(this.merchantApp.business_legal_entity?.bank_account?.type || null),
            accountHolder: new FormControl(this.merchantApp.business_legal_entity?.bank_account?.account_holder_name || null),
            bankName: new FormControl(this.merchantApp.business_legal_entity?.bank_account?.bank_name || null),
            accountNumber: new FormControl(this.merchantApp.business_legal_entity?.bank_account?.account_number || null),
            routingNumber: new FormControl(this.merchantApp.business_legal_entity?.bank_account?.routing_number || null,
                [isRoutingNumber()]),
        });

        if (this.merchantApp.product_codes[0].region === ProductCode.RegionEnum.CA) {
            this.routingNumberLabel = 'Transit Number and Institution ID';
        }

        if (!this.merchantApp.business_legal_entity.bank_account) {
            this.merchantApp.business_legal_entity.bank_account = {
                account_number: '',
                routing_number: ''
            } as BusinessLegalEntityBankAccount;
        }

        if (this.disabled$) {
            this.subscriptions.push(this.disabled$.subscribe((isDisabled) => {
                if (isDisabled) {
                    this.bankAccountForm.disable();
                } else {
                    this.bankAccountForm.enable();
                }
            }));
        }
    
        if (this.forConsole) {
            if (this.saveApp$) {
                this.subscriptions.push(this.saveApp$.subscribe((save) => {
                    if (save) {
                        this.onContinueClicked(save);
                    }
                }));
            }
            if (this.checkUnsavedApp$) {
                this.subscriptions.push(this.checkUnsavedApp$.subscribe((check) => {
                    if (check) {
                        this.markAppUnsaved.emit(this.isAppUnsaved());
                    }
                }));
            }
            if (this.resetApp$) {
                this.subscriptions.push(this.resetApp$.subscribe((reset) => {
                    if (reset) {
                        this.resetApplication();
                    }
                }));
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onBackClicked(event: string): void {
        this._merchantAppService.updateCurrentStep(3);
    }

    onContinueClicked(accountId?: string) {
        this.bankAccountForm.markAllAsTouched();
        if (this.bankAccountForm.invalid) {
            this.scrollToError();
            return;
        }
        // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
        for (const field in this.bankAccountForm.controls) {
            const control = this.bankAccountForm.get(field);
            if (control.value === '') {
                control.setValue(null);
            }
        }

        this.merchantApp.business_legal_entity.bank_account.account_number = this.bankAccountForm.value.accountNumber ?? '';
        this.merchantApp.business_legal_entity.bank_account.routing_number = this.bankAccountForm.value.routingNumber ?? '';
        this.merchantApp.business_legal_entity.bank_account.type = this.bankAccountForm.value.accountType as BusinessLegalEntityBankAccount.TypeEnum;
        this.merchantApp.business_legal_entity.bank_account.account_holder_name = this.bankAccountForm.value.accountHolder;
        this.merchantApp.business_legal_entity.bank_account.bank_name = this.bankAccountForm.value.bankName;

        this._merchantAppService.updateMerchantApplication(this.merchantApp, !this.forConsole ? 5 : 4, accountId);
    }

    private isAppUnsaved(): boolean {
        for (const field in this.bankAccountForm.controls) {
            const control = this.bankAccountForm.get(field);
            if (control.value === '') {
                control.setValue(null);
            }
        }
        if (this.bankAccountForm.value.routingNumber === null) {
            this.bankAccountForm.controls['routingNumber'].setValue('');
        }
        // ignore account number, as it is masked
        return !(
            _compareTwoStrings(this.merchantApp.business_legal_entity?.bank_account?.routing_number, 
                this.bankAccountForm.value.routingNumber) &&
            _compareTwoStrings(this.merchantApp.business_legal_entity?.bank_account?.type, 
                this.bankAccountForm.value.accountType) &&
            _compareTwoStrings(this.merchantApp.business_legal_entity?.bank_account?.account_holder_name, 
                this.bankAccountForm.value.accountHolder) &&
            _compareTwoStrings(this.merchantApp.business_legal_entity?.bank_account?.bank_name, 
                this.bankAccountForm.value.bankName)
        );
    }
    
    private resetApplication(): void {
        this.bankAccountForm.controls['accountNumber'].setValue(this.merchantApp.business_legal_entity?.bank_account?.account_number);
        this.bankAccountForm.controls['routingNumber'].setValue(this.merchantApp.business_legal_entity?.bank_account?.routing_number);
        this.bankAccountForm.controls['accountType'].setValue(this.merchantApp.business_legal_entity?.bank_account?.type);
        this.bankAccountForm.controls['accountHolder'].setValue(this.merchantApp.business_legal_entity?.bank_account?.account_holder_name);
        this.bankAccountForm.controls['bankName'].setValue(this.merchantApp.business_legal_entity?.bank_account?.bank_name);
    }

    scrollTo(el: Element): void {
        if (el) {
           el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    
    scrollToError(): void {
        const firstElementWithError = document.querySelector('.mat-form-field-invalid');
        this.scrollTo(firstElementWithError);
    }
}
