<mat-chip-list>
    <!-- [color]='green' will apply class '.mat-green' -->
    <mat-chip
        id="tilled-chip"
        class="text-sm min-w-10 truncate"
        disabled
        [matTooltip]="tooltipText"
        matTooltipPosition="above"
        [color]="colorClass"
        >{{ displayStatus }}
    </mat-chip>
</mat-chip-list>
