import { NgModule, Optional, SkipSelf } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Replay } from '@sentry/replay';
import { Integrations } from '@sentry/tracing';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { environment } from 'environments/environment';
import { LIB_VERSION } from '../../../version';
import { AuthService } from './services/auth.service';

if (environment.sentryDSN.length > 0) {
  const release = LIB_VERSION;

  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.env,
    release: 'hawkeye@v' + release,
    replaysSessionSampleRate: environment.replaysSamplingRate,
    replaysOnErrorSampleRate: environment.replaysOnErrorSamplingRate,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.api],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),

      // This feature is in alpha, so we are only enabling it for staging/sandbox.
      // This also may turn into a paid feature in which we may not utilize depending on the value.
      new Replay({}),
    ],
    tracesSampleRate: environment.tracesSampleRate,
  });
}
@NgModule({
  imports: [IconsModule, TranslocoCoreModule],
  providers: [
    //AuthorizeGuard,
    //FeatureToggleGuard,
    AuthService,
    // ConnectedAccountsService,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false, // Pops up in all the wrong places
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
