import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, expand, map, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';
import {
  Account,
  AccountsService,
  AccountUpdateParams,
  CreateConnectedAccountRequestParams,
  DeleteConnectedAccountRequestParams,
  GetAccountRequestParams,
  ListConnectedAccountsRequestParams,
  UpdateAccountRequestParams,
  InternalListConnectedAccountsRequestParams,
  InternalService,
} from '../../../../projects/tilled-api-client/src';
import { DEFAULT_PAGE_LIMIT } from '../constants';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountAppService {
  private subject = new BehaviorSubject<Account[]>([]);
  private _connectedAccountsCount$ = new ReplaySubject<number>();
  private _connectedAccountsPaginated$ = new ReplaySubject<Account[]>();
  private _connectedAccount$ = new BehaviorSubject<Account>(null);
  private accountListBuilder: Account[] = [];
  private _connectedAccountApplicationProgress$ = new BehaviorSubject<number>(null);

  public connectedAccounts$: Observable<Account[]> = this.subject.asObservable();
  public connectedAccountsCount$: Observable<number> = this._connectedAccountsCount$.asObservable();
  public connectedAccountsPaginated$: Observable<Account[]> = this._connectedAccountsPaginated$.asObservable();
  public connectedAccount$: Observable<Account> = this._connectedAccount$.asObservable();
  public connectedAccountApplicationProgress$: Observable<number> =
    this._connectedAccountApplicationProgress$.asObservable();

  constructor(
    private _authService: AuthService,
    private _accountsService: AccountsService,
    private _alertService: AlertService,
    private _router: Router,
    private _internalService: InternalService,
  ) {
    this.loadAllConnectedAccounts();
  }

  private loadAllConnectedAccounts(): void {
    const requestParams: InternalListConnectedAccountsRequestParams = {
      tilledAccount: this._authService.user.account_id,
      metadata: null,
      q: null,
      sort: null,
      offset: 0,
      limit: 100,
      capabilityStatus: null,
    };

    const listConnectedAccounts$ = this._internalService.internalListConnectedAccounts(requestParams);
    listConnectedAccounts$
      .pipe(
        expand((result) => {
          const hasMore = result.has_more;
          requestParams.offset = result.offset + result.limit;
          if (hasMore) {
            this.accountListBuilder.push(...result.items);
            return this._accountsService.listConnectedAccounts(requestParams);
          }
          this.accountListBuilder.push(...result.items);
          this.subject.next(this.accountListBuilder);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public loadConnectedAccounts(params: InternalListConnectedAccountsRequestParams): void {
    const requestParams: InternalListConnectedAccountsRequestParams = {
      tilledAccount: this._authService.user.account_id,
      metadata: params.metadata,
      q: params.q,
      sort: params.sort,
      offset: params.offset ? params.offset : 0,
      limit: params.limit ? params.limit : DEFAULT_PAGE_LIMIT,
      capabilityStatus: params.capabilityStatus,
    };

    this._internalService
      .internalListConnectedAccounts(requestParams)
      .pipe(
        tap((result) => this._connectedAccountsCount$.next(result.total)),
        map((result) => result.items),
        catchError((err) => {
          const message: TilledAlert = {
            message: 'Could not load accounts',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
          throw 'Error loading accounts ' + JSON.stringify(err);
        }),
        shareReplay(1),
      )
      .subscribe((result) => this._connectedAccountsPaginated$.next(result));
  }

  public getConnectedAccountById(accountId: string): void {
    const accountParams: GetAccountRequestParams = {
      tilledAccount: accountId,
    };
    this._accountsService.getAccount(accountParams).subscribe({
      next: (account) => {
        this._connectedAccount$.next(account);
        this._connectedAccountApplicationProgress$.next(
          account.capabilities[0]?.status === 'active' ? 100 : account.capabilities[0]?.onboarding_application_progress,
        );
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not load account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw 'Error loading account ' + JSON.stringify(err);
      },
    });
  }

  public createConnectedAccount(params: CreateConnectedAccountRequestParams): void {
    this._accountsService.createConnectedAccount(params).subscribe({
      next: (result) => {
        const message: TilledAlert = {
          message: "Merchant application '" + result.name + "' was created successfully",
          title: 'Account created',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        this._connectedAccount$.next(result);
        const requestParams: ListConnectedAccountsRequestParams = {
          tilledAccount: params.tilledAccount,
          offset: 0,
          limit: 25,
        };
        this.loadConnectedAccounts(requestParams);
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not create account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw 'Error creating account ' + JSON.stringify(err);
      },
    });
  }

  public updateConnectedAccountById(connectedAccountId: string, updateParams: AccountUpdateParams): void {
    const accountParams: UpdateAccountRequestParams = {
      tilledAccount: connectedAccountId,
      accountUpdateParams: updateParams,
    };
    this._accountsService.updateAccount(accountParams).subscribe({
      next: (result) => {
        const message: TilledAlert = {
          message: 'Account was updated successfully',
          title: 'Account updated',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        this.getConnectedAccountById(result.id);
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not update account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw 'Error updating account ' + JSON.stringify(err);
      },
    });
  }

  filterBy(key: string, value: string) {
    return this.connectedAccounts$.pipe(map((accounts) => accounts.filter((account) => account[key] == value)));
  }

  filterByCreatedAt(date: Date) {
    return this.connectedAccounts$.pipe(
      map((accounts) => accounts.filter((account) => new Date(account.created_at) > date)),
    );
  }

  //We aren't using this yet, but we will use it on payment details in a coming PR
  getAccountById(id): Observable<Account> {
    const getAccountParams: GetAccountRequestParams = {
      tilledAccount: id,
    };
    return this._accountsService.getAccount(getAccountParams).pipe(
      map((res) => res),
      catchError((err) => {
        const message: TilledAlert = {
          message: 'Could not load account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw 'Error getting account ' + JSON.stringify(err);
      }),
      shareReplay(),
    );
  }

  deleteConnectedAccount(params: DeleteConnectedAccountRequestParams): Observable<any> {
    return this._accountsService.deleteConnectedAccount(params).pipe(
      map((res) => res),
      catchError((err) => {
        const message: TilledAlert = {
          message: 'Could not delete account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw 'Error deleting account' + JSON.stringify(err);
      }),
    );
  }
}
