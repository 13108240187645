<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <ng-container>
        <div
            class="p-2 text-lg font-medium tracking-tight leading-6 truncate m-4"
        >
            <!-- Title -->
            <div>Metadata</div>

            <!-- Tasks -->
            <ng-container>
                <div class="mx-2 mt-4 space-y-1.5">
                    <form [formGroup]="metadataForm">
                        <div
                            formArrayName="items"
                            *ngFor="
                                let metadata of metadataForm.get('items')[
                                    'controls'
                                ];
                                let i = index
                            "
                        >
                            <div [formGroupName]="i" class="flex flex-row">
                                <div
                                    class="group flex flex-row w-full items-center mr-6"
                                >
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full px-1 py-0.5 mr-6"
                                    >
                                        <mat-label class="text-lg font-medium"
                                            >Key</mat-label
                                        >
                                        <input
                                            class="text-md text-secondary"
                                            matInput
                                            formControlName="key"
                                            placeholder="Key"
                                            data-test-id="metadata-key"
                                        />
                                    </mat-form-field>
                                    <mat-form-field
                                        floatLabel="always"
                                        appearance="standard"
                                        class="flex flex-col w-full px-1 py-0.5 mr-6"
                                    >
                                        <mat-label class="text-lg font-medium"
                                            >Value</mat-label
                                        >
                                        <input
                                            class="text-md text-secondary"
                                            matInput
                                            formControlName="value"
                                            placeholder="Value"
                                            data-test-id="metadata-value"
                                        />
                                    </mat-form-field>
                                    <mat-icon
                                        (click)="removeMetadataRow(i)"
                                        data-test-id="metadata-delete"
                                        color="primary"
                                        >delete</mat-icon
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>

            <!-- Add Actions -->
            <ng-container>
                <div class="flex flex-row items-center justify-between mt-4">
                    <!-- Add Item -->
                    <tilled-button
                        data-test-id="metadata-addBtn"
                        bgColor="accent"
                        [rounded]="true"
                        buttonText="Add Item"
                        (buttonClick)="addMetadataItem()"
                    ></tilled-button>
                    <div clas="flex flex-col justify-end">
                        <!-- Cancel -->
                        <tilled-button
                            class="mr-3"
                            data-test-id="metadata-cancelBtn"
                            bgColor="white"
                            textColor="grey-300"
                            [whiteText]="false"
                            buttonText="Cancel"
                            (buttonClick)="dialogRef.close()"
                        ></tilled-button>

                        <!-- Save -->
                        <tilled-button
                            data-test-id="metadata-saveBtn"
                            bgColor="primary"
                            buttonText="Save"
                            (buttonClick)="dialogRef.close(metadataForm)"
                        ></tilled-button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
