import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { BrandingService } from './core/services/branding.service';

const TOKEN_SHARING_CHANNEL = 'token-sharing';
const REQUESTING_TOKEN = 'requesting-token';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public bc = new BroadcastChannel(TOKEN_SHARING_CHANNEL);
  constructor(private _brandingService: BrandingService, private _authService: AuthService) {
    this.addBroadcastChannelListener();
    this.bc.postMessage(REQUESTING_TOKEN);
  }

  private addBroadcastChannelListener() {
    this.bc.addEventListener('message', (event) => {
      if (event.data === REQUESTING_TOKEN) {
        new BroadcastChannel(TOKEN_SHARING_CHANNEL).postMessage({
          accessToken: AuthService.getAccessToken(),
          refreshToken: AuthService.getRefreshToken(),
        });
      } else {
        const { accessToken, refreshToken } = event.data;
        accessToken && this._authService.setAccessToken(accessToken, false);
        refreshToken && this._authService.setRefreshToken(refreshToken, false);
      }
    });
  }

  ngOnInit(): void {
    // this.setFuseConfig();
  }

  isImpersonated(): boolean {
    return this._authService.isImpersonated();
  }
}
