<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div 
    class="fixed m-auto left-0 right-0 z-[99999] sm:px-0 px-6 sm:w-fit w-full sm:max-w-[50%] max-w-full"
    [ngClass]="{
        'top-20': !(alert$ | async)?.bottom,
        'bottom-20': (alert$ | async)?.bottom
    }"
>
    <tilled-alert></tilled-alert>
</div>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-accent-500 print:hidden"
    [ngClass]="{
        'text-white' : whiteText,
        'text-black' : !whiteText
    }"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navItems"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 pl-7 pr-6 mb-6"
            [ngClass]="{'bg-white': (isWhiteLabel$ | async),
                'p-6 pb-0': !(isWhiteLabel$ | async)}">
            
            <img [ngClass]="
                {'w-30': !(isWhiteLabel$ | async),
                'w-fit max-h-[75px]': (isWhiteLabel$ | async)
                }"
                src="{{ (darkLogoUrl$ | async) ?? (logoUrl$ | async) }}" />
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationFooter>
        <!-- User -->
        <div class="flex items-center w-full px-6 py-8 border-t">
            <user></user>
            <div class="flex flex-col w-full ml-4 overflow-hidden">
                <div
                    data-test-id="username"
                    class="
                        w-full
                        whitespace-nowrap
                        text-ellipsis
                        overflow-hidden
                        leading-normal
                        text-current
                        opacity-80
                    "
                >
                    {{ user.name }}
                </div>
                <div
                    data-test-id="email"
                    class="
                        w-full
                        mt-0.5
                        whitespace-nowrap
                        text-sm text-ellipsis
                        overflow-hidden
                        leading-normal
                        text-current
                        opacity-50
                    "
                >
                    {{ user.email }}
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 bg-tilled-neutral-200">
    <!-- Header -->
    <div
        class="
            relative
            flex flex-0
            items-center
            w-full
            h-16
            px-4
            md:px-6
            z-49
            shadow
            dark:shadow-none
            dark:border-b
            bg-transparent
            dark:bg-transparent
            print:hidden
        "
    >
        <!-- Navigation toggle button -->
        <button
            data-test-id="logout-nav"
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')"
        >
            <mat-icon
                class="text-primary"
                [svgIcon]="'heroicons_outline:menu'"
            ></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button mat-icon-button (click)="helpCenter.toggle()">
                <mat-icon
                    color="primary"
                    [svgIcon]="'heroicons_outline:question-mark-circle'"
                ></mat-icon>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="
            relative
            flex flex-0
            items-center
            justify-start
            w-full
            h-14
            px-4
            md:px-6
            z-49
            border-t
            bg-card
            dark:bg-transparent
            print:hidden
        "
    >
        <span *ngIf="!(isWhiteLabel$ | async)" class="font-medium text-secondary"
            >Shift4 &copy; {{ currentYear }}</span
        >
    </div>
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->

<!-- Help center -->
<help-center
    [email]="user.email"
    [accountId]="user.account_id"
    [tilledSupport]="
        this.account?.merchant_support || this.account?.type === 'partner'
    "
    #helpCenter="helpCenter"
></help-center>
