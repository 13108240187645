import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@Component({
  selector: 'tilled-input',
  templateUrl: './tilled-input.component.html',
  styleUrls: ['../form-fields.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
})
export class TilledInputComponent implements OnInit {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() name?: string;
  @Input() groupName?: string;
  @Input() fieldType?: string = 'text';
  @Input() styles?: string = '';
  @Input() showPercent?: boolean = false;
  @Input() showCurrency?: boolean = false;
  @Input() mask?: string;
  @Input() dropMaskCharacters?: boolean = true;
  @Input() leadZeroDateTime?: boolean = false;
  @Input() thousandSeparator?: string = ',';
  @Input() readonly?: boolean = false;
  @Input() errors?: boolean = false;
  @Input() errorMessage?: string;
  @Input() hideRequiredMarker?: boolean = false;
  @Input() minLength?: number;
  @Input() maxLength?: number = 255;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() appearance?: string = 'standard';
  @Input() tilledLabel: boolean = false;
  @Input() toolTip: string = null;
  @Input() required: boolean = false;
  constructor() {}

  ngOnInit() {}
}
