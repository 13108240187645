import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import packageJson from '../../../../package.json';
import { environment } from '../../../environments/environment';
import { StringToSentencePipe } from '../pipes/string-to-sentence.pipe';

@Injectable()
export class RequestResponseInterceptor implements HttpInterceptor {
  constructor(private router: Router, private stringToSentencePipe: StringToSentencePipe) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We only want to intercept requests to Tilled's API
    if (req.url.includes(environment.api)) {
      const clonedReq = req.clone({
        headers: req.headers.set('tilled-client-name', 'isv_console').set('tilled-client-version', packageJson.version),
      });
      // We'll attempt the request and IF it fails with a 404 then we'll redirect to the 404 page
      return next.handle(clonedReq).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.router.navigate(['not-found'], {
              replaceUrl: false,
            });
          } else if (error instanceof HttpErrorResponse && error.status === 400) {
            /**
             * Bad request error. Need to standardize since 400 can return a single string
             * or an array of strings. Here we will concat the strings with new line chars
             * in between.
             **/
            if (Array.isArray(error.error?.message)) {
              let concatString = '';
              error.error.message.forEach((element) => {
                if (typeof element === 'string') {
                  element = this.stringToSentencePipe.transform(element);
                  concatString = concatString + element + '\n';
                }
              });
              error.error.message = concatString;
            }
          }
          return throwError(error);
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
