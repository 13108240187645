<div class="overflow-y-auto max-h-[100vh] sm:w-120 w-fit px-4">
    <form-card
        (exitClick)="closeDialog()"
        [backButton]="false"
        [submitButton]="true"
        (submitClick)="copyPaymentLink()"
        submitText="Copy Payment Link to Clipboard"
    >
        <div data-test-id="modal-header" class="grid w-full justify-items-center text-center pb-2">
            <!-- Title -->
            <tilled-heading-h2 class="pb-2">Payment Link for {{ amount| minorUnitsToCurrency: currency }}</tilled-heading-h2>
            <tilled-paragraph-p3 class="pb-8"
                >Copy and share the unique payment link with your customer.</tilled-paragraph-p3>
        </div>
        <div>
            <div class="flex flex-col pb-6">
                <tilled-label-l1 secondary="true" class="pb-3"
                    >ONE-TIME PAYMENT LINK</tilled-label-l1
                >
                <tilled-paragraph-p3 class="break-all">{{url}}</tilled-paragraph-p3>
            </div>
        </div>
    </form-card>
</div>
