<div
    class="
        flex flex-col flex-auto
        shadow
        rounded-2xl
        overflow-hidden
        mb-10
        cursor
        {{ detailView ? 'cursor-default' : 'cursor-pointer' }}
        bg-tilled-neutral-100
    "
    (click)="onTemplateClick()"
>
    <div class="my-6">
        <div class="flex mx-6 justify-between">
            <div class="flex">
                <div *ngIf="!canEdit">
                    <tilled-heading-h4>
                        {{ template.nickname }}
                    </tilled-heading-h4>
                </div>
                <div *ngIf="canEdit">
                    <editable-pricing-template-nickname
                        [nickname]="template.nickname"
                        [accountId]="template.accountId"
                        [pricingTemplateId]="template.id"
                    ></editable-pricing-template-nickname>
                </div>
                <div class="ml-2 flex flex-col justify-center">
                    <tilled-chip
                        [chipConfig]="
                            template.status | template_status_chip_config
                        "
                    ></tilled-chip>
                </div>
            </div>
            <div *ngIf="!detailView">
                <tilled-paragraph-p4>
                    <div class="text-primary">
                        View template details >
                    </div>
                </tilled-paragraph-p4>
            </div>
        </div>
        <div class="mx-6 mb-3 {{ canEdit ? 'mt-1' : 'mt-2' }}">
            <tilled-paragraph-p3 [secondary]="true">
                {{ template.currency | uppercase }} /
                {{ template.feeType | snakeCaseSplit | titlecase }}
            </tilled-paragraph-p3>
        </div>
        <div class="mx-6 border-t border-gray-400"></div>
        <div class="mx-6 my-3">
            <div class="flex flex-row flex-auto">
                <div class="flex flex-col flex-auto">
                    <div class="my-2">
                        <tilled-label-l1 [secondary]="true"
                            >Transaction Fee
                        </tilled-label-l1>
                    </div>
                    <tilled-paragraph-p3>
                        {{
                            template.transactionFee
                                | minorUnitsToCurrency: template.currency
                        }}
                    </tilled-paragraph-p3>
                </div>
                <div class="flex flex-col flex-auto">
                    <div class="my-2">
                        <tilled-label-l1 [secondary]="true"
                            >Return Fee</tilled-label-l1
                        >
                    </div>
                    <tilled-paragraph-p3>
                        {{
                            template.returnFee
                                | minorUnitsToCurrency: template.currency
                        }}
                    </tilled-paragraph-p3>
                </div>
                <div class="flex flex-col flex-auto">
                    <div class="my-2">
                        <tilled-label-l1 [secondary]="true"
                            >Monthly Account Fee</tilled-label-l1
                        >
                    </div>
                    <tilled-paragraph-p3>
                        {{
                            template.accountFee
                                | minorUnitsToCurrency: template.currency
                        }}
                    </tilled-paragraph-p3>
                </div>
                <div
                    class="flex flex-col flex-auto"
                    *ngIf="template.minimumFee > 0"
                >
                    <div class="my-2">
                        <tilled-label-l1 [secondary]="true"
                            >Monthly Minimum Fee</tilled-label-l1
                        >
                    </div>
                    <tilled-paragraph-p3>
                        {{
                            template.minimumFee
                                | minorUnitsToCurrency: template.currency
                        }}
                    </tilled-paragraph-p3>
                </div>
            </div>
        </div>
    </div>
</div>
