/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialBusinessProfileParams } from './partialBusinessProfileParams';
import { BankAccountUpdateParams } from './bankAccountUpdateParams';
import { ProcessingInformation } from './processingInformation';


export interface PartnerApplicationResponse { 
    id: string;
    account_id: string;
    status: PartnerApplicationResponse.StatusEnum;
    created_at: string;
    updated_at: string;
    sent_to_partner_at: string;
    started_at: string;
    submitted_to_processor_at: string;
    in_underwriting_at: string;
    declined_at: string;
    approved_at: string;
    account_name?: string;
    business_profile?: PartialBusinessProfileParams;
    bank_account?: BankAccountUpdateParams;
    processing_information?: ProcessingInformation;
}
export namespace PartnerApplicationResponse {
    export type StatusEnum = 'not_started' | 'sent_to_partner' | 'started' | 'submitted_to_tilled' | 'submitted_to_processor' | 'in_underwriting' | 'declined' | 'approved';
    export const StatusEnum = {
        NOT_STARTED: 'not_started' as StatusEnum,
        SENT_TO_PARTNER: 'sent_to_partner' as StatusEnum,
        STARTED: 'started' as StatusEnum,
        SUBMITTED_TO_TILLED: 'submitted_to_tilled' as StatusEnum,
        SUBMITTED_TO_PROCESSOR: 'submitted_to_processor' as StatusEnum,
        IN_UNDERWRITING: 'in_underwriting' as StatusEnum,
        DECLINED: 'declined' as StatusEnum,
        APPROVED: 'approved' as StatusEnum
    };
}


