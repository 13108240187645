<mat-form-field
    class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 {{
        additonalClasses
    }}"
    [floatLabel]="'always'"
    [appearance]="inputAppearance"
>
    <mat-icon
        *ngIf="includeSearch"
        matPrefix
        [svgIcon]="'heroicons_solid:search'"
    ></mat-icon>

    <input
        data-test-id="merchant-selector"
        [placeholder]="'Select merchant'"
        #merchantSelectorInput
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (ngModelChange)="changeInput($event)"
        class="text-p3 text-secondary"
    />

    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="merchantSelected($event)"
    >
        <mat-option disabled *ngIf="isLoading">
            <mat-spinner diameter="32"></mat-spinner>
        </mat-option>

        <div
            *ngIf="showRecentMerchants"
            class="
                fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded
                min-w-56
            "
        >
            <mat-label>
                <div class="p-4 border border-black">
                    <tilled-label-l1> Recent Merchants</tilled-label-l1>
                </div>
            </mat-label>
            <mat-option
                class="multiline-mat-option"
                *ngFor="let merchant of recentMerchantAccounts"
                [value]="merchant"
                [disabled]="disabledAccountIds.includes(merchant.id)"
            >
                <div class="pt-2 pb-4">
                    <div *ngIf="merchant.type === 'partner'">
                        <div>
                            {{ merchant.name }}
                        </div>
                    </div>

                    <div>
                        <span
                            ><b>{{ merchant.name }}</b></span
                        >
                        <div
                            *ngIf="
                                merchant.business_profile.representatives
                                    .length > 0
                            "
                        >
                            <br />
                            <small
                                *ngIf="
                                    merchant.business_profile.representatives
                                        .length > 0
                                "
                                ><em
                                    >{{
                                        merchant.business_profile.address.city
                                    }},
                                    {{
                                        merchant.business_profile.address.state
                                    }}</em
                                ></small
                            >
                        </div>
                        <div
                            *ngIf="
                                merchant.business_profile.representatives
                                    .length > 0
                            "
                        >
                            <br />
                            <small
                                *ngIf="
                                    merchant.business_profile.representatives
                                        .length > 0
                                "
                                ><em
                                    >Owner:
                                    {{
                                        merchant.business_profile
                                            .representatives[0].first_name
                                    }}
                                    {{
                                        merchant.business_profile
                                            .representatives[0].last_name
                                    }}</em
                                ></small
                            >
                        </div>
                        <br />
                        <small
                            ><em>{{ merchant.id }}</em></small
                        >
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-option>
        </div>
        <mat-label>
            <div class="p-4 border border-black">
                <tilled-label-l1> All Merchants</tilled-label-l1>
            </div></mat-label
        >
        <mat-option
            class="multiline-mat-option"
            *ngFor="let merchant of filteredMerchants | async"
            [value]="merchant"
            [disabled]="disabledAccountIds.includes(merchant.id)"
        >
            <div class="pt-2 pb-4">
                <div *ngIf="merchant.type === 'partner'">
                    <div>
                        {{ merchant.name }}
                    </div>
                </div>

                <div>
                    <span
                        ><b>{{ merchant.name }}</b></span
                    >
                    <div
                        *ngIf="
                            merchant.business_profile.representatives.length > 0
                        "
                    >
                        <br />
                        <small
                            ><em
                                >{{ merchant.business_profile.address.city }},
                                {{
                                    merchant.business_profile.address.state
                                }}</em
                            ></small
                        >
                    </div>
                    <div
                        *ngIf="
                            merchant.business_profile.representatives.length > 0
                        "
                    >
                        <br />
                        <small
                            ><em
                                >Owner:
                                {{
                                    merchant.business_profile.representatives[0]
                                        .first_name
                                }}
                                {{
                                    merchant.business_profile.representatives[0]
                                        .last_name
                                }}</em
                            ></small
                        >
                    </div>
                    <br />
                    <small
                        ><em>{{ merchant.id }}</em></small
                    >
                </div>
            </div>
            <mat-divider></mat-divider>
        </mat-option>
        <mat-option disabled *ngIf="(filteredMerchants | async)?.length == 0">
            No results
        </mat-option>
    </mat-autocomplete>

    <button
        data-test-id="merchant-selector-clear-Btn"
        mat-button
        *ngIf="showClear"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearchText()"
    >
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
