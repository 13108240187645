import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { ApplicationStep } from 'app/core/models/application-step';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isPhoneNumber } from 'app/shared/validators/phone.validator';
import { isValidUrl } from 'app/shared/validators/url.validator';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { BusinessLegalEntityAddress, MerchantApplication, PartialAddressV2 } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'business-contact-step',
  templateUrl: './business-contact-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessContactStepComponent extends ComponentBase implements OnInit, OnDestroy {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];
  public businessContactForm: FormGroup;
  public steps: ApplicationStep[];
  public merchantApp: MerchantApplication;
  public selectorTypes: SelectorTypes = new SelectorTypes();
  private stateAndProvinceMap = SelectorTypes.stateAndProvinceMap;

  constructor(private _formBuilder: FormBuilder, private _merchantAppService: MerchantAppService) {
    super();
    this._merchantAppService.merchantAppSteps$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((steps) => (this.steps = steps));
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => (this.merchantApp = cloneDeep(application)));
  }

  ngOnInit(): void {
    this.businessContactForm = this._formBuilder.group({
      phone: new FormControl(this.merchantApp.business_legal_entity?.phone || null, [isPhoneNumber('US')]),
      email: new FormControl(this.merchantApp.business_legal_entity?.company_email || null, [
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
      ]),
      website: new FormControl(this.merchantApp.business_legal_entity?.website || null, [
        Validators.pattern(/^https:\/\/(.*)/i),
        isValidUrl(),
      ]),
      street: new FormControl(this.merchantApp.business_legal_entity?.address?.street || null),
      street2: new FormControl(this.merchantApp.business_legal_entity?.address?.street2 || null),
      city: new FormControl(this.merchantApp.business_legal_entity?.address?.city || null),
      state: new FormControl(
        SelectorTypes.stateAndProvinceMap.get(this.merchantApp.business_legal_entity?.address?.state) || null,
      ),
      zip: new FormControl(this.merchantApp.business_legal_entity?.address?.zip || null),
      country: new FormControl(this.merchantApp.business_legal_entity?.address?.country || null),
    });
    if (!this.merchantApp.business_legal_entity.address) {
      this.merchantApp.business_legal_entity.address = {} as BusinessLegalEntityAddress;
    }

    if (this.disabled$) {
      this.subscriptions.push(this.disabled$.subscribe((isDisabled) => {
        if (isDisabled) {
          this.businessContactForm.disable();
        } else {
          this.businessContactForm.enable();
        }
      }));
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(this.saveApp$.subscribe((save) => {
          if (save) {
            this.onContinueClicked(save);
          }
        }));
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(this.checkUnsavedApp$.subscribe((check) => {
          if (check) {
            this.markAppUnsaved.emit(this.isAppUnsaved());
          }
        }));
      }
      if (this.resetApp$) {
        this.subscriptions.push(this.resetApp$.subscribe((reset) => {
          if (reset) {
            this.resetApplication();
          }
        }));
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public setBusinessAddress(address: PartialAddressV2): void {
    this.businessContactForm.patchValue({
      street: address.street,
      city: address.city,
      state: SelectorTypes.stateAndProvinceMap.get(address.state),
      zip: address.postal_code,
    });
    this.businessContactForm.markAsTouched();
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(0);
  }

  onContinueClicked(accountId?: string) {
    this.businessContactForm.markAllAsTouched();
    if (this.businessContactForm.invalid) {
      this.scrollToError();
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.businessContactForm.controls) {
      const control = this.businessContactForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.merchantApp.business_legal_entity.phone = this.businessContactForm.value.phone;
    this.merchantApp.business_legal_entity.company_email = this.businessContactForm.value.email;
    this.merchantApp.business_legal_entity.website = this.businessContactForm.value.website;
    this.merchantApp.business_legal_entity.address.street = this.businessContactForm.value.street;
    this.merchantApp.business_legal_entity.address.street2 = this.businessContactForm.value.street2;
    this.merchantApp.business_legal_entity.address.city = this.businessContactForm.value.city;
    this.merchantApp.business_legal_entity.address.state = this.businessContactForm.value.state
      ? [...this.stateAndProvinceMap].find(([key, val]) => val === this.businessContactForm.value.state)[0]
      : null;
    this.merchantApp.business_legal_entity.address.zip = this.businessContactForm.value.zip;
    this.merchantApp.business_legal_entity.address.country = SelectorTypes.getCountryFromState(
      this.merchantApp.business_legal_entity.address.state,
    );

    this._merchantAppService.updateMerchantApplication(this.merchantApp, 2, accountId);
  }

  private isAppUnsaved(): boolean {
    const state = this.businessContactForm.value.state
      ? [...this.stateAndProvinceMap].find(
          ([key, val]) => val === this.businessContactForm.value.state,
        )[0]
      : null;

    return !(
      _compareTwoStrings(this.merchantApp.business_legal_entity?.phone, 
        this.businessContactForm.value.phone) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.company_email, 
        this.businessContactForm.value.email) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.website, 
        this.businessContactForm.value.website) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.address?.street, 
        this.businessContactForm.value.street) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.address?.street2, 
        this.businessContactForm.value.street2) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.address?.city, 
        this.businessContactForm.value.city) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.address?.state, 
        state) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.address?.zip, 
        this.businessContactForm.value.zip) &&
      _compareTwoStrings(this.merchantApp.business_legal_entity?.address?.country, 
        SelectorTypes.getCountryFromState(state))
    );
  }

  private resetApplication(): void {
    const state = this.merchantApp.business_legal_entity?.address?.state
      ? [...this.stateAndProvinceMap].find(
          ([key, val]) => key === this.merchantApp.business_legal_entity?.address?.state,
        )[1]
      : null;
    this.businessContactForm.controls['phone'].setValue(this.merchantApp.business_legal_entity?.phone);
    this.businessContactForm.controls['email'].setValue(this.merchantApp.business_legal_entity?.company_email);
    this.businessContactForm.controls['website'].setValue(this.merchantApp.business_legal_entity?.website);
    this.businessContactForm.controls['street'].setValue(this.merchantApp.business_legal_entity?.address?.street);
    this.businessContactForm.controls['street2'].setValue(this.merchantApp.business_legal_entity?.address?.street2);
    this.businessContactForm.controls['city'].setValue(this.merchantApp.business_legal_entity?.address?.city);
    this.businessContactForm.controls['state'].setValue(state);
    this.businessContactForm.controls['zip'].setValue(this.merchantApp.business_legal_entity?.address?.zip);
    this.businessContactForm.controls['country'].setValue(
      SelectorTypes.getCountryFromState(this.merchantApp.business_legal_entity?.address?.state)
    );
  }

  scrollTo(el: Element): void {
    if (el) {
       el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
      const firstElementWithError = document.querySelector('.mat-form-field-invalid');
      this.scrollTo(firstElementWithError);
  }
}
