/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CheckoutSessionConfirmParams } from '../model/checkoutSessionConfirmParams';
// @ts-ignore
import { DashboardFilters } from '../model/dashboardFilters';
// @ts-ignore
import { DashboardOnboardingSummary } from '../model/dashboardOnboardingSummary';
// @ts-ignore
import { DashboardStats } from '../model/dashboardStats';
// @ts-ignore
import { DashboardTransactionSummary } from '../model/dashboardTransactionSummary';
// @ts-ignore
import { InternalCheckoutSession } from '../model/internalCheckoutSession';
// @ts-ignore
import { InternalGetPricingTemplateAccounts200Response } from '../model/internalGetPricingTemplateAccounts200Response';
// @ts-ignore
import { InternalGetPricingTemplates200Response } from '../model/internalGetPricingTemplates200Response';
// @ts-ignore
import { InternalListBalanceTransactions200Response } from '../model/internalListBalanceTransactions200Response';
// @ts-ignore
import { InternalListPayouts200Response } from '../model/internalListPayouts200Response';
// @ts-ignore
import { InternalPayout } from '../model/internalPayout';
// @ts-ignore
import { ListConnectedAccounts200Response } from '../model/listConnectedAccounts200Response';
// @ts-ignore
import { MerchantApplication } from '../model/merchantApplication';
// @ts-ignore
import { MinimumThresholdDto } from '../model/minimumThresholdDto';
// @ts-ignore
import { PayoutBalanceTransactionsSummary } from '../model/payoutBalanceTransactionsSummary';
// @ts-ignore
import { PricingTemplate } from '../model/pricingTemplate';
// @ts-ignore
import { PricingTemplateCreateParams } from '../model/pricingTemplateCreateParams';
// @ts-ignore
import { UpdatePricingTemplateBodyParams } from '../model/updatePricingTemplateBodyParams';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ConfirmCheckoutSessionRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    checkoutSessionId: string;
    checkoutSessionConfirmParams: CheckoutSessionConfirmParams;
}

export interface GetInternalCheckoutSessionRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    checkoutSessionId: string;
}

export interface InternalApplyPricingTemplateRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface InternalArchivePricingTemplateRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface InternalCreatePricingTemplateRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    pricingTemplateCreateParams: PricingTemplateCreateParams;
}

export interface InternalGetDashboardFiltersRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
}

export interface InternalGetDashboardOnboardingSummaryRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** Three-letter ISO currency code, in lowercase. */
    currency?: 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
}

export interface InternalGetDashboardStatsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** Date of the start of the data to include in the summary. */
    startAt: string;
    /** Date of the end of the data to include in the summary. */
    endAt: string;
    /** Three-letter ISO currency code, in lowercase. */
    currency?: 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
}

export interface InternalGetDashboardTransactionSummaryRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** Date of the start of the data to include in the summary. */
    startAt: string;
    /** Date of the end of the data to include in the summary. */
    endAt: string;
    /** Three-letter ISO currency code, in lowercase. */
    currency?: 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
}

export interface InternalGetMinimumThresholdsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    currencies: Array<string>;
}

export interface InternalGetPayoutRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    /** An array of optional include parameters, specifying extra properties to return. Currently for this endpoint only accepts \&#39;transaction_count\&#39;. In the query parameters, this is specified as include&#x3D;value1,value2,value3,etc. */
    include?: Array<'transaction_count'>;
}

export interface InternalGetPayoutBalanceTransactionsSummaryRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface InternalGetPricingTemplateRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
}

export interface InternalGetPricingTemplateAccountsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
}

export interface InternalGetPricingTemplatesRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** String indicating the status to filter the result by. */
    status?: Array<'active' | 'archived' | 'pending'>;
    /** String indicating the type to filter the result by. */
    type?: 'card' | 'ach_debit' | 'eft_debit';
    /** A merchant accountId using pricing templates associated with the provided partner accountId. If provided, the returned pricing templates will be ones that are applied to the merchant accountId provided, if any. */
    merchantAccountId?: string;
    /** If a merchant accountId is provided, the query will return pricing templates associated with the merchant that have this activation status. */
    merchantActivationStatus?: 'active' | 'pending' | 'cancelled';
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
}

export interface InternalListBalanceTransactionsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** Only returns transactions that were paid out on the specified payout ID. */
    payoutId: string;
    /** Exclude transactions of the given types. */
    excludedTypes?: Array<'charge' | 'charge_failure_refund' | 'dispute' | 'dispute_won' | 'refund' | 'refund_failure' | 'adjustment' | 'commission' | 'payout' | 'payout_cancel' | 'payout_failure' | 'fee' | 'platform_fee' | 'platform_fee_refund' | 'charge_fee' | 'refund_fee' | 'account_fee' | 'payment_method_fee' | 'tilled_fee'>;
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
}

export interface InternalListConnectedAccountsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** &#x60;metadata&#x60; key-value pairs to filter by. Only exact matches on the key-value pair(s) will be returned. Example: &#x60;?metadata[internal_customer_id]&#x3D;7cb1159d-875e-47ae-a309-319fa7ff395b&#x60;. */
    metadata?: { [key: string]: string; };
    /** The partial search of text fields. Supports searching by &#x60;account.name&#x60;, &#x60;account.business_profile.legal_name&#x60;, &#x60;account.id&#x60;, &#x60;account.email&#x60;, &#x60;user.name&#x60;, &#x60;user.email&#x60; */
    q?: string;
    /** The sort parameters, value:direction. Possible values: &#x60;name&#x60;, &#x60;created_at&#x60;. Possible directions:  &#x60;asc&#x60;, &#x60;desc&#x60;  For example &#x60;name:asc&#x60;.  Default: &#x60;created_at:desc&#x60; */
    sort?: string;
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
    /** Filter search results by capability status.  Example &#x60;capability_status:created,active,withdrawn&#x60;. */
    capabilityStatus?: Array<'created' | 'started' | 'submitted' | 'test' | 'active' | 'disabled' | 'in_review' | 'rejected' | 'withdrawn'>;
}

export interface InternalListPayoutsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    /** Minimum &#x60;created_at&#x60; value to filter by (inclusive). Cannot be paired with paid_at_gte or paid_at_lte. */
    createdAtGte?: string;
    /** Maximum &#x60;created_at&#x60; value to filter by (inclusive). Cannot be paired with paid_at_gte or paid_at_lte. */
    createdAtLte?: string;
    /** Minimum &#x60;paid_at&#x60; value to filter by (inclusive). Cannot be paired with created_at_gte or created_at_lte. */
    paidAtGte?: string;
    /** Maximum &#x60;paid_at&#x60; value to filter by (inclusive). Cannot be paired with created_at_gte or created_at_lte. */
    paidAtLte?: string;
    /** Only return payouts that have the given status. */
    status?: 'canceled' | 'failed' | 'in_transit' | 'paid' | 'pending';
    /** An array of optional include parameters, specifying extra properties to return. Currently for this endpoint only accepts \&#39;transaction_count\&#39;. In the query parameters, this is specified as include&#x3D;value1,value2,value3,etc. */
    include?: 'transaction_count';
    /** Whether or not to include the results from any connected accounts. */
    includeConnectedAccounts?: boolean;
    /** The (zero-based) offset of the first item in the collection to return. */
    offset?: number;
    /** The maximum number of entries to return. If the value exceeds the maximum, then the maximum value will be used. */
    limit?: number;
}

export interface InternalRetrieveMerchantApplicationRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
}

export interface InternalRetrieveMerchantApplicationByAccountIdRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    accountId: string;
}

export interface InternalUpdatePricingTemplateRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    updatePricingTemplateBodyParams: UpdatePricingTemplateBodyParams;
}


@Injectable({
  providedIn: 'root'
})
export class InternalService {

    protected basePath = 'https://api.tilled.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Confirm a Checkout Session
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmCheckoutSession(requestParameters: ConfirmCheckoutSessionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalCheckoutSession>;
    public confirmCheckoutSession(requestParameters: ConfirmCheckoutSessionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalCheckoutSession>>;
    public confirmCheckoutSession(requestParameters: ConfirmCheckoutSessionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalCheckoutSession>>;
    public confirmCheckoutSession(requestParameters: ConfirmCheckoutSessionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling confirmCheckoutSession.');
        }
        const checkoutSessionId = requestParameters.checkoutSessionId;
        if (checkoutSessionId === null || checkoutSessionId === undefined) {
            throw new Error('Required parameter checkoutSessionId was null or undefined when calling confirmCheckoutSession.');
        }
        const checkoutSessionConfirmParams = requestParameters.checkoutSessionConfirmParams;
        if (checkoutSessionConfirmParams === null || checkoutSessionConfirmParams === undefined) {
            throw new Error('Required parameter checkoutSessionConfirmParams was null or undefined when calling confirmCheckoutSession.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/checkout-sessions/${this.configuration.encodeParam({name: "checkoutSessionId", value: checkoutSessionId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/confirm`;
        return this.httpClient.request<InternalCheckoutSession>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: checkoutSessionConfirmParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a checkout session
     * Retrieves the details of a checkout session
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInternalCheckoutSession(requestParameters: GetInternalCheckoutSessionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalCheckoutSession>;
    public getInternalCheckoutSession(requestParameters: GetInternalCheckoutSessionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalCheckoutSession>>;
    public getInternalCheckoutSession(requestParameters: GetInternalCheckoutSessionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalCheckoutSession>>;
    public getInternalCheckoutSession(requestParameters: GetInternalCheckoutSessionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getInternalCheckoutSession.');
        }
        const checkoutSessionId = requestParameters.checkoutSessionId;
        if (checkoutSessionId === null || checkoutSessionId === undefined) {
            throw new Error('Required parameter checkoutSessionId was null or undefined when calling getInternalCheckoutSession.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/checkout-sessions/${this.configuration.encodeParam({name: "checkoutSessionId", value: checkoutSessionId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<InternalCheckoutSession>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Apply a pricing template to a merchant account
     * Updates status of pricing template and associates it with merchant account.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalApplyPricingTemplate(requestParameters: InternalApplyPricingTemplateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PricingTemplate>;
    public internalApplyPricingTemplate(requestParameters: InternalApplyPricingTemplateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PricingTemplate>>;
    public internalApplyPricingTemplate(requestParameters: InternalApplyPricingTemplateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PricingTemplate>>;
    public internalApplyPricingTemplate(requestParameters: InternalApplyPricingTemplateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalApplyPricingTemplate.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalApplyPricingTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/apply`;
        return this.httpClient.request<PricingTemplate>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Archive a pricing template
     * Archives the pricing template with the given ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalArchivePricingTemplate(requestParameters: InternalArchivePricingTemplateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PricingTemplate>;
    public internalArchivePricingTemplate(requestParameters: InternalArchivePricingTemplateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PricingTemplate>>;
    public internalArchivePricingTemplate(requestParameters: InternalArchivePricingTemplateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PricingTemplate>>;
    public internalArchivePricingTemplate(requestParameters: InternalArchivePricingTemplateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalArchivePricingTemplate.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalArchivePricingTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/archive`;
        return this.httpClient.request<PricingTemplate>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a pricing template
     * Creates a pricing template for a partner.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalCreatePricingTemplate(requestParameters: InternalCreatePricingTemplateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PricingTemplate>;
    public internalCreatePricingTemplate(requestParameters: InternalCreatePricingTemplateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PricingTemplate>>;
    public internalCreatePricingTemplate(requestParameters: InternalCreatePricingTemplateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PricingTemplate>>;
    public internalCreatePricingTemplate(requestParameters: InternalCreatePricingTemplateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalCreatePricingTemplate.');
        }
        const pricingTemplateCreateParams = requestParameters.pricingTemplateCreateParams;
        if (pricingTemplateCreateParams === null || pricingTemplateCreateParams === undefined) {
            throw new Error('Required parameter pricingTemplateCreateParams was null or undefined when calling internalCreatePricingTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates`;
        return this.httpClient.request<PricingTemplate>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: pricingTemplateCreateParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve dashboard filters
     * Retrieves various filter values for the dashboard.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetDashboardFilters(requestParameters: InternalGetDashboardFiltersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DashboardFilters>;
    public internalGetDashboardFilters(requestParameters: InternalGetDashboardFiltersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DashboardFilters>>;
    public internalGetDashboardFilters(requestParameters: InternalGetDashboardFiltersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DashboardFilters>>;
    public internalGetDashboardFilters(requestParameters: InternalGetDashboardFiltersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetDashboardFilters.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/dashboard/filters`;
        return this.httpClient.request<DashboardFilters>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve dashboard onboarding summary
     * Retrieves a summary of merchant application statuses for the partner account.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetDashboardOnboardingSummary(requestParameters: InternalGetDashboardOnboardingSummaryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DashboardOnboardingSummary>;
    public internalGetDashboardOnboardingSummary(requestParameters: InternalGetDashboardOnboardingSummaryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DashboardOnboardingSummary>>;
    public internalGetDashboardOnboardingSummary(requestParameters: InternalGetDashboardOnboardingSummaryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DashboardOnboardingSummary>>;
    public internalGetDashboardOnboardingSummary(requestParameters: InternalGetDashboardOnboardingSummaryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetDashboardOnboardingSummary.');
        }
        const currency = requestParameters.currency;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/dashboard/onboarding-summary`;
        return this.httpClient.request<DashboardOnboardingSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve dashboard stats
     * Retrieves various dashboard stats.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetDashboardStats(requestParameters: InternalGetDashboardStatsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DashboardStats>;
    public internalGetDashboardStats(requestParameters: InternalGetDashboardStatsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DashboardStats>>;
    public internalGetDashboardStats(requestParameters: InternalGetDashboardStatsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DashboardStats>>;
    public internalGetDashboardStats(requestParameters: InternalGetDashboardStatsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetDashboardStats.');
        }
        const startAt = requestParameters.startAt;
        if (startAt === null || startAt === undefined) {
            throw new Error('Required parameter startAt was null or undefined when calling internalGetDashboardStats.');
        }
        const endAt = requestParameters.endAt;
        if (endAt === null || endAt === undefined) {
            throw new Error('Required parameter endAt was null or undefined when calling internalGetDashboardStats.');
        }
        const currency = requestParameters.currency;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startAt !== undefined && startAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startAt, 'start_at');
        }
        if (endAt !== undefined && endAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endAt, 'end_at');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/dashboard/stats`;
        return this.httpClient.request<DashboardStats>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve dashboard transaction summary
     * Returns a summary of balance transactions grouped by source_type.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetDashboardTransactionSummary(requestParameters: InternalGetDashboardTransactionSummaryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DashboardTransactionSummary>;
    public internalGetDashboardTransactionSummary(requestParameters: InternalGetDashboardTransactionSummaryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DashboardTransactionSummary>>;
    public internalGetDashboardTransactionSummary(requestParameters: InternalGetDashboardTransactionSummaryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DashboardTransactionSummary>>;
    public internalGetDashboardTransactionSummary(requestParameters: InternalGetDashboardTransactionSummaryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetDashboardTransactionSummary.');
        }
        const startAt = requestParameters.startAt;
        if (startAt === null || startAt === undefined) {
            throw new Error('Required parameter startAt was null or undefined when calling internalGetDashboardTransactionSummary.');
        }
        const endAt = requestParameters.endAt;
        if (endAt === null || endAt === undefined) {
            throw new Error('Required parameter endAt was null or undefined when calling internalGetDashboardTransactionSummary.');
        }
        const currency = requestParameters.currency;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startAt !== undefined && startAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startAt, 'start_at');
        }
        if (endAt !== undefined && endAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endAt, 'end_at');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/dashboard/transaction-summary`;
        return this.httpClient.request<DashboardTransactionSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get minimum thresholds for creating pricing template
     * Gets minimum thresholds for credit and debit for all currencies provided for verifying pricing-template create form
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetMinimumThresholds(requestParameters: InternalGetMinimumThresholdsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<MinimumThresholdDto>>;
    public internalGetMinimumThresholds(requestParameters: InternalGetMinimumThresholdsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<MinimumThresholdDto>>>;
    public internalGetMinimumThresholds(requestParameters: InternalGetMinimumThresholdsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<MinimumThresholdDto>>>;
    public internalGetMinimumThresholds(requestParameters: InternalGetMinimumThresholdsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetMinimumThresholds.');
        }
        const currencies = requestParameters.currencies;
        if (currencies === null || currencies === undefined) {
            throw new Error('Required parameter currencies was null or undefined when calling internalGetMinimumThresholds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (currencies) {
            currencies.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'currencies');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates/minimum-thresholds`;
        return this.httpClient.request<Array<MinimumThresholdDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a payout
     * Internal: Retrieves the details of an existing payout with the given ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetPayout(requestParameters: InternalGetPayoutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalPayout>;
    public internalGetPayout(requestParameters: InternalGetPayoutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalPayout>>;
    public internalGetPayout(requestParameters: InternalGetPayoutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalPayout>>;
    public internalGetPayout(requestParameters: InternalGetPayoutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetPayout.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalGetPayout.');
        }
        const include = requestParameters.include;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (include) {
            include.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'include');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/payouts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<InternalPayout>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve payout balance transactions summary
     * Retrieves a summary of balance transactions for an existing payout.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetPayoutBalanceTransactionsSummary(requestParameters: InternalGetPayoutBalanceTransactionsSummaryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PayoutBalanceTransactionsSummary>;
    public internalGetPayoutBalanceTransactionsSummary(requestParameters: InternalGetPayoutBalanceTransactionsSummaryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PayoutBalanceTransactionsSummary>>;
    public internalGetPayoutBalanceTransactionsSummary(requestParameters: InternalGetPayoutBalanceTransactionsSummaryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PayoutBalanceTransactionsSummary>>;
    public internalGetPayoutBalanceTransactionsSummary(requestParameters: InternalGetPayoutBalanceTransactionsSummaryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetPayoutBalanceTransactionsSummary.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalGetPayoutBalanceTransactionsSummary.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/payouts/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/summary`;
        return this.httpClient.request<PayoutBalanceTransactionsSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a pricing template
     * Retrieves the pricing template with the given ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetPricingTemplate(requestParameters: InternalGetPricingTemplateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PricingTemplate>;
    public internalGetPricingTemplate(requestParameters: InternalGetPricingTemplateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PricingTemplate>>;
    public internalGetPricingTemplate(requestParameters: InternalGetPricingTemplateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PricingTemplate>>;
    public internalGetPricingTemplate(requestParameters: InternalGetPricingTemplateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetPricingTemplate.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalGetPricingTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PricingTemplate>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List pricing template accounts
     * Retrieve all accounts configured to use a particular pricing template.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetPricingTemplateAccounts(requestParameters: InternalGetPricingTemplateAccountsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalGetPricingTemplateAccounts200Response>;
    public internalGetPricingTemplateAccounts(requestParameters: InternalGetPricingTemplateAccountsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalGetPricingTemplateAccounts200Response>>;
    public internalGetPricingTemplateAccounts(requestParameters: InternalGetPricingTemplateAccountsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalGetPricingTemplateAccounts200Response>>;
    public internalGetPricingTemplateAccounts(requestParameters: InternalGetPricingTemplateAccountsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetPricingTemplateAccounts.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalGetPricingTemplateAccounts.');
        }
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/accounts`;
        return this.httpClient.request<InternalGetPricingTemplateAccounts200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all pricing templates
     * Retrieves all pricing templates for a partner.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalGetPricingTemplates(requestParameters: InternalGetPricingTemplatesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalGetPricingTemplates200Response>;
    public internalGetPricingTemplates(requestParameters: InternalGetPricingTemplatesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalGetPricingTemplates200Response>>;
    public internalGetPricingTemplates(requestParameters: InternalGetPricingTemplatesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalGetPricingTemplates200Response>>;
    public internalGetPricingTemplates(requestParameters: InternalGetPricingTemplatesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalGetPricingTemplates.');
        }
        const status = requestParameters.status;
        const type = requestParameters.type;
        const merchantAccountId = requestParameters.merchantAccountId;
        const merchantActivationStatus = requestParameters.merchantActivationStatus;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (status) {
            status.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'status');
            })
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (merchantAccountId !== undefined && merchantAccountId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantAccountId, 'merchant_account_id');
        }
        if (merchantActivationStatus !== undefined && merchantActivationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>merchantActivationStatus, 'merchant_activation_status');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates`;
        return this.httpClient.request<InternalGetPricingTemplates200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all balance transactions
     * Returns a list of transactions that have contributed to the Tilled account balance (e.g. charges, refunds, and so forth). The transactions are returned in sorted order, with the most recent transactions appearing first.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalListBalanceTransactions(requestParameters: InternalListBalanceTransactionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalListBalanceTransactions200Response>;
    public internalListBalanceTransactions(requestParameters: InternalListBalanceTransactionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalListBalanceTransactions200Response>>;
    public internalListBalanceTransactions(requestParameters: InternalListBalanceTransactionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalListBalanceTransactions200Response>>;
    public internalListBalanceTransactions(requestParameters: InternalListBalanceTransactionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalListBalanceTransactions.');
        }
        const payoutId = requestParameters.payoutId;
        if (payoutId === null || payoutId === undefined) {
            throw new Error('Required parameter payoutId was null or undefined when calling internalListBalanceTransactions.');
        }
        const excludedTypes = requestParameters.excludedTypes;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (payoutId !== undefined && payoutId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>payoutId, 'payout_id');
        }
        if (excludedTypes) {
            excludedTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'excluded_types');
            })
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/balance-transactions`;
        return this.httpClient.request<InternalListBalanceTransactions200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all connected accounts
     * Returns a list of a partner\&#39;s connected accounts.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalListConnectedAccounts(requestParameters: InternalListConnectedAccountsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ListConnectedAccounts200Response>;
    public internalListConnectedAccounts(requestParameters: InternalListConnectedAccountsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ListConnectedAccounts200Response>>;
    public internalListConnectedAccounts(requestParameters: InternalListConnectedAccountsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ListConnectedAccounts200Response>>;
    public internalListConnectedAccounts(requestParameters: InternalListConnectedAccountsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalListConnectedAccounts.');
        }
        const metadata = requestParameters.metadata;
        const q = requestParameters.q;
        const sort = requestParameters.sort;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const capabilityStatus = requestParameters.capabilityStatus;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (metadata !== undefined && metadata !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>metadata, 'metadata');
        }
        if (q !== undefined && q !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>q, 'q');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (capabilityStatus) {
            capabilityStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'capability_status');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/accounts/connected`;
        return this.httpClient.request<ListConnectedAccounts200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all payouts
     * Returns a list of existing payouts. The payouts are returned in sorted order, with the most recent payouts appearing first.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalListPayouts(requestParameters: InternalListPayoutsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<InternalListPayouts200Response>;
    public internalListPayouts(requestParameters: InternalListPayoutsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<InternalListPayouts200Response>>;
    public internalListPayouts(requestParameters: InternalListPayoutsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<InternalListPayouts200Response>>;
    public internalListPayouts(requestParameters: InternalListPayoutsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalListPayouts.');
        }
        const createdAtGte = requestParameters.createdAtGte;
        const createdAtLte = requestParameters.createdAtLte;
        const paidAtGte = requestParameters.paidAtGte;
        const paidAtLte = requestParameters.paidAtLte;
        const status = requestParameters.status;
        const include = requestParameters.include;
        const includeConnectedAccounts = requestParameters.includeConnectedAccounts;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (createdAtGte !== undefined && createdAtGte !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAtGte, 'created_at_gte');
        }
        if (createdAtLte !== undefined && createdAtLte !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAtLte, 'created_at_lte');
        }
        if (paidAtGte !== undefined && paidAtGte !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paidAtGte, 'paid_at_gte');
        }
        if (paidAtLte !== undefined && paidAtLte !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paidAtLte, 'paid_at_lte');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (include !== undefined && include !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>include, 'include');
        }
        if (includeConnectedAccounts !== undefined && includeConnectedAccounts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeConnectedAccounts, 'include_connected_accounts');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/payouts`;
        return this.httpClient.request<InternalListPayouts200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Merchant Application
     * Retrieve merchant application
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalRetrieveMerchantApplication(requestParameters: InternalRetrieveMerchantApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<MerchantApplication>;
    public internalRetrieveMerchantApplication(requestParameters: InternalRetrieveMerchantApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<MerchantApplication>>;
    public internalRetrieveMerchantApplication(requestParameters: InternalRetrieveMerchantApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<MerchantApplication>>;
    public internalRetrieveMerchantApplication(requestParameters: InternalRetrieveMerchantApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalRetrieveMerchantApplication.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/merchant-applications`;
        return this.httpClient.request<MerchantApplication>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Merchant Application By Account ID
     * Retrieve merchant application
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalRetrieveMerchantApplicationByAccountId(requestParameters: InternalRetrieveMerchantApplicationByAccountIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<MerchantApplication>;
    public internalRetrieveMerchantApplicationByAccountId(requestParameters: InternalRetrieveMerchantApplicationByAccountIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<MerchantApplication>>;
    public internalRetrieveMerchantApplicationByAccountId(requestParameters: InternalRetrieveMerchantApplicationByAccountIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<MerchantApplication>>;
    public internalRetrieveMerchantApplicationByAccountId(requestParameters: InternalRetrieveMerchantApplicationByAccountIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalRetrieveMerchantApplicationByAccountId.');
        }
        const accountId = requestParameters.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling internalRetrieveMerchantApplicationByAccountId.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/merchant-applications/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MerchantApplication>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update pricing template
     * Update pricing template nickname.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalUpdatePricingTemplate(requestParameters: InternalUpdatePricingTemplateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<PricingTemplate>;
    public internalUpdatePricingTemplate(requestParameters: InternalUpdatePricingTemplateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<PricingTemplate>>;
    public internalUpdatePricingTemplate(requestParameters: InternalUpdatePricingTemplateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<PricingTemplate>>;
    public internalUpdatePricingTemplate(requestParameters: InternalUpdatePricingTemplateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling internalUpdatePricingTemplate.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling internalUpdatePricingTemplate.');
        }
        const updatePricingTemplateBodyParams = requestParameters.updatePricingTemplateBodyParams;
        if (updatePricingTemplateBodyParams === null || updatePricingTemplateBodyParams === undefined) {
            throw new Error('Required parameter updatePricingTemplateBodyParams was null or undefined when calling internalUpdatePricingTemplate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/internal/pricing-templates/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PricingTemplate>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: updatePricingTemplateBodyParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
