/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentIntentLevel3 } from './paymentIntentLevel3';


export interface PaymentIntentCreateParams { 
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Individual keys can be unset by posting an empty value (i.e. `\'\'` or `null`) to them. All keys can be unset by posting an empty value (i.e. `{}` or `null`) to `metadata`.
     */
    metadata?: { [key: string]: string; };
    /**
     * The list of payment method types (e.g. card) that this PaymentIntent is allowed to use.
     */
    payment_method_types: Array<PaymentIntentCreateParams.PaymentMethodTypesEnum>;
    /**
     * Provides information about a payment that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that’s set on the account to form the complete statement descriptor. Maximum 20 characters for the concatenated descriptor.
     */
    statement_descriptor_suffix?: string;
    /**
     * Amount intended to be collected by this PaymentIntent. A positive integer representing how much to charge in the smallest currency unit (e.g., 100 cents to charge $1.00)
     */
    amount: number;
    /**
     * The amount of the platform fee (if any) that will be requested to be applied to the payment and transferred to the `partner` account. The amount of the fee collected will be capped at the total payment amount.
     */
    platform_fee_amount?: number;
    /**
     * Three-letter ISO currency code, in lowercase.
     */
    currency: PaymentIntentCreateParams.CurrencyEnum;
    /**
     * ID of the PaymentMethod to attach to this PaymentIntent.
     */
    payment_method_id?: string;
    /**
     * Controls when the funds will be captured from the customer’s account.  `automatic` (Default) Tilled automatically captures funds when the customer authorizes the payment.  `manual` Place a hold on funds when the customer authorizes the payment, but don\'t capture the funds until later.
     */
    capture_method?: PaymentIntentCreateParams.CaptureMethodEnum;
    /**
     * Set to \'true\' to attempt to confirm this PaymentIntent immediately. This parameter defaults to `false`.
     */
    confirm?: boolean;
    /**
     * Used to identify authorization requests that use stored credentials to improve authorization rates and reduce fraud.  `consumer_ad_hoc` Ad hoc consumer-initiated request  `merchant_ad_hoc` Unscheduled merchant-initiated request  `merchant_recurring` Scheduled, merchant-initiated recurring request
     */
    occurrence_type?: PaymentIntentCreateParams.OccurrenceTypeEnum;
    level3?: PaymentIntentLevel3;
}
export namespace PaymentIntentCreateParams {
    export type PaymentMethodTypesEnum = 'card' | 'ach_debit' | 'eft_debit';
    export const PaymentMethodTypesEnum = {
        CARD: 'card' as PaymentMethodTypesEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypesEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypesEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type CaptureMethodEnum = 'automatic' | 'manual';
    export const CaptureMethodEnum = {
        AUTOMATIC: 'automatic' as CaptureMethodEnum,
        MANUAL: 'manual' as CaptureMethodEnum
    };
    export type OccurrenceTypeEnum = 'consumer_ad_hoc' | 'merchant_ad_hoc' | 'merchant_recurring';
    export const OccurrenceTypeEnum = {
        CONSUMER_AD_HOC: 'consumer_ad_hoc' as OccurrenceTypeEnum,
        MERCHANT_AD_HOC: 'merchant_ad_hoc' as OccurrenceTypeEnum,
        MERCHANT_RECURRING: 'merchant_recurring' as OccurrenceTypeEnum
    };
}


