import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TilledTextGeneric } from '../tilled-text-generic.component';

@Component({
  selector: 'tilled-heading-generic',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TilledHeadingGeneric implements TilledTextGeneric {
  @Input() white: boolean = false;
  @Input() secondary: boolean = false;
  @Input() primaryHighlight: boolean = false;
  @Input() accent: boolean = false;

  public internalStyles: string = '';
  public styles: string = '';

  constructor() {}

  ngOnInit() {
    this.checkInputs();
  }
  ngOnChanges() {
    this.checkInputs();
  }

  checkInputs() {
    if (this.white) {
      this.styles = ' text-tilled-neutral-100 ';
    } else if (this.secondary) {
      this.styles = ' text-tilled-secondary ';
    } else if (this.primaryHighlight) {
      this.styles += ' text-primary';
    } else if (this.accent) {
      this.styles = ' text-tilled-accent ';
    } else {
      this.styles = ' text-tilled-primary ';
    }

    this.styles += ' font-openSans ';
    this.styles += this.internalStyles;
  }
}
