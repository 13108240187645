import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'tilled-search',
  templateUrl: './tilled-search.component.html',
  styleUrls: ['./tilled-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TilledSearchComponent implements OnInit {
  @Input() controlName: FormControl;
  @Input() placeholder?: string;
  @Input() toolTip?: string;
  @Input() styles?: string = '';

  constructor() {}

  ngOnInit() {}

  clearSearchText() {
    this.controlName.patchValue(null);
  }
}
