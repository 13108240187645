import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { PricingTemplateAppService } from 'app/core/services/pricing-template.app.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'editable-pricing-template-nickname',
  templateUrl: './editable-pricing-template-nickname.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class EditablePricingTemplateNicknameComponent extends ComponentBase implements OnInit {
  @Input() nickname: string;
  @Input() pricingTemplateId: string;
  @Input() accountId: string;
  @Output() successfulSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() errorSubmit: EventEmitter<Error> = new EventEmitter<Error>();
  public editStatus: boolean = false;
  public nameInput: string;
  public loading: boolean = false;
  public errorMessage: string = 'Test Error Message';
  public width: number = 700;

  constructor(
    private _pricingTemplateAppService: PricingTemplateAppService,
    private _alertService: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    this.nameInput = this.nickname;
    this.width = this.nameInput.length * 9.2 + 10;
  }

  onButtonClick() {}

  onStatusToggle() {
    this.editStatus = !this.editStatus;
  }

  onNameInputChange(input) {
    this.nameInput = input;
    this.width = this.nameInput.length * 9.2 + 10;
  }

  onNameEdit() {
    if (this.nameInput === this.nickname) {
      this.onStatusToggle();
    } else if (this.nameInput.length) {
      this.loading = true;
      this._pricingTemplateAppService
        .internalUpdatePricingTemplate(this.pricingTemplateId, this.accountId, this.nameInput)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.nickname = res.name;
            this.editStatus = false;
            this.loading = false;
          },
          error: (err: Error) => {
            this.nameInput = this.nickname;
            this.loading = false;
            this.errorMessage = err.message;
            const alert: TilledAlert = {
              message: this.errorMessage,
              title: 'Error',
              type: 'error',
            };
            this._alertService.showAlert(alert);
          },
        });
    }
  }

  onEditCancel() {
    this.nameInput = this.nickname;
    this.editStatus = false;
  }
}
