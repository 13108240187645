<div class="flex flex-col flex-auto min-w-120">
    <div class="flex justify-end">
        <mat-icon
            style="cursor: pointer"
            (click)="closeDialog()"
            svgIcon="mat_outline:close"
        ></mat-icon>
    </div>
    <ng-container>
        <div class="grid w-full justify-items-center pb-8">
            <!-- Title -->
            <tilled-heading-h2 class="pb-2">Initiate a Refund</tilled-heading-h2>
            <tilled-paragraph-p3
                >Provide refund details below.</tilled-paragraph-p3>

        </div>
        <!-- Form -->
        <form [formGroup]="refundForm" class="">
            <div class="px-8">
                <!-- Amount Start -->
                <outline-input
                    name="amount"
                    showCurrency="'true'"
                    label="Refund Amount"
                    inputTextStyle="mb-2 text-tilled-primary py-1"
                    mask="separator.2"
                    data-test-id="refund-amount"
                >
                </outline-input>
                <div class="-mt-11">
                    <tilled-paragraph-p3>Maximum refund amount is 
                        {{maxRefund | minorUnitsToCurrency}}</tilled-paragraph-p3>
                </div>
                <div
                    class="text-sm text-warn"
                    *ngIf="refundForm.get('amount').hasError('required') && refundForm.get('amount').touched else hasValue"
                >
                    A refund amount is required
                </div>
                <ng-template #hasValue>
                    <div class="text-sm text-warn"
                        *ngIf="refundForm.get('amount').hasError('max') && refundForm.get('amount').touched else checkZero"
                    >
                        Cannot exceed the maximum refund amount
                    </div>
                </ng-template>
                <ng-template #checkZero>
                    <div class="text-sm text-warn"
                        *ngIf="refundForm.get('amount').hasError('min') && refundForm.get('amount').touched else noError"
                    >
                        Refund amount must be greater than 0
                    </div>
                </ng-template>
                <ng-template #noError>
                    <div class="text-sm">&nbsp;</div>
                </ng-template>
                <!-- Amount End -->

                <!-- Reason Start-->
                <div>
                    <mat-form-field floatLabel="always" appearance="standard" class="w-full -mt-4 pb-4">
                        <tilled-label-l1 secondary="true" class=""
                            >Refund Reason</tilled-label-l1
                        >
                        <mat-select
                            class="text-p3 text-secondary pt-4"
                            formControlName="reason"
                            placeholder="Select a refund reason"
                            data-test-id="reason"
                        >
                            <mat-option value="duplicate">Duplicate</mat-option>
                            <mat-option value="fraudulent"
                                >Fraudulent</mat-option
                            >
                            <mat-option value="requested_by_customer"
                                >Requested by customer</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Reason End -->

                <!-- Platform Fee Start -->
                <div *ngIf="!isMerchant && payment.platform_fee_amount">
                    <mat-slide-toggle
                        color="primary"
                        (change)="togglePlatformFeeRefund()"
                        data-test-id="platform-fee-refund"
                    >
                        <tilled-paragraph-p3
                            >Refund platform fee</tilled-paragraph-p3
                        >
                    </mat-slide-toggle>
                    <div class="flex flex-row pt-1">
                        <mat-icon
                            class="-mt-1 -ml-0.5 text-tilled-secondary"
                            style="font-size: 4px; transform: scale(0.7);"
                            svgIcon="heroicons_solid:exclamation-circle"
                        >
                        </mat-icon>
                        <tilled-paragraph-p4 secondary="'true'">Platform fee will be refunded 
                            in proportion to the refund amount</tilled-paragraph-p4>
                    </div>
                </div>
                <!-- Platform Fee End -->
            </div>
        </form>

        <div *ngIf="displayAlert$ | async" class="flex justify-start w-full px-8 pt-2">
            <fuse-alert
                [type]="alertType"
                [appearance]="'outline'"
                [dismissible]="alertDismissible"
                [dismissed]="false"
                [name]="'refundDialogAlertBox'">
                <span fuseAlertTitle>{{alertTitle}}</span>
                {{alertMessage}}
            </fuse-alert>
        </div>

        <div class="flex justify-end w-full px-8 pt-8">
            <!-- Cancel Button -->
            <tilled-button
                data-test-id="canel-refund-Btn"
                bgColor="white"
                styles="text-tilled-secondary"
                [whiteText]="false"
                [rounded]="true"
                buttonText="Cancel"
                (buttonClick)="closeDialog()"
            ></tilled-button>

            <!-- Submit Button -->
            <tilled-button
                data-test-id="submit-refund-Btn"
                bgColor="primary"
                [rounded]="true"
                buttonText="Submit Refund"
                (buttonClick)="submitRefundClicked()"
                [whiteText]="true"
                [disabled]="refundForm.invalid"
            ></tilled-button>
        </div>
    </ng-container>
</div>
