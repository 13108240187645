import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import {
  CheckoutSession,
  CheckoutSessionConfirmParams,
  CheckoutSessionCreateParams,
  CheckoutSessionsService,
  ConfirmCheckoutSessionRequestParams, CreateCheckoutSessionRequestParams, GetCheckoutSessionRequestParams,
  GetInternalCheckoutSessionRequestParams,
  InternalCheckoutSession,
  InternalService,
  ListCheckoutSessionsRequestParams
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutSessionAppService {
  private _checkoutSessions$ = new BehaviorSubject<CheckoutSession[]>(null);
  private _checkoutSessionsCount$ = new BehaviorSubject<number>(null);
  private _checkoutSession$ = new BehaviorSubject<InternalCheckoutSession>(null);

  public checkoutSessions$ = this._checkoutSessions$.asObservable();
  public checkoutSessionsCount$ = this._checkoutSessionsCount$.asObservable();
  public checkoutSession$ = this._checkoutSession$.asObservable();

  constructor(
    private _checkoutSessionService: CheckoutSessionsService,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {}
  
  public createCheckoutSession(params: CheckoutSessionCreateParams, accountId: string): Observable<CheckoutSession> {
    const requestParams: CreateCheckoutSessionRequestParams = {
      tilledAccount: accountId,
      checkoutSessionCreateParams: params,
    };
    return this._checkoutSessionService.createCheckoutSession(requestParams);
  }

  public getCheckoutSession(checkoutSessionId: string, accountId: string, apiKey: string): void {
    this._internalService.configuration.credentials = {'TilledApiKey': apiKey};
    
    const requestParams: GetInternalCheckoutSessionRequestParams = {
      tilledAccount: accountId,
      checkoutSessionId: checkoutSessionId,
    };

    this._internalService.getInternalCheckoutSession(requestParams, null, null, ).subscribe({
      next: (checkoutSession) => {
        this._checkoutSession$.next(checkoutSession);
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not load checkout session',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw 'Error loading checkout session ' + JSON.stringify(err);
      },
    });
  }

  public confirmCheckoutSession(
    params: CheckoutSessionConfirmParams, 
    accountId: string,
    checkoutSessionId: string,
    apiKey: string
  ): Observable<InternalCheckoutSession> {
    this._checkoutSessionService.configuration.credentials = {'TilledApiKey': apiKey};

    const requestParams: ConfirmCheckoutSessionRequestParams = {
      tilledAccount: accountId,
      checkoutSessionConfirmParams: params,
      checkoutSessionId: checkoutSessionId,
    };
    return this._internalService.confirmCheckoutSession(requestParams);
  }

  public getAllCheckoutSessions(params: ListCheckoutSessionsRequestParams): void {
    this._checkoutSessionService.listCheckoutSessions(params)
      .pipe(
        tap((result) => this._checkoutSessionsCount$.next(result.total)),
        map((result) => result.items),
      )
      .subscribe({
        next: (checkoutSessions) => {
          this._checkoutSessions$.next(checkoutSessions);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load checkout sessions',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      });
  }
}
