import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GetTeamSupportUrlRequestParams } from '../../../../../projects/tilled-api-client/src';
import { TeamSupportAppService } from '../../../core/services/team-support.app.service';

@Component({
  selector: 'help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'helpCenter',
})
export class HelpCenterComponent implements OnInit {
  @Input() email: string;
  @Input() accountId: string;
  @Input() tilledSupport: boolean = false;
  opened: boolean = false;
  teamSupportUrl: string;
  teamSupportUrlExpiration: Date;
  baseTeamSupportUrl: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
  private _overlay: HTMLElement;

  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    private _teamSupportService: TeamSupportAppService,
  ) {}

  ngOnInit(): void {
    this._teamSupportService.getTeamSupportUrl$.pipe(takeUntil(this._unsubscribeAll)).subscribe((resp) => {
      if (!resp) {
        return;
      }
      this.teamSupportUrl = resp.url;
      this.teamSupportUrlExpiration = resp.expiration as Date;
      if (this.teamSupportUrl) {
        this.baseTeamSupportUrl = this.teamSupportUrl.split('sso')[0];
      }
    });
    this.getUrl();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Decorated methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'help-center-opened': this.opened,
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the panel
   */
  open(): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }
    this.getUrl();

    // Open the panel
    this._toggleOpened(true);
  }

  /**
   * Close the panel
   */
  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }

    // Close the panel
    this._toggleOpened(false);
  }

  /**
   * Toggle the panel
   */
  toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Generate a team support url
   */
  getUrl(): void {
    const urlReq: GetTeamSupportUrlRequestParams = {
      tilledAccount: this.accountId,
      tSUrlParams: {
        email: this.email,
      },
    };
    this._teamSupportService.getTeamSupportUrl(urlReq);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the backdrop
   *
   * @private
   */
  private _showOverlay(): void {
    // Try hiding the overlay in case there is one already opened
    this._hideOverlay();

    // Create the backdrop element
    this._overlay = this._renderer2.createElement('div');

    // Return if overlay couldn't be created for some reason
    if (!this._overlay) {
      return;
    }

    // Add a class to the backdrop element
    this._overlay.classList.add('help-center-overlay');

    // Append the backdrop to the parent of the panel
    this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

    // Enable block scroll strategy
    this._scrollStrategy.enable();

    // Add an event listener to the overlay
    this._overlay.addEventListener('click', () => {
      this.close();
    });
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _hideOverlay(): void {
    if (!this._overlay) {
      return;
    }

    // If the backdrop still exists...
    if (this._overlay) {
      // Remove the backdrop
      this._overlay.parentNode.removeChild(this._overlay);
      this._overlay = null;
    }

    // Disable block scroll strategy
    this._scrollStrategy.disable();
  }

  /**
   * Open/close the panel
   *
   * @param open
   * @private
   */
  private _toggleOpened(open: boolean): void {
    // Set the opened
    this.opened = open;

    // If the panel opens, show the overlay
    if (open) {
      this._showOverlay();
    }
    // Otherwise, hide the overlay
    else {
      this._hideOverlay();
    }
  }
}
