<div
    class="flex-col flex items-center cursor-pointer"
    (click)="onButtonClick()"
>
    <div
        class="z-20 flex flex-0 items-center justify-center w-9 h-9 rounded-full ring-2 ring-inset ring-transparent"
        [ngClass]="{
            'bg-primary': appStep.complete,
            'bg-gray-600': !appStep.complete
        }"
    >
        <ng-container>
            <mat-icon
                class="m-0"
                [ngClass]="{
                    'text-white': appStep.complete,
                    'text-zinc-400': !appStep.complete
                }"
                svgIcon="mat_outline:{{ appStep.icon }}"
            ></mat-icon>
        </ng-container>
    </div>
    <fuse-card
        class="z-10 -mt-4 w-36 h-30 flex-shrink px-5 text-white items-center text-center outline outline-1 outline-white rounded-md hover:outline-primary hover:bg-opacity-0 bg-white bg-opacity-10"
    >
        <span>{{ appStep.title }}</span>
    </fuse-card>
</div>
