import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { ReportRunStatusPipe } from 'app/core/pipes/downloads/download-status.pipe';
import { AccountAppService } from 'app/core/services/account.app.service';
import { ReportRunsAppService } from 'app/core/services/report-runs.app.service';
import { interval, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FilesService, ReportRun } from '../../../../projects/tilled-api-client/src';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';

@Component({
  selector: 'app-report-run-list',
  templateUrl: './report-run-list.component.html',
  styleUrls: ['./report-run-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportRunListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() accountId: string;
  @Input() type: ReportRun.TypeEnum;
  @Input() isMerchant: boolean;

  readonly paymentsSummary1: ReportRun.TypeEnum = ReportRun.TypeEnum.PAYMENTS_SUMMARY_1;
  readonly payoutsSummary2: ReportRun.TypeEnum = ReportRun.TypeEnum.PAYOUTS_SUMMARY_2;
  readonly feesSummary1: ReportRun.TypeEnum = ReportRun.TypeEnum.FEES_SUMMARY_1;
  readonly processingSummary1: ReportRun.TypeEnum = ReportRun.TypeEnum.PROCESSING_SUMMARY_1;

  public pageIndex = 0;
  public pageSize = 5;
  public accountNameMap = new Map();
  public reportRuns$: Observable<ReportRun[]>;
  public reportRunsCount$: Observable<number>;
  public viewModels$: Observable<ReportRunViewModel[]>;
  public timeInterval: Subscription;
  public hideColumns: number[] = [];

  constructor(
    private _accountAppService: AccountAppService,
    private _filesService: FilesService,
    private _reportRunAppService: ReportRunsAppService,
    private _reportRunStatusPipe: ReportRunStatusPipe,
    private _dateFormatPipe: DateFormatPipe,
  ) {}

  ngOnInit(): void {
    if (this.type === ReportRun.TypeEnum.PAYMENTS_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.paymentReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.paymentReportRuns$;
    } else if (this.type === ReportRun.TypeEnum.PAYOUTS_SUMMARY_2) {
      this.reportRunsCount$ = this._reportRunAppService.payoutReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.payoutReportRuns$;
    } else if (this.type === ReportRun.TypeEnum.FEES_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.feeReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.feeReportRuns$;
    } else if (this.type === ReportRun.TypeEnum.PROCESSING_SUMMARY_1) {
      this.reportRunsCount$ = this._reportRunAppService.processingReportRunsCount$;
      this.reportRuns$ = this._reportRunAppService.processingReportRuns$;
    }

    this._accountAppService.connectedAccounts$.subscribe((accounts) => {
      for (const account of accounts) {
        this.accountNameMap.set(account.id, account.name);
      }
      this.viewModels$ = this.reportRuns$.pipe(map((reportRuns) => this.getViewModelsFromReportRuns(reportRuns)));
    });

    if (this.type === ReportRun.TypeEnum.PAYMENTS_SUMMARY_1) {
      this.getPaymentsReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === ReportRun.TypeEnum.PAYOUTS_SUMMARY_2) {
      this.getPayoutsReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === ReportRun.TypeEnum.FEES_SUMMARY_1) {
      this.getFeesReportRuns(this.pageSize, this.pageIndex);
    } else if (this.type === ReportRun.TypeEnum.PROCESSING_SUMMARY_1) {
      this.getProcessingReportRuns(this.pageSize, this.pageIndex);
    }

    if (this.isMerchant) {
      this.hideColumns.push(2);
    }
  }

  ngOnDestroy(): void {
    this.timeInterval.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.timeInterval = interval(10000)
      .pipe(startWith(0))
      .subscribe((res) => {
        if (this._reportRunAppService.paymentReportRunHasPending) {
          this.getPaymentsReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.payoutReportRunHasPending) {
          this.getPayoutsReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.feeReportRunHasPending) {
          this.getFeesReportRuns(this.pageSize, this.pageIndex);
        } else if (this._reportRunAppService.processingReportRunHasPending) {
          this.getProcessingReportRuns(this.pageSize, this.pageIndex);
        }
      });
  }

  getViewModelsFromReportRuns(reportRuns: ReportRun[]): ReportRunViewModel[] {
    const viewModels: ReportRunViewModel[] = [];
    if (!reportRuns || reportRuns.length === 0) {
      const temp: ReportRunViewModel = new ReportRunViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const reportRun of reportRuns) {
      const localStartAt = new Date(reportRun.parameters.start_at);
      const localEndAt = new Date(reportRun.parameters.end_at);
      const localRangeString =
        localStartAt.toLocaleDateString('en-us') + ' - ' + localEndAt.toLocaleDateString('en-us');

      const reportName = reportRun.result?.filename ? (reportRun.result.filename as string).split('/')[1] : '';
      const temp: ReportRunViewModel = new ReportRunViewModel();
      temp.dates = localRangeString;
      temp.file_name = reportName;
      temp.merchant_name = this.accountNameMap.get(
        reportRun.parameters.account_id ? reportRun.parameters.account_id : reportRun.account_id,
      );
      temp.created_at = this._dateFormatPipe.transform(reportRun.created_at);
      temp.file_id = reportRun.result?.id ? reportRun.result.id : '';
      temp.account_id = this.accountId;
      temp.status = reportRun.status;
      temp.chipConfig = this._reportRunStatusPipe.transform(reportRun, false);
      viewModels.push(temp);
    }
    return viewModels;
  }

  getPaymentsReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getPaymentReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getPayoutsReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getPayoutReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getFeesReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getFeeReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };

  getProcessingReportRuns = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    this._reportRunAppService.getProcessingReportRuns(this.accountId, this.pageSize * this.pageIndex, this.pageSize);
  };
}

export class ReportRunViewModel {
  @Column({
    order: 0,
    name: 'Download Date',
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Report Name',
  })
  file_name: string;

  @Column({
    order: 2,
    name: 'Merchant',
  })
  merchant_name: string;

  @Column({
    order: 3,
    name: 'Date Range',
  })
  dates: string;

  @Column({
    order: 4,
    name: 'Status',
    isChip: true,
  })
  status: string;

  @Column({
    order: 5,
    isDownload: true,
  })
  file_id: string;

  id: string;

  type: ReportRun.TypeEnum;

  account_id: string;

  // non display, needed if trying to display status chip
  chipConfig: TilledChipConfig;
}
