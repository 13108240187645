import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ComponentBase } from 'app/core/componentBase';
import { PricingTemplateAppService } from 'app/core/services/pricing-template.app.service';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { Account, Markup, PricingTemplate, PricingTemplateCard } from '../../../../../projects/tilled-api-client/src';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-add-connected-account',
  templateUrl: './connected-account-dialog.component.html',
  styleUrls: ['./connected-account-dialog.component.scss'],
})
export class ConnectedAccountDialogComponent extends ComponentBase implements OnInit {
  private accountId: Account;
  @Output() addMerchantData;
  public connectedAccountForm: FormGroup;
  public cardIsChecked: boolean = false;
  public debitIsChecked: boolean = false;
  public pricingTemplateSub: Subscription;
  public pricingTemplates$: Observable<PricingTemplate[]>;
  private pricingTemplates: PricingTemplate[];
  public cardPricingTemplates: PricingTemplate[];
  public debitPricingTemplates: PricingTemplate[];
  public isLoading: boolean;
  public selectedCardTemplate: CardPricingTemplateViewModel;
  public selectedDebitTemplate: DebitPricingTemplateViewModel;
  public isSandbox: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConnectedAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _pricingTemplateAppService: PricingTemplateAppService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.accountId = this._data?.accountId ?? null;
    this.isSandbox = !environment.production;

    this.connectedAccountForm = this._formBuilder.group({
      name: new FormControl({ value: null, disabled: false }, [Validators.required]),
      email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
      region: new FormControl(null, [Validators.required]),
      cardPricingTemplate: new FormControl(null, [
        //Validators.required,
      ]),
      debitPricingTemplate: new FormControl(null, [
        //Validators.required,
      ]),
    });

    this.pricingTemplateSub = this._pricingTemplateAppService
      .getPricingTemplates(this.accountId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.pricingTemplates = res;
        this.isLoading = false;
      });
  }

  cardToggled(event: MatSlideToggleChange): void {
    if (event.checked === true) {
      this.connectedAccountForm.get('cardPricingTemplate').setValidators([Validators.required]);
    } else {
      this.connectedAccountForm.get('cardPricingTemplate').clearValidators();
    }
    this.connectedAccountForm.get('cardPricingTemplate').updateValueAndValidity();
    this.connectedAccountForm.get('cardPricingTemplate').setValue(null);
    this.selectedCardTemplate = null;
  }

  debitToggled(event: MatSlideToggleChange): void {
    if (event.checked === true) {
      this.connectedAccountForm.get('debitPricingTemplate').setValidators([Validators.required]);
    } else {
      this.connectedAccountForm.get('debitPricingTemplate').clearValidators();
    }
    this.connectedAccountForm.get('debitPricingTemplate').updateValueAndValidity();
    this.connectedAccountForm.get('debitPricingTemplate').setValue(null);
    this.selectedDebitTemplate = null;
  }

  updateSelectedCard(event: MatSelectChange): void {
    const cardPricing = this.pricingTemplates.find((p) => p.id == event.value);
    if (cardPricing) {
      this.selectedCardTemplate = new CardPricingTemplateViewModel(cardPricing);
    }
  }

  updateSelectedDebit(event: MatSelectChange): void {
    const debitPricing = this.pricingTemplates.find((p) => p.id == event.value);
    if (debitPricing) {
      this.selectedDebitTemplate = new DebitPricingTemplateViewModel(debitPricing);
    }
  }

  updatePricingTemplates(event: MatSelectChange): void {
    const currFilter = event.value as PricingTemplate.CurrencyEnum;

    this.cardPricingTemplates = this.pricingTemplates.filter(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD && p.currency === currFilter,
    );

    this.debitPricingTemplates = this.pricingTemplates.filter(
      (p) =>
        (p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
          p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT) &&
        p.currency === currFilter,
    );

    this.connectedAccountForm.get('cardPricingTemplate').setValue(null);
    this.connectedAccountForm.get('debitPricingTemplate').setValue(null);
    this.selectedCardTemplate = null;
    this.selectedDebitTemplate = null;
  }

  public addMerchantClicked(): void {
    this.connectedAccountForm.markAllAsTouched();
    if (!this.connectedAccountForm.invalid) {
      if (
        !this.connectedAccountForm.get('cardPricingTemplate').value &&
        !this.connectedAccountForm.get('debitPricingTemplate').value
      ) {
        return;
      }
      this.dialogRef.close(this.connectedAccountForm);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

export class CardPricingTemplateViewModel {
  public name: string;
  public currency: PricingTemplate.CurrencyEnum;
  public fee_type: PricingTemplateCard.TransactionFeeTypeEnum;
  public visa_rate: number;
  public amex_rate: number;
  public transaction_fee: number;
  public chargeback_fee: number;
  public retrieval_fee: number;
  public reversal_fee: number;
  public account_monthly_fee: number;
  public account_monthly_minimum_fee: number;

  public constructor(t: PricingTemplate) {
    const visaRate = t.card.markups.find((m) => m.card_type === Markup.CardTypeEnum.VISA).rate;
    const amexRate = t.card.markups.find((m) => m.card_type === Markup.CardTypeEnum.AMEX).rate;
    this.name = t.name;
    this.currency = t.currency;
    this.fee_type = t.card.transaction_fee_type;
    this.visa_rate = visaRate ? visaRate / 100 : 0;
    this.amex_rate = amexRate ? amexRate / 100 : 0;
    this.transaction_fee = t.card.transaction_fee;
    this.chargeback_fee = t.card.chargeback_fee;
    this.retrieval_fee = t.card.retrieval_fee;
    this.reversal_fee = t.card.reversal_fee;
    this.account_monthly_fee = t.account_monthly_fee;
    this.account_monthly_minimum_fee = t.account_monthly_minimum_fee;
  }
}

export class DebitPricingTemplateViewModel {
  public name: string;
  public currency: PricingTemplate.CurrencyEnum;
  public fee_type: PricingTemplateCard.TransactionFeeTypeEnum;
  public transaction_fee: number;
  public return_fee: number;
  public account_monthly_fee: number;
  public account_monthly_minimum_fee: number;

  public constructor(t: PricingTemplate) {
    const debitDetails =
      t.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ? t.ach_debit : t.eft_debit;

    this.name = t.name;
    this.currency = t.currency;
    this.fee_type = debitDetails.transaction_fee_type;
    this.transaction_fee = debitDetails.transaction_fee;
    this.return_fee = debitDetails.return_fee;
    this.account_monthly_fee = t.account_monthly_fee;
    this.account_monthly_minimum_fee = t.account_monthly_minimum_fee;
  }
}
