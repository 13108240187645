<div class="sm:mx-5 p-6 flex flex-col w-full">
    <div class="grid grid-cols-1 justify-items-start">
        <tilled-heading-h3 *ngIf="stepNumber" class="">STEP # {{stepNumber}}</tilled-heading-h3>
        <tilled-heading-h1 *ngIf="title" [primaryHighlight]="true" class="py-2">{{title}}</tilled-heading-h1>
        <tilled-paragraph-p1 *ngIf="description" [secondary]="true">{{description}}</tilled-paragraph-p1>
        <div class="
            flex flex-col
            items-start
            w-full
            mt-7
            py-5
            px-8
            rounded-xl
            outline outline-1
            bg-neutral-50"
        >
            <ng-content></ng-content>
        </div>
        <div class="flex items-end justify-end w-full mt-4 space-x-4"
            [ngClass]="{'py-5': backButton || continueButton}">
            <div *ngIf="backButton">
                <tilled-button
                    data-test-id="merchant-app-backBtn"
                    bgColor="tilled-neutral-200"
                    [whiteText]="false"
                    [secondary]="true"
                    [rounded]="true"
                    buttonText="Back"
                    (buttonClick)="onButtonClick('back')"
                ></tilled-button>
            </div>
            <div *ngIf="continueButton">
                <tilled-button
                    data-test-id="merchant-app-saveBtn"
                    bgColor="primary"
                    [rounded]="true"
                    [disabled]="disableContinueButton"
                    [buttonText]="continueButtonText"
                    (buttonClick)="onButtonClick('continue')"
                ></tilled-button>
            </div>
    </div>
</div>
