import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseAlertService } from '@fuse/components/alert';
import { ComponentBase } from 'app/core/componentBase';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { RefundsAppService } from 'app/core/services/refunds.app.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Charge, CreateRefundRequestParams, PaymentIntent } from '../../../../../projects/tilled-api-client/src';

@Component({
  selector: 'app-refund-form-dialog',
  templateUrl: './refund-form-dialog.component.html',
  styleUrls: ['./refund-form-dialog.component.scss'],
})
export class RefundFormDialogComponent extends ComponentBase implements OnInit {
  private accountId: string;

  public refundForm: FormGroup;
  public maxRefund: number;
  public platformFeeRefund: boolean;
  public payment: PaymentIntent;
  public successfulCharge: Charge;
  public isMerchant: boolean;

  private _displayAlert$ = new Subject<boolean>();
  public displayAlert$ = this._displayAlert$.asObservable();
  public alertType: string;
  public alertTitle: string;
  public alertMessage: string;
  public alertDismissible: boolean;

  private refundError$: Observable<string>;
  private refundCreated$: Observable<boolean>;

  constructor(
    public matDialogRef: MatDialogRef<RefundFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _refundsAppService: RefundsAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _fuseAlertService: FuseAlertService,
  ) {
    super();
    // Determine if this is a merchant or partner logged in
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
    });

    this.accountId = this._data.accountId;
    this.payment = this._data.payment;
    this.successfulCharge = this.payment.charges.find((c) => c.status === 'succeeded');

    this.maxRefund = this.payment.amount - this.successfulCharge.amount_refunded;
    this.platformFeeRefund = false;

    this.refundError$ = this._refundsAppService.refundCreatedError$;
    this.refundError$.subscribe((message) => {
      this.alertType = 'warn';
      this.alertTitle = 'Refund Attempt Failed';
      this.alertMessage = message;
      this.alertDismissible = true;
      this._displayAlert$.next(true);
      this._fuseAlertService.show('refundDialogAlertBox');
    });

    this.refundCreated$ = this._refundsAppService.refundCreated$;
    this.refundCreated$.subscribe((success) => {
      if (success) {
        this.matDialogRef.close(this.refundForm);
      }
    });
  }

  ngOnInit(): void {
    this.refundForm = this._formBuilder.group({
      payment_intent_id: [this._data.id],
      amount: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.max(this.maxRefund / 100),
        Validators.min(0.01),
      ]),
      reason: new FormControl({ value: null, disabled: false }, []),
      refund_platform_fee: new FormControl({ value: this.platformFeeRefund, disabled: false }, []),
    });
  }

  public togglePlatformFeeRefund(): void {
    this.platformFeeRefund = !this.platformFeeRefund;
    this.alertType = 'warning';
    this.alertTitle = 'Platform fee is refunded to the customer';
    this.alertMessage = 'Because the customer incurred the platform fee, they will receive the platform fee refund.';
    this.alertDismissible = false;
    this._displayAlert$.next(this.platformFeeRefund);
  }

  public submitRefundClicked(): void {
    this.refundForm.get('refund_platform_fee').patchValue(this.platformFeeRefund);

    const refundDto = this.refundForm.getRawValue();
    refundDto.amount = this._numericToMinorPipe.transform(refundDto.amount);

    const refundRequestParams: CreateRefundRequestParams = {
      tilledAccount: this.accountId,
      refundCreateParams: refundDto,
    };
    this._refundsAppService.createRefund(refundRequestParams);
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }
}
