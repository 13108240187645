<div class="fixed top-0 bottom-0 h-full shadow font-sans">
    <div
        class="
            flex flex-col
            w-96
            h-full
            transition-transform
            duration-400
            ease-drawer
            bg-tilled-neutral-200
        "
        [ngClass]="{
            '-translate-x-full shadow': opened,
            'translate-x-0': !opened
        }"
    >
        <!-- Header -->
        <div
            class="
                h-16
                flex flex-0
                items-center
                bg-tilled-neutral-200
                drop-shadow
            "
            (click)="toggle()"
        >
            <!-- Toggle -->
            <ng-container *ngIf="opened">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            color="primary"
                            [svgIcon]="'heroicons_outline:question-mark-circle'"
                        ></mat-icon>
                    </div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <!-- Content -->
        <div
            class="
                flex flex-col
                m-auto
                items-center
                bg-tilled-neutral-200
                border-t
                p-4
                w-full
                h-full
                max-h-screen
                overflow-auto
            "
        >
            <div
                class="
                    justify-center
                    mt-4
                    mb-16
                    p-4
                    w-[280px]
                    h-[131px]
                    text-[30px]
                    font-extrabold
                    text-center
                    leading-10
                "
            >
                Need Support? We got you covered.
            </div>
            <div
                class="
                    flex flex-col flex-none
                    items-center
                    mb-20
                    w-[280px]
                    h-[210px]
                    p-6
                    bg-card
                    shadow
                    rounded-2xl
                "
            >
                <div
                    class="
                        relative
                        flex
                        items-center
                        justify-center
                        rounded-full
                        bg-tilled-neutral-100
                        w-[60px]
                        h-[60px]
                        -top-14
                    "
                >
                    <mat-icon
                        class="text-tilled-orange icon-size-24"
                        svgIcon="heroicons_solid:question-mark-circle"
                    ></mat-icon>
                </div>
                <div
                    class="relative text-[24px] leading-[30px] font-bold -top-7"
                >
                    View FAQ
                </div>
                <div class="mt-2 text-[15px] leading-[20px] font-semibold">
                    <a href="/faq">Frequently asked questions</a>
                </div>
            </div>
            <div
                *ngIf="teamSupportUrl"
                class="
                    flex flex-col
                    items-center
                    justify-center
                    mb-20
                    w-[280px]
                    h-[210px]
                    p-6
                    bg-card
                    shadow
                    rounded-2xl
                "
            >
                <div
                    class="
                        relative
                        flex
                        items-center
                        justify-center
                        rounded-full
                        bg-[#6C6DDE]
                        w-[75px]
                        h-[75px]
                        -top-14
                    "
                >
                    <mat-icon
                        class="white-icon enlarge"
                        svgIcon="heroicons_solid:ticket"
                    ></mat-icon>
                </div>
                <div
                    class="relative text-[24px] leading-[30px] font-bold -top-7"
                >
                    Submit a ticket
                </div>
                <div
                    class="
                        relative
                        mt-2
                        text-[15px]
                        leading-[20px]
                        font-semibold
                        -top-3.5
                    "
                >
                    <a
                        href="{{ teamSupportUrl }}?redirect={{
                            baseTeamSupportUrl
                        }}createticket"
                        target="_blank"
                        rel="noopener noreferrer"
                        >New ticket</a
                    >
                </div>
                <div class="mt-2 text-[15px] leading-[20px] font-semibold">
                    <a
                        href="{{ teamSupportUrl }}?redirect={{
                            baseTeamSupportUrl
                        }}mytickets"
                        target="_blank"
                        rel="noopener noreferrer"
                        >View all tickets</a
                    >
                </div>
            </div>
            <!--            <div-->
            <!--                class="flex flex-col flex-none items-center mb-20 w-[280px] h-[210px] p-6 bg-card shadow rounded-2xl">-->
            <!--                <div-->
            <!--                    class="relative flex items-center justify-center rounded-full bg-[#FFA000] w-[75px] h-[75px] -top-14">-->
            <!--                    <mat-icon class="white-icon enlarge" svgIcon="heroicons_solid:chat-alt-2"></mat-icon>-->
            <!--                </div>-->
            <!--                <div class="relative text-[24px] leading-[30px] font-bold -top-7">-->
            <!--                    Chat with us-->
            <!--                </div>-->
            <!--                <div class="mt-2 text-[15px] leading-[20px] font-semibold">-->
            <!--                    <a href="">Chat with a support agent.</a>-->
            <!--                </div>-->
            <!--            </div>-->

            <div
                *ngIf="tilledSupport"
                class="
                    flex flex-col flex-none
                    items-center
                    mb-20
                    w-[280px]
                    h-[210px]
                    p-6
                    bg-card
                    shadow
                    rounded-2xl
                "
            >
                <div
                    class="
                        relative
                        flex
                        items-center
                        justify-center
                        rounded-full
                        bg-[#EB5778]
                        w-[75px]
                        h-[75px]
                        -top-14
                    "
                >
                    <mat-icon
                        class="white-icon enlarge"
                        svgIcon="heroicons_solid:phone"
                    ></mat-icon>
                </div>
                <div
                    class="relative text-[24px] leading-[30px] font-bold -top-7"
                >
                    Call us
                </div>
                <div class="mt-2 text-[15px] leading-[20px] font-semibold">
                    <a href="tel:8772790010">877-279-0010</a>
                </div>
                <div class="mt-2 text-[15px] leading-[20px] font-semibold">
                    8am-5pm MT, Monday-Friday
                </div>
            </div>
        </div>
    </div>
</div>
