import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginDto } from '../../../../projects/tilled-api-client/src';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // We do not want to perform a refresh on these routes
  private readonly routesToIgnore = [
    'auth/login',
    'auth/refresh',
    'auth/logout',
    // TODO: fix isTokenExpired bug in auth service and can maybe remove this
    'auth-links',
  ];

  constructor(private _injector: Injector) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We only want to intercept requests to Tilled's API
    if (req.url.includes(environment.api)) {
      let tokenExpired = false;

      // FIXME: We get a circular dependency error the first time intercept() is called.
      try {
        const authService = this._injector.get(AuthService);
        tokenExpired = authService.isTokenExpired();
      } catch (error) {}

      // If the token is expired, we want to pre-emptively refresh it without waiting for a 401
      if (tokenExpired && this.routesToIgnore.every((route) => !req.url.includes(route))) {
        return this.refreshAccessTokenAndRetryRequest(req, next);
      } else {
        // We'll attempt the request and IF it fails with a 401
        // then we will attempt to refresh the access token and retry
        return next.handle(req).pipe(
          catchError((error) => {
            // TODO: Catch only "expired" token attempt (message = 'jwt expired', perhaps).
            // Right now, *any* 401 error will assume that a refresh is needed.
            // We know for sure that a 'This request requires the following scope(s):' is an example
            // of a 401 error that is not due to expired tokens so we'll exclude that
            if (
              error instanceof HttpErrorResponse &&
              error.status === 401 &&
              this.routesToIgnore.every((route) => !req.url.includes(route)) &&
              !error.error?.message?.includes('scope')
            ) {
              return this.refreshAccessTokenAndRetryRequest(req, next);
            }
            return throwError(error);
          }),
        );
      }
    } else {
      return next.handle(req);
    }
  }

  private refreshAccessTokenAndRetryRequest(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);
      const refreshToken = AuthService.getRefreshToken();
      const authService = this._injector.get(AuthService);
      if (refreshToken) {
        return authService.refreshAccessToken().pipe(
          switchMap((response: LoginDto) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(response.token);

            return next.handle(this.addAuthorizationHeader(request, response.token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            authService.logout(true);
            return throwError(err);
          }),
        );
      } else {
        // If we try to refresh without a refresh token, we need to go ahead and logout
        authService.logout(true);
      }
    } else {
      // If we are actively refreshing, we will wait until refreshTokenSubject has a non-null value
      // When the new token is ready and we can retry the request again
      // This is what ensures that when *multiple* requests fail with a 401,
      // the *subsequent* requests get retried. The first failed request is retried above.
      return this.refreshTokenSubject.pipe(
        filter((accessToken) => accessToken !== null),
        take(1),
        switchMap((accessToken) => next.handle(this.addAuthorizationHeader(request, accessToken))),
      );
    }
  }

  private addAuthorizationHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + accessToken),
    });
  }
}
