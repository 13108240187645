<div>
    <div>
        <div class="pl-3 pb-4">
            <tilled-label-l1 secondary="true">{{ label }}</tilled-label-l1>
        </div>
        <div class="flex items-center justify-center rounded-2xl bg-tilled-neutral-300 h-[33px] w-[198px] pl-2.5">
            <button
                class="rounded-2xl transition-all duration-400 h-[90%] w-[102px] px-4 -ml-2"
                [ngClass]="leftSelect ? 'bg-white' : ''"
                (click)="onToggle()"
            >
                <div>
                    <tilled-paragraph-p3> {{ textA }}</tilled-paragraph-p3>
                </div>
            </button>
            <button
                class="rounded-2xl transition-all duration-400 h-[90%] w-[102px] px-4 -ml-1.5 mr-0.5"
                [ngClass]="!leftSelect ? 'bg-white' : ''"
                (click)="onToggle()"
            >
                <div>
                    <tilled-paragraph-p3> {{ textB }}</tilled-paragraph-p3>
                </div>
            </button>
        </div>
    </div>
</div>
