import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { AccountCapability } from '../../../../../projects/tilled-api-client/src';

/**
 * Takes a AccountCapability StatusEnum
 */
@Pipe({
  name: 'accountStatus',
})
export class AccountStatusPipe implements PipeTransform {
  /**
   *
   * @param status AccountCapability StatusEnum
   * @returns
   */
  transform(status: AccountCapability.StatusEnum): TilledChipConfig {
    let chipConfig: TilledChipConfig = {
      color: ChipColorClass.SOLID_ORANGE,
      text: status,
      toolTip: '',
    };
    if (status === AccountCapability.StatusEnum.ACTIVE) {
      chipConfig.color = ChipColorClass.SOLID_GREEN;
      chipConfig.text = 'ACTIVE';
    } else if (status === AccountCapability.StatusEnum.CREATED) {
      chipConfig.color = ChipColorClass.SOLID_ACCENT;
      chipConfig.text = 'CREATED';
    } else if (status === AccountCapability.StatusEnum.DISABLED) {
      chipConfig.color = ChipColorClass.SOLID_ORANGE;
      chipConfig.text = 'DISABLED';
    } else if (status === AccountCapability.StatusEnum.IN_REVIEW) {
      chipConfig.color = ChipColorClass.SOLID_ORANGE;
      chipConfig.text = 'IN REVIEW';
    } else if (status === AccountCapability.StatusEnum.REJECTED) {
      chipConfig.color = ChipColorClass.SOLID_RED;
      chipConfig.text = 'REJECTED';
    } else if (status === AccountCapability.StatusEnum.STARTED) {
      chipConfig.color = ChipColorClass.SOLID_ACCENT;
      chipConfig.text = 'STARTED';
    } else if (status === AccountCapability.StatusEnum.SUBMITTED) {
      chipConfig.color = ChipColorClass.SOLID_BLUE;
      chipConfig.text = 'SUBMITTED';
    } else if (status === AccountCapability.StatusEnum.WITHDRAWN) {
      chipConfig.color = ChipColorClass.SOLID_ACCENT;
      chipConfig.text = 'WITHDRAWN';
    }

    return chipConfig;
  }
}
