import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { APIKeysService } from './api/aPIKeys.service';
import { AccountsService } from './api/accounts.service';
import { ApplePayDomainsService } from './api/applePayDomains.service';
import { AuthLinksService } from './api/authLinks.service';
import { BalanceTransactionsService } from './api/balanceTransactions.service';
import { ChargesService } from './api/charges.service';
import { CheckoutSessionsService } from './api/checkoutSessions.service';
import { CustomersService } from './api/customers.service';
import { DisputesService } from './api/disputes.service';
import { EventsService } from './api/events.service';
import { FilesService } from './api/files.service';
import { HealthService } from './api/health.service';
import { InternalService } from './api/internal.service';
import { OnboardingService } from './api/onboarding.service';
import { PartnerApplicationsService } from './api/partnerApplications.service';
import { PaymentIntentsService } from './api/paymentIntents.service';
import { PaymentMethodsService } from './api/paymentMethods.service';
import { PayoutsService } from './api/payouts.service';
import { PlatformFeeRefundsService } from './api/platformFeeRefunds.service';
import { PlatformFeesService } from './api/platformFees.service';
import { PricingTemplatesService } from './api/pricingTemplates.service';
import { ProductCodesService } from './api/productCodes.service';
import { RefundsService } from './api/refunds.service';
import { ReportRunsService } from './api/reportRuns.service';
import { SubscriptionsService } from './api/subscriptions.service';
import { TeamSupportService } from './api/teamSupport.service';
import { UsersService } from './api/users.service';
import { WebhookEndpointsService } from './api/webhookEndpoints.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
