/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PricingTemplateCreateParamsDebit } from './pricingTemplateCreateParamsDebit';
import { PricingTemplateCreateParamsCard } from './pricingTemplateCreateParamsCard';


export interface PricingTemplateCreateParams { 
    /**
     * The id of the product code to be associated with this pricing template, if any.
     */
    product_code_id?: string;
    /**
     * The payment method type this pricing template applies to.
     */
    payment_method_type: PricingTemplateCreateParams.PaymentMethodTypeEnum;
    card?: PricingTemplateCreateParamsCard;
    debit?: PricingTemplateCreateParamsDebit;
    /**
     * The name of this pricing template.
     */
    name: string;
    /**
     * The currency this pricing template applies to.
     */
    currency: PricingTemplateCreateParams.CurrencyEnum;
    /**
     * The account monthly fee configured for this pricing template, in currency minor units.
     */
    account_monthly_fee: number;
    /**
     * The account monthly minimum fee configured for this pricing template, in currency minor units.
     */
    account_monthly_minimum_fee: number;
}
export namespace PricingTemplateCreateParams {
    export type PaymentMethodTypeEnum = 'card' | 'ach_debit' | 'eft_debit';
    export const PaymentMethodTypeEnum = {
        CARD: 'card' as PaymentMethodTypeEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypeEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypeEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
}


