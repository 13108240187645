import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, shareReplay, Subject, tap } from 'rxjs';
import {
  CreatePaymentIntentRequestParams,
  GetPaymentIntentRequestParams,
  ListPaymentIntentsRequestParams,
  PaymentIntent,
  PaymentIntentsService,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
@Injectable({
  providedIn: 'root',
})
export class PaymentAppService {
  constructor(
    private _paymentIntentsService: PaymentIntentsService,
    private _alertService: AlertService,
  ) {}

  private _payments$ = new ReplaySubject<PaymentIntent[]>();
  private _paymentsCount$ = new ReplaySubject<number>();
  private _payment$ = new Subject<PaymentIntent>();

  public payments$: Observable<PaymentIntent[]> = this._payments$.asObservable();
  public paymentsCount$: Observable<number> = this._paymentsCount$.asObservable();
  public payment$: Observable<PaymentIntent> = this._payment$.asObservable();
  
  public getAllPaymentIntents(params: ListPaymentIntentsRequestParams): void {
    this._paymentIntentsService
      .listPaymentIntents(params)
      .pipe(
        tap((result) => this._paymentsCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (payments) => {
          this._payments$.next(payments);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load all payments',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  public getPaymentIntent(params: GetPaymentIntentRequestParams): void {
    this._paymentIntentsService
      .getPaymentIntent(params)

      .subscribe({
        next: (payment) => {
          this._payment$.next(payment);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load payment \'' + params.id + '\'',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  // error handling/alert is handled in the component
  public createPaymentIntent(
    params: CreatePaymentIntentRequestParams,
    parentAccountId?: string,
  ): Observable<PaymentIntent> {
    return this._paymentIntentsService.createPaymentIntent(params);
  }
}
