<div class="flex flex-col flex-auto min-w-100">
    <div class="flex justify-end pt-2 pr-2">
        <mat-icon
            style="cursor: pointer"
            (click)="closeDialog(true)"
            svgIcon="mat_outline:close"
        ></mat-icon>
    </div>
    <div class="w-full px-4 pb-2">
        <div class="flex flex-col w-full items-center px-8 mb-4">
            <!-- Title -->
            <tilled-heading-h2 class="pb-6">{{title}}</tilled-heading-h2>
            <!-- Content -->
            <tilled-paragraph-p3
            >{{text}}
            </tilled-paragraph-p3
            >
        </div>

        <!-- Add Actions -->
        <div class="flex w-full justify-end pt-8 px-8 space-x-4">
            <tilled-button
                data-test-id="leave-button"
                buttonText={{leaveButtonText}}
                bgColor="warn"
                whiteText="true"
                (buttonClick)="closeDialog(false)"
            ></tilled-button>
            <tilled-button
                data-test-id="stay-button"
                buttonText={{stayButtonText}}
                bgColor="primary"
                (buttonClick)="closeDialog(true)"
            ></tilled-button>
        </div>
    </div>
</div>
