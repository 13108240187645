import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';

@Component({
  selector: 'merchant-app-card',
  templateUrl: './merchant-app-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MerchantAppCardComponent extends ComponentBase implements OnInit {
  @Output() backClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() continueClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() backButton?: boolean = true;
  @Input() continueButton?: boolean = true;
  @Input() continueButtonText?: string = "Save and Continue";
  @Input() disableContinueButton?: boolean = false;
  
  @Input() stepNumber?: string;
  @Input() title?: string;
  @Input() description?: string;

  constructor() {
    super();
  }

  ngOnInit() {}

  onButtonClick(text) {
    switch (text) {
      case 'back':
        this.backClick.emit();
        break;
      case 'continue':
        this.continueClick.emit();
        break;
    }
  }
  
}