import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  GetTeamSupportUrlRequestParams,
  TeamSupportService,
  TSUrlResponse,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class TeamSupportAppService {
  private url$ = new BehaviorSubject<TSUrlResponse>(null);

  constructor(
    private _teamSupportService: TeamSupportService,
    private _alertService: AlertService,
  ) {}

  // TODO: Move these gets to public observables public observable$ = subject.asObservable();
  get getTeamSupportUrl$(): Observable<TSUrlResponse> {
    return this.url$.asObservable();
  }

  public getTeamSupportUrl(params: GetTeamSupportUrlRequestParams): void {
    this._teamSupportService
      .getTeamSupportUrl(params)
      .pipe((resp) => {
        return resp;
      })
      .subscribe({
        next: (url) => this.url$.next(url),
        error: (err) => {
          // generic catch all for error responses
          /*const message: TilledAlert = {
            message: 'Could not load support URL',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);*/
        },
      });
  }
}
