/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notEqualToList(checkValues: any[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    for (const checkValue of checkValues) {
      if (value === checkValue) {
        return { isEqualToList: true };
      }
    }
    
    return null;
  };
}
