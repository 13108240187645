<fuse-alert
    class="pt-4"
    [type]="'warn'"
    [appearance]="'outline'"
    [dismissible]="false"
    [dismissed]="false"
    [name]="alertName">
    <mat-icon class="icon-size-5"
        fuseAlertIcon
        [svgIcon]="'heroicons_solid:exclamation'">
    </mat-icon>
    <tilled-heading-h6 fuseAlertTitle>{{title}}</tilled-heading-h6>
    <div class="flex sm:flex-row flex-col">
        <tilled-paragraph-p3>{{description}}</tilled-paragraph-p3>
        <tilled-button
            class="sm:pl-4 sm:-mt-4 mt-4"
            [whiteText]="false"
            textColor="tilled-secondary"
            bgColor="tilled-neutral-300"
            bgOpacity="50"
            outlineColor="tilled-neutral-300"
            [rounded]="true"
            buttonText="Edit"
            (buttonClick)="onButtonClick('edit')"
            >
            <mat-icon
                class="pl-1 icon-size-5"
                [ngStyle]="{'color':'secondary'}"
                [svgIcon]="'heroicons_solid:pencil'">
            </mat-icon>
        </tilled-button>
    </div> 
</fuse-alert>