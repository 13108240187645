<!-- we could probably do something clever-er here with isLoading -->
<div [hidden]="isLoading">
  <div
    [hidden]="dataLength === 0"
    [class]="scrollable && displayedColumns?.length > 6 && !viewportMaxSize ? 'overflow-x-scroll max-w-[70vw]' : ''"
  >
    <mat-table
      [dataSource]="data"
      matSort
      (matSortChange)="sortData($event)"
      [class]="scrollable && displayedColumns?.length > 6 && !viewportMaxSize ? 'min-w-[100vw]' : ''"
    >
      <ng-container [matColumnDef]="column.key" *ngFor="let column of columns">
        <ng-container *ngIf="column.canSort; else noSort">
          <mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.key }}" [style]="column.styling ?? null">
            {{ column.name.toUpperCase() }}
            <span
              *ngIf="column.headerTooltip"
              class="mt-[-2px] flex flex-col justify-center"
              [ngxTippy]="headerTooltipTemplate"
              tippyClassName="bg-tilled-primary"
              [tippyProps]="{
                placement: 'right',
                arrow: false,
                interactive: true
              }"
            >
              <mat-icon
                style="width: 4px; height: 4px; font-size: 4px; transform: scale(0.7)"
                svgIcon="heroicons_solid:question-mark-circle"
              >
              </mat-icon>
            </span>
          </mat-header-cell>
        </ng-container>
        <ng-template #noSort>
          <mat-header-cell *matHeaderCellDef [style]="column.styling ?? null">
            {{ column.name ? column.name.toUpperCase() : '' }}
            <span
              *ngIf="column.headerTooltip"
              class="mt-[-2px] flex flex-col justify-center"
              [ngxTippy]="headerTooltipTemplate"
              tippyClassName="bg-tilled-primary"
              [tippyProps]="{
                placement: 'right',
                arrow: false,
                interactive: true
              }"
            >
              <mat-icon
                style="width: 4px; height: 4px; font-size: 4px; transform: scale(0.7)"
                svgIcon="heroicons_solid:question-mark-circle"
              >
              </mat-icon>
            </span>
          </mat-header-cell>
        </ng-template>

        <ng-template #headerTooltipTemplate>
          <tilled-paragraph-p4 [neutral]="true" class="text-tilled-neutral-100 font-normal">
            <markdown [data]="column.headerTooltip"></markdown>
          </tilled-paragraph-p4>
        </ng-template>

        <ng-container *ngIf="column.isProgress; else second">
          <mat-cell *matCellDef="let element" [style]="column.styling ?? null">
            <tilled-progress-bar
              [color]="element[column.key] === 100 ? 'green_vertical' : 'red_vertical'"
              [value]="element[column.key]"
            ></tilled-progress-bar>
            {{ element[column.key] }}%
          </mat-cell>
        </ng-container>
        <ng-template #second>
          <ng-container *ngIf="column.isShareLink; else third">
            <mat-cell *matCellDef="let element" class="grid pr-8 justify-items-end" [style]="column.styling ?? null">
              <ng-container *ngIf="element['accountIdForInvitation']">
                <button
                  mat-icon-button
                  (click)="inviteNewMerchantUser(element['accountIdForInvitation'], element['name'])"
                >
                  <mat-icon class="text-primary">share</mat-icon>
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>
        </ng-template>

        <ng-template #third>
          <ng-container *ngIf="column.isChip; else forth">
            <mat-cell *matCellDef="let element" [style]="column.styling ?? null">
              <tilled-chip [chipConfig]="element['chipConfig']"></tilled-chip>
            </mat-cell>
          </ng-container>
        </ng-template>
        <ng-template #forth>
          <ng-container *ngIf="column.isAction; else fifth">
            <mat-cell
              *matCellDef="let element"
              [style]="column.styling ?? null"
              (mouseover)="actionHover = true"
              (mouseout)="actionHover = false"
            >
              <div class="absolute right-0 pr-8">
                <button mat-icon-button (click)="actionClicked(element)">
                  <mat-icon color="primary">chevron_right</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>
        </ng-template>

        <ng-template #fifth>
          <ng-container *ngIf="column.isMultiline; else sixth">
            <mat-cell *matCellDef="let element">
              <div class="flex flex-col items-left">
                <tilled-paragraph-p3 *ngIf="element[column.key] && element[column.key][0]" class="font-medium">{{
                  element[column.key][0]
                }}</tilled-paragraph-p3>
                <tilled-paragraph-p3 *ngIf="element[column.key] && element[column.key][1]" class="">{{
                  element[column.key][1]
                }}</tilled-paragraph-p3>
              </div>
            </mat-cell>
          </ng-container>
        </ng-template>
        <ng-template #sixth>
          <ng-container *ngIf="column.isActionList; else seventh">
            <mat-cell *matCellDef="let element">
              <div class="absolute right-0 pr-8 bg-tilled-neutral-100">
                <action-list-component [actionList]="element[column.key]"></action-list-component>
              </div>
            </mat-cell>
          </ng-container>
        </ng-template>
        <ng-template #seventh>
          <ng-container *ngIf="column.isCurrency; else eighth">
            <mat-cell *matCellDef="let element" [style]="column.styling ?? null">{{
              element[column.key] | minorUnitsToCurrency : element['currency']
            }}</mat-cell>
          </ng-container>
        </ng-template>

        <ng-template #eighth>
          <ng-container *ngIf="column.isDownload; else tooltipCell">
            <mat-cell *matCellDef="let element" class="grid justify-items-end" [style]="column.styling ?? null">
              <ng-container *ngIf="element[column.key]">
                <button mat-icon-button (click)="downloadFileClicked(element['file_id'], element['file_name'])">
                  <mat-icon color="primary" [svgIcon]="'heroicons_outline:download'"></mat-icon>
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>
        </ng-template>

        <ng-template #tooltipCell>
          <ng-container *ngIf="column.cellTooltip; else normalCell">
            <mat-cell
              *matCellDef="let element"
              [style]="column.styling ?? null"
              [matTooltip]="column.showCellTooltipIcon ? null : column.cellTooltip(element[column.key])"
              matTooltipClass="mat-tooltip-linebreak"
              matTooltipPosition="right"
              >{{ element[column.key] }}

              <mat-icon
                *ngIf="column.showCellTooltipIcon && column.cellTooltip(element[column.key])"
                class="icon-size-4"
                style="vertical-align: -2px"
                [svgIcon]="'heroicons_solid:information-circle'"
                [matTooltip]="column.cellTooltip(element[column.key])"
                matTooltipClass="mat-tooltip-linebreak"
              ></mat-icon>
            </mat-cell> </ng-container
        ></ng-template>
        <ng-template #normalCell>
          <ng-container *ngIf="column.propertyType === 'Number'; else leftAlign">
            <mat-cell *matCellDef="let element" [style]="column.styling ?? null">{{ element[column.key] }}</mat-cell>
          </ng-container>
          <ng-template #leftAlign>
            <mat-cell *matCellDef="let element" [style]="column.styling ?? null">{{ element[column.key] }}</mat-cell>
          </ng-template>
        </ng-template>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <ng-container *ngIf="getClickCallback; else noClick">
        <mat-row
          class="cursor-pointer"
          *matRowDef="let row; columns: displayedColumns"
          (click)="rowClicked(row, $event)"
        ></mat-row>
      </ng-container>
      <ng-template #noClick>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </ng-template>
      <div class="w-full flex justify-center content-center" *matNoDataRow>
        <div class="flex flex-col items-center">
          <tilled-heading-h2 *ngIf="dataLength === 0" class="mt-8 mb-4">{{ noDataMainText }}</tilled-heading-h2>
          <tilled-paragraph-p1 *ngIf="dataLength === 0" class="mb-8">{{ noDataSecondaryText }}</tilled-paragraph-p1>
        </div>
      </div>
    </mat-table>
  </div>
  <div class="flex justify-between">
    <div [hidden]="hidePaginator || dataLength < pageSize">
      <mat-paginator
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        showFirstLastButtons
        [length]="dataLength"
      >
      </mat-paginator>
    </div>
    <div class="flex justify-around min-w-[40%]" *ngIf="showDisplayedColumnsDropdown">
      <div class="flex flex-col justify-center">
        <tilled-paragraph-p4 secondary="true">Displayed Columns: </tilled-paragraph-p4>
      </div>
      <div class="flex flex-col justify-center pt-3 min-w-[60%]">
        <mat-form-field appearence="fill">
          <mat-select
            placeholder="Displayed Columns"
            multiple
            (selectionChange)="onColumnSelectionChange($event)"
            [value]="displayedColumns"
          >
            <ng-container *ngFor="let column of columns">
              <mat-option *ngIf="column.name" [value]="column.key">{{ column.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div [hidden]="hideZeroState || dataLength > 0">
    <div class="tracking-tight text-center my-[10%]">
      <div class="mt-4 mx-4 text-2xl font-bold">
        {{ noDataMainText }}
      </div>
      <div class="mb-4 mx-4 mt-2">{{ noDataSecondaryText }}</div>
    </div>
  </div>
</div>
