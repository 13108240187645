import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DeleteUserRequestParams,
  ListAllUsersRequestParams,
  UpdateUserRequestParams,
  User,
} from '../../../../projects/tilled-api-client/src';
import { UserRoles } from '../../core/data/user-roles';
import { ActionListItem } from '../action-list/action-list.model';
import { Column } from '../tilled-table/decorators/column';

import { UserInviteDialogComponent } from './user-dialog/user-invite-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accountId: string;

  public users$: Observable<User[]>;
  public usersViewModel$: Observable<UserViewModel[]>;
  public usersCount$: Observable<number>;

  public isLoading = true;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public sortInfo = null;

  public dialogRef: any;

  constructor(
    private _usersAppService: UsersAppService,
    private _matDialog: MatDialog,
    private _dateFormatPipe: DateFormatPipe,
    private _authService: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.users$ = this._usersAppService.users$;
    this.usersCount$ = this._usersAppService.usersCount$;

    this.usersViewModel$ = this.users$.pipe(map((users) => this.getViewModelsFromUsers(users)));

    this.getUsers(this.pageSize, this.pageIndex);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this._usersAppService.unsubscribeFromUserService();
  }

  getUsers = (size: number, index: number): void => {
    const usersParams: ListAllUsersRequestParams = {
      tilledAccount: this.accountId,
      offset: size * index,
      limit: size,
    };
    this._usersAppService.getAllUsers(usersParams);
  };

  getViewModelsFromUsers(users: User[]): UserViewModel[] {
    const viewModels: UserViewModel[] = [];
    if (!users || users.length === 0) {
      let temp: UserViewModel = new UserViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const user of users) {
      let lastLogin = 'Never';
      if (user.last_login_at) {
        lastLogin = this._dateFormatPipe.transform(user.last_login_at);
      }

      let temp: UserViewModel = new UserViewModel();
      temp.user_name = [];
      temp.user_name.push(user.name);
      temp.user_name.push(user.email);
      temp.last_login = lastLogin;
      temp.role = UserRoles.DisplayText.get(user.role);
      temp.verification_code = user.verification_code;
      temp.actionList = [];
      let disabled = false;
      if (
        user.role === User.RoleEnum.OWNER ||
        user.role === User.RoleEnum.MERCHANT_OWNER ||
        !this._authService.isScopeAble('users:write')
      ) {
        disabled = true;
      }
      temp.actionList.push(
        new ActionListItem({
          name: 'Edit User',
          callback: (): void => {
            this.editUser(user);
          },
          disabled: disabled,
        }),
      );
      temp.actionList.push(
        new ActionListItem({
          name: 'Delete User',
          callback: (): void => {
            this.deleteUser(user);
          },
          disabled: disabled,
        }),
      );

      viewModels.push(temp);
    }
    return viewModels;
  }

  deleteUser = (userData: User): void => {
    const params: DeleteUserRequestParams = {
      tilledAccount: this.accountId,
      id: userData.id,
    };
    this._usersAppService.deleteUser(params);
  };

  editUser = (userData: User): void => {
    const isMerchantUser = userData.role === User.RoleEnum.MERCHANT_ADMIN || userData.role === User.RoleEnum.MERCHANT_OWNER;
    this.dialogRef = this._matDialog.open(UserInviteDialogComponent, {
      panelClass: 'user-invite-dialog',
      data: {
        action: 'edit',
        user: this.getViewModelsFromUsers([userData])[0],
        isMerchantUser: isMerchantUser,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      let params: UpdateUserRequestParams = {
        tilledAccount: this.accountId,
        id: userData.id,
        userUpdateParams: {
          role: response.getRawValue().role,
        },
      };

      this._usersAppService.updateUser(params);
    });
  };
}

export class UserViewModel {
  @Column({
    order: 0,
    name: 'User Name',
    isMultiline: true,
  })
  user_name: string[];

  @Column({
    order: 1,
    name: 'Role',
  })
  role: string;

  @Column({
    order: 2,
    name: 'Last Login',
  })
  last_login: string;

  @Column({
    order: 3,
    name: 'Verification Code',
  })
  verification_code: string;

  @Column({
    order: 4,
    isActionList: true,
  })
  actionList: ActionListItem[];
}
