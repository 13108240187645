<merchant-app-card
    [stepNumber]="!forConsole ? '2' : null"
    [title]="!forConsole ? 'Business Contact Information' : null"
    [description]="!forConsole ? 'This section should include the contact details you share with your customers to get in touch with your business' : null"
    [backButton]="!forConsole"
    (backClick)="onBackClicked($event)"
    (continueClick)="onContinueClicked($event)"
    [continueButton]="!forConsole && !(disabled$ | async)"
>
    <form
        data-test-id="contact-step"
        class="w-full"
        [formGroup]="businessContactForm"
    >
        <tilled-input
            data-test-id="contact-phone-number"
            class="w-full"
            name="phone"
            placeholder="800-022-3212"
            mask="(000) 000-0000"
            label="CUSTOMER SUPPORT PHONE NUMBER"
            tilledLabel="true"
            errors="{{ businessContactForm.get('phone').hasError('invalidPhone') }}"
            errorMessage="Must be a valid phone number."
        ></tilled-input>
        <tilled-input
            data-test-id="contact-email"
            class="w-full"
            name="email"
            placeholder="getsupport@tilled.com"
            label="CUSTOMER SUPPORT EMAIL"
            tilledLabel="true"
            errors="{{ businessContactForm.get('email').hasError('email') }}"
            errorMessage="Email Address must be valid."
        ></tilled-input>
        <tilled-input
            data-test-id="contact-website"
            class="w-full"
            name="website"
            placeholder="https://www.yourcompanyname.xyz"
            label="COMPANY WEBSITE"
            tilledLabel="true"
            errors="{{ businessContactForm.get('website').hasError('website') }}"
            errorMessage="Website must begin with https:// and be valid."
        ></tilled-input>
        <app-autocomplete
            data-test-id="contact-street"
            class="w-full"
            name="street"
            placeholder="123 Main St"
            label="LEGAL BUSINESS ADDRESS"
            tilledLabel="true"
            [autocompleteAddress]="businessContactForm.value.street"
            (setAddress)="setBusinessAddress($event)"
        ></app-autocomplete>
        <tilled-input
            data-test-id="contact-street2"
            class="w-full"
            name="street2"
            placeholder="Suite 456"
            label="STREET ADDRESS 2"
            tilledLabel="true"
        ></tilled-input>
        <div class="grid sm:grid-cols-2 grid-cols-1 w-full gap-x-6">
            <div class="flex flex-col col-span-1">
                <tilled-input
                    data-test-id="contact-city"
                    name="city"
                    placeholder="Louisville"
                    label="CITY"
                    tilledLabel="true"
                ></tilled-input>
            </div>
            <div class="flex flex-col col-span-1">
                <tilled-label-l1 secondary="true" class="pt-8">STATE</tilled-label-l1>
                <mat-form-field appearance="standard" class="w-full -mt-4">
                    <mat-select
                        data-test-id="contact-state"
                        class="italic"
                        placeholder="Choose One"
                        [formControlName]="'state'"
                    >
                        <mat-option
                            *ngFor="let state of selectorTypes.states"
                            [value]="state"
                        >{{ state }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <tilled-input
            data-test-id="contact-zip"
            class="w-1/2"
            name="zip"
            placeholder="80205"
            label="ZIP"
            tilledLabel="true"
            maxLength="10"
        ></tilled-input>
    </form>
</merchant-app-card>

<!--div class="grid sm:grid-cols-2 grid-cols-1 w-full gap-x-6">
    <div class="flex flex-col col-span-1">
        <tilled-label-l1 [secondary]="true" class="pt-11">
            Account Type
        </tilled-label-l1>
        <mat-form-field appearance="standard" class="w-full -mt-4">
            <mat-select
                data-test-id="account-type-merchant"
                class="text-p3 text-secondary"
                [formControlName]="'accountType'"
            >
                <mat-option [value]="'checking'">Checking</mat-option>
                <mat-option [value]="'savings'">Savings</mat-option>
            </mat-select>
        </mat-form-field>
    </div-->
