import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { Charge } from '../../../../../projects/tilled-api-client/src';

/**
 * Takes a Charge object
 */
@Pipe({
  name: 'charge_status',
})
export class ChargeStatusPipe implements PipeTransform {
  /**
   *
   * @param charge Charge object
   * @param solid is the chip color solid (or opaque)
   * @returns
   */
  transform(charge: Charge, solid: boolean = false): TilledChipConfig {
    let chipConfig: TilledChipConfig = {
      color: ChipColorClass.SOLID_YELLOW,
      text: '',
      toolTip: '',
    };

    if (charge.status === Charge.StatusEnum.SUCCEEDED) {
      chipConfig.color = solid ? ChipColorClass.SOLID_GREEN : ChipColorClass.OPAQUE_GREEN;
      chipConfig.text = 'SUCCEEDED';
    } else if (charge.status === Charge.StatusEnum.PENDING) {
      chipConfig.color = solid ? ChipColorClass.SOLID_YELLOW : ChipColorClass.OPAQUE_YELLOW;
      chipConfig.text = 'PENDING';
    } else if (charge.status === Charge.StatusEnum.FAILED) {
      chipConfig.color = solid ? ChipColorClass.SOLID_RED : ChipColorClass.OPAQUE_RED;
      chipConfig.text = 'FAILED';
      chipConfig.toolTip = charge.failure_message;
    }
    return chipConfig;
  }
}
