import { KeyValue } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatStepper } from '@angular/material/stepper';
import { fuseAnimations } from '@fuse/animations';
import { PaymentsTypes } from 'app/core/data/payments-types';
import { SelectorTypes } from 'app/core/data/selector-types';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AuthService } from 'app/core/services/auth.service';
import { PaymentAppService } from 'app/core/services/payments.app.service';
import { allOrNone } from 'app/shared/validators/all-or-none.validator';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import {
  Account,
  AccountCapability,
  CreatePaymentIntentRequestParams,
  LineItem,
  PaymentIntentCreateParams,
  PaymentIntentLevel3,
  PaymentMethod,
  PaymentMethodCreateParams,
} from '../../../../../projects/tilled-api-client/src';

declare let Tilled: any; // from /assets/js/tilled.js
@Component({
  selector: 'app-collect-payment',
  templateUrl: './collect-payment-dialog.component.html',
  styleUrls: ['./collect-payment-dialog.component.scss'],
  animations: fuseAnimations,
})
export class CollectPaymentDialogComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  private accountId: string;
  public isMerchant: boolean;
  public account$: Observable<Account>;
  public merchantAccount: Account;
  public newPaymentForm: FormGroup;
  public availablePaymentMethods: Map<string, string>;
  public availableCurrencies: Map<string, string>;
  public cardPaymentForm: FormGroup;
  public bankPaymentForm: FormGroup;
  public eftPaymentForm: FormGroup;
  public selectedPaymentType: PaymentMethod.TypeEnum;
  public pageTitle: string;
  public selectedPaymentMethod: string;
  public selectedCurrency: string;
  public disablePaymentMethod: boolean = false;
  public disableCurrency: boolean = false;
  public cardAddressIsChecked: boolean = false;
  public cardLevel3IsChecked: boolean = false;

  public lineItems: LineItem[];

  public countryCodeMap: any;
  public stateCodeMap: any;

  private tilledCard: any;
  private tilledBank: any;
  public tilledCardForm: any;
  public tilledBankForm: any;
  public showCardNumberError = false;
  public showExpirationError = false;
  public showCvvError = false;
  public showAccountNumberError = false;
  public showRoutingNumberError = false;
  private cardFormBuilt = false;
  private bankFormBuilt = false;

  private _alertDialogError$ = new Subject<boolean>();
  public alertDialogError$ = this._alertDialogError$.asObservable();
  public errorTitle: string;
  public errorMessage: string;

  private _submittingPayment$ = new Subject<boolean>();
  public submittingPayment$ = this._submittingPayment$.asObservable();

  constructor(
    public dialogRef: MatDialogRef<CollectPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _paymentAppService: PaymentAppService,
    private _accountAppService: AccountAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
  ) {
    this.loadTilledJs();
  }

  ngOnInit(): void {
    this.accountId = this._data?.accountId ?? null;
    this.isMerchant = this._data?.isMerchant ?? false;

    if (this.isMerchant) {
      this.account$ = this._accountAppService.connectedAccount$;
      this._accountAppService.getConnectedAccountById(this.accountId);
      this.account$.subscribe({
        next: (result) => {
          this.merchantAccountChanged(result);
        },
        error: (err) => {},
      });
    }

    this.newPaymentForm = this._formBuilder.group({
      paymentMethod: new FormControl({ value: null, disabled: false }, [Validators.required]),
      currency: new FormControl({ value: null, disabled: false }, [Validators.required]),
      amount: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(0.01)]),
    });

    this.cardPaymentForm = this._formBuilder.group({
      cardholderName: new FormControl({ value: null, disabled: false }, [Validators.required]),
      postalCode: new FormControl({ value: null, disabled: false }, [Validators.required]),
      street1: new FormControl({ value: null, disabled: false }, []),
      street2: new FormControl({ value: null, disabled: false }, []),
      country: new FormControl({ value: null, disabled: false }, []),
      state: new FormControl({ value: null, disabled: false }, []),
      city: new FormControl({ value: null, disabled: false }, []),
      level3Form: new FormGroup(
        {
          shipToCountry: new FormControl({ value: null, disabled: false }, []),
          shipToZip: new FormControl({ value: null, disabled: false }, []),
          shipFromZip: new FormControl({ value: null, disabled: false }, []),
          shipAmount: new FormControl({ value: null, disabled: false }, []),
          dutyAmount: new FormControl({ value: null, disabled: false }, []),
          lineItemsArray: this._formBuilder.array([]),
        },
        [allOrNone()],
      ),
    });

    this.bankPaymentForm = this._formBuilder.group({
      accountholderName: new FormControl({ value: null, disabled: false }, [Validators.required]),
      accountType: new FormControl({ value: null, disabled: false }, [Validators.required]),
      street1: new FormControl({ value: null, disabled: false }, [Validators.required]),
      street2: new FormControl({ value: null, disabled: false }, []),
      country: new FormControl({ value: null, disabled: false }, [Validators.required]),
      state: new FormControl({ value: null, disabled: false }, [Validators.required]),
      city: new FormControl({ value: null, disabled: false }, [Validators.required]),
      postalCode: new FormControl({ value: null, disabled: false }, [Validators.required]),
    });

    this.countryCodeMap = SelectorTypes.CountryToCode;
    this.stateCodeMap = SelectorTypes.stateAndProvinceMap;
  }

  loadTilledJs(): void {
    var script = document.createElement('script');
    const hostname = window.location.hostname;
    if (
      window.location.hostname.includes('staging-app.tilled.com') ||
      window.location.hostname.includes('amplifyapp.com')
    ) {
      script.src = 'https://staging-js.tilled.com/v2';
    } else if (window.location.hostname.includes('localhost') || hostname.includes('ngrok')) {
      script.src = './assets/js/tilled.js';
    } else {
      script.src = 'https://js.tilled.com/v2';
    }
    document.head.appendChild(script);
  }

  updateLineItems = (lineItemsArray: LineItem[]): void => {
    this.lineItems = lineItemsArray;

    let formArray = this.cardPaymentForm.get('level3Form').get('lineItemsArray') as FormArray;
    formArray.clear();
    if (lineItemsArray) {
      for (let lineItem of lineItemsArray) {
        formArray.push(
          new FormControl({
            lineItem: [
              {
                value: lineItem,
                disabled: false, // disable input if k is passed in
              },
            ],
          }),
        );
      }
    }
  };

  merchantAccountChanged(account: Account): void {
    if (!account?.id) {
      return;
    }

    this.merchantAccount = account;

    this.availablePaymentMethods = new Map<string, string>();
    this.availableCurrencies = new Map<string, string>();
    for (let capability of this.merchantAccount.capabilities) {
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        this.availablePaymentMethods.set(
          capability?.product_code?.payment_method_type,
          PaymentsTypes.PaymentMethodDisplayText.get(capability?.product_code?.payment_method_type),
        );
      }
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        this.availableCurrencies.set(
          capability?.product_code?.currency,
          PaymentsTypes.CurrencyDisplayText.get(capability?.product_code?.currency),
        );
      }
    }

    if (this.availablePaymentMethods.size === 1) {
      this.selectedPaymentMethod = Array.from(this.availablePaymentMethods.keys())[0];
      if (this.newPaymentForm) {
        this.newPaymentForm.get('paymentMethod').setValue(this.selectedPaymentMethod);
      }
      this.disablePaymentMethod = true;
    } else {
      this.selectedPaymentMethod = null;
      this.disablePaymentMethod = false;

      if (Array.from(this.availablePaymentMethods.keys()).includes('card')) {
        this.selectedPaymentMethod = PaymentMethod.TypeEnum.CARD;
      }
    }

    if (this.availableCurrencies.size === 1) {
      this.selectedCurrency = Array.from(this.availableCurrencies.keys())[0];
      if (this.newPaymentForm) {
        this.newPaymentForm.get('currency').setValue(this.selectedCurrency);
      }
      this.disableCurrency = true;
    } else {
      this.selectedCurrency = null;
      this.disableCurrency = false;
    }
  }

  // these are optional for credit card
  cardAddressToggled(event: MatSlideToggleChange): void {
    if (!event.checked) {
      this.cardPaymentForm.get('street1').setValue(null);
      this.cardPaymentForm.get('street2').setValue(null);
      this.cardPaymentForm.get('country').setValue(null);
      this.cardPaymentForm.get('state').setValue(null);
      this.cardPaymentForm.get('city').setValue(null);
    }
  }

  cardLevel3Toggled(event: MatSlideToggleChange): void {
    if (!event.checked) {
      this.lineItems = null;
      this.cardPaymentForm.get('level3Form').reset();
    }
  }

  public async closeDialog(value?: any): Promise<void> {
    if (this.tilledCardForm) {
      await this.tilledCardForm.teardown();
    }
    if (this.tilledBankForm) {
      await this.tilledBankForm.teardown();
    }

    this.dialogRef.close(value);
  }

  public async nextPage(): Promise<void> {
    this.selectedPaymentType = this.newPaymentForm.get('paymentMethod').value;

    if (this.selectedPaymentType == PaymentMethod.TypeEnum.EFT_DEBIT) {
      this.bankPaymentForm.get('accountType').clearValidators();
      this.bankPaymentForm.get('accountType').updateValueAndValidity();
    }

    if (!this.isMerchant && this.merchantAccount) {
      this.pageTitle = `Complete payment to ${this.merchantAccount.name} for `;
    } else {
      this.pageTitle = 'Complete payment for ';
    }

    this.stepper.next();
    if (!this.cardFormBuilt && this.selectedPaymentType == PaymentMethod.TypeEnum.CARD) {
      this.cardFormBuilt = true;
      await this.buildTilledCardForm();
    }
    if (
      !this.bankFormBuilt &&
      (this.selectedPaymentType == PaymentMethod.TypeEnum.ACH_DEBIT ||
        this.selectedPaymentType == PaymentMethod.TypeEnum.EFT_DEBIT)
    ) {
      this.bankFormBuilt = true;
      await this.buildTilledBankForm();
    }
  }

  public prevPage(): void {
    this.selectedPaymentType = null;
    this.stepper.previous();
  }

  private async buildTilledCardForm(): Promise<void> {
    const publishableKey = AuthService.getAccessToken();

    this.tilledCard = new Tilled(publishableKey, this.merchantAccount.id, {
      endpoint: environment.api + '/v1',
      sandbox: !environment.production,
      log_level: 0,
    });

    this.tilledCardForm = await this.tilledCard.form({
      payment_method_type: PaymentMethod.TypeEnum.CARD,
    });

    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter var, sans-serif',
          color: '#1B253B', //fuse-accent (text-tilled-primary)
          fontWeight: '400',
          fontSize: '15px',
        },
      },
    };

    this.tilledCardForm.createField('cardNumber', fieldOptions).inject('#card-number-element');
    this.tilledCardForm
      .createField('cardExpiry', { ...fieldOptions, placeholder: '' })
      .inject('#card-expiration-element');
    this.tilledCardForm.createField('cardCvv', fieldOptions).inject('#card-cvv-element');

    this.tilledCardForm.fields.cardNumber.on('change', (change) => {
      const cardBrand = change.brand;
      const cardSuffix: any = document.getElementById('card-suffix');

      switch (cardBrand) {
        case 'amex':
          cardSuffix.innerHTML = '<img class="p-8" src="assets/images/card-brands/amex.tif.svg" />';
          break;
        case 'mastercard':
          cardSuffix.innerHTML = '<img class="p-8" src="assets/images/card-brands/mastercard.tif.svg" />';
          break;
        case 'visa':
          cardSuffix.innerHTML = '<img src="assets/images/card-brands/visa.tif.svg" />';
          break;
        case 'discover':
          cardSuffix.innerHTML = '<img src="assets/images/card-brands/discover.tif.svg" />';
          break;
        default:
          cardSuffix.innerHTML =
            '<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">credit_card</mat-icon>';
      }
      if (this.tilledCardForm.fields.cardNumber.valid) {
        this.tilledCardForm.fields.cardNumber.element.classList.remove('success');
        this.tilledCardForm.fields.cardNumber.element.classList.remove('error');
        this.showCardNumberError = false;
        this.tilledCardForm.fields.cardNumber.element.classList.add('success');
      }
    });

    this.tilledCardForm.fields.cardNumber.on('focus', () => {
      this.tilledCardForm.fields.cardNumber.element.classList.add('focus');
    });

    this.tilledCardForm.fields.cardNumber.on('blur', () => {
      this.tilledCardForm.fields.cardNumber.element.classList.remove('success');
      this.tilledCardForm.fields.cardNumber.element.classList.remove('error');
      if (this.tilledCardForm.fields.cardNumber.valid) {
        this.showCardNumberError = false;
        this.tilledCardForm.fields.cardNumber.element.classList.add('success');
      } else {
        this.showCardNumberError = true;
        this.tilledCardForm.fields.cardNumber.element.classList.add('error');
      }
      this.tilledCardForm.fields.cardNumber.element.classList.remove('focus');
    });

    this.tilledCardForm.fields.cardExpiry.on('change', (change) => {
      if (this.tilledCardForm.fields.cardExpiry.valid) {
        this.tilledCardForm.fields.cardExpiry.element.classList.remove('success');
        this.tilledCardForm.fields.cardExpiry.element.classList.remove('error');
        this.showExpirationError = false;
        this.tilledCardForm.fields.cardExpiry.element.classList.add('success');
      }
    });

    this.tilledCardForm.fields.cardExpiry.on('focus', () => {
      this.tilledCardForm.fields.cardExpiry.element.classList.add('focus');
    });

    this.tilledCardForm.fields.cardExpiry.on('blur', () => {
      this.tilledCardForm.fields.cardExpiry.element.classList.remove('success');
      this.tilledCardForm.fields.cardExpiry.element.classList.remove('error');
      if (this.tilledCardForm.fields.cardExpiry.valid) {
        this.showExpirationError = false;
        this.tilledCardForm.fields.cardExpiry.element.classList.add('success');
      } else {
        this.showExpirationError = true;
        this.tilledCardForm.fields.cardExpiry.element.classList.add('error');
      }
      this.tilledCardForm.fields.cardExpiry.element.classList.remove('focus');
    });

    this.tilledCardForm.fields.cardCvv.on('change', (change) => {
      if (this.tilledCardForm.fields.cardCvv.valid) {
        this.tilledCardForm.fields.cardCvv.element.classList.remove('success');
        this.tilledCardForm.fields.cardCvv.element.classList.remove('error');
        this.showCvvError = false;
        this.tilledCardForm.fields.cardCvv.element.classList.add('success');
      }
    });

    this.tilledCardForm.fields.cardCvv.on('focus', () => {
      this.tilledCardForm.fields.cardCvv.element.classList.add('focus');
    });

    this.tilledCardForm.fields.cardCvv.on('blur', () => {
      this.tilledCardForm.fields.cardCvv.element.classList.remove('success');
      this.tilledCardForm.fields.cardCvv.element.classList.remove('error');
      if (this.tilledCardForm.fields.cardCvv.valid) {
        this.showCvvError = false;
        this.tilledCardForm.fields.cardCvv.element.classList.add('success');
      } else {
        this.showCvvError = true;
        this.tilledCardForm.fields.cardCvv.element.classList.add('error');
      }
      this.tilledCardForm.fields.cardCvv.element.classList.remove('focus');
    });

    await this.tilledCardForm.build();
  }

  private async buildTilledBankForm(): Promise<void> {
    const publishableKey = AuthService.getAccessToken();

    this.tilledBank = new Tilled(publishableKey, this.merchantAccount.id, {
      endpoint: environment.api + '/v1',
      sandbox: !environment.production,
      log_level: 0,
    });

    this.tilledBankForm = await this.tilledBank.form({
      payment_method_type: this.selectedPaymentType,
    });

    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter var, sans-serif',
          color: '#1B253B', //fuse-accent (text-tilled-primary)
          fontWeight: '400',
          fontSize: '15px',
        },
      },
    };

    this.tilledBankForm.createField('bankAccountNumber', fieldOptions).inject('#bank-account-number-element');
    this.tilledBankForm.createField('bankRoutingNumber', fieldOptions).inject('#bank-routing-number-element');

    this.tilledBankForm.fields.bankAccountNumber.on('change', (change) => {
      if (this.tilledBankForm.fields.bankAccountNumber.valid) {
        this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('success');
        this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('error');
        this.showAccountNumberError = false;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('success');
      }
    });

    this.tilledBankForm.fields.bankAccountNumber.on('focus', () => {
      this.tilledBankForm.fields.bankAccountNumber.element.classList.add('focus');
    });

    this.tilledBankForm.fields.bankAccountNumber.on('blur', () => {
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('success');
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('error');
      if (this.tilledBankForm.fields.bankAccountNumber.valid) {
        this.showAccountNumberError = false;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('success');
      } else {
        this.showAccountNumberError = true;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('error');
      }
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('focus');
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('change', (change) => {
      if (this.tilledBankForm.fields.bankRoutingNumber.valid) {
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('success');
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('error');
        this.showRoutingNumberError = false;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('success');
      }
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('focus', () => {
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('focus');
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('blur', () => {
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('success');
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('error');
      if (this.tilledBankForm.fields.bankRoutingNumber.valid) {
        this.showRoutingNumberError = false;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('success');
      } else {
        this.showRoutingNumberError = true;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('error');
      }
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('focus');
    });

    await this.tilledBankForm.build();
  }

  public async collectBankPayment(): Promise<void> {
    this._submittingPayment$.next(true);
    await this.tilledBank
      .createPaymentMethod({
        type:
          this.selectedPaymentType === PaymentMethod.TypeEnum.ACH_DEBIT
            ? PaymentMethodCreateParams.TypeEnum.ACH_DEBIT
            : PaymentMethodCreateParams.TypeEnum.EFT_DEBIT,
        ach_debit: {
          account_type:
            this.selectedPaymentType === PaymentMethod.TypeEnum.ACH_DEBIT
              ? this.bankPaymentForm.get('accountType').value
              : null,
          account_holder_name: this.bankPaymentForm.get('accountholderName').value,
        },
        billing_details: {
          address: {
            street: this.bankPaymentForm.get('street1').value,
            street2: this.bankPaymentForm.get('street2').value,
            country: this.bankPaymentForm.get('country').value,
            state: this.bankPaymentForm.get('state').value,
            city: this.bankPaymentForm.get('city').value,
            zip: this.bankPaymentForm.get('postalCode').value,
          },
          name: this.bankPaymentForm.get('accountholderName').value,
        },
      })
      .then(
        async (paymentMethod) => {
          await this.createAndConfirmPaymentIntent(paymentMethod);
        },
        (err) => {
          // An error with the request (>400 status code)
          this.errorTitle = 'Creating Payment Method Failed';
          if (err === 'Unauthorized: jwt expired') {
            this.errorMessage = 'Please close this payment form and try again.';
          } else if (err.toString().includes('billing_details.address.state')) {
            if (this.bankPaymentForm.get('country').value == 'US') {
              this.errorMessage = 'Please select a valid state';
            } else {
              this.errorMessage = 'Please select a valid province';
            }
          } else {
            this.errorMessage = err;
          }
          this._alertDialogError$.next(true);
          this._submittingPayment$.next(false);
        },
      );
  }

  public async collectCardPayment(): Promise<void> {
    this._submittingPayment$.next(true);
    await this.tilledCard
      .createPaymentMethod({
        type: PaymentMethodCreateParams.TypeEnum.CARD,
        billing_details: {
          address: {
            street: this.cardPaymentForm.get('street1').value,
            street2: this.cardPaymentForm.get('street2').value,
            country: this.cardPaymentForm.get('country').value,
            state: this.cardPaymentForm.get('state').value,
            city: this.cardPaymentForm.get('city').value,
            zip: this.cardPaymentForm.get('postalCode').value,
          },
          name: this.cardPaymentForm.get('cardholderName').value,
        },
      })
      .then(
        async (paymentMethod) => {
          let level3 = this.prepareCardPayment();
          await this.createAndConfirmPaymentIntent(paymentMethod, level3);
        },
        (err) => {
          // An error with the request (>400 status code)
          this.errorTitle = 'Creating Payment Method Failed';
          if (err === 'Unauthorized: jwt expired') {
            this.errorMessage = 'Please close this payment form and try again.';
          } else if (err.toString().includes('billing_details.address.state')) {
            if (this.cardPaymentForm.get('country').value == 'US') {
              this.errorMessage = 'Please select a valid state';
            } else {
              this.errorMessage = 'Please select a valid province';
            }
          } else {
            this.errorMessage = err;
          }
          this._alertDialogError$.next(true);
          this._submittingPayment$.next(false);
        },
      );
  }

  private prepareCardPayment(): any {
    let lineItems: LineItem[] = null;
    let lineItemsArray = this.cardPaymentForm.get('level3Form').get('lineItemsArray').value;
    if (lineItemsArray && lineItemsArray.length > 0) {
      lineItems = [];
      for (let lineItemForm of lineItemsArray) {
        let lineItem = lineItemForm.lineItem[0].value;
        let tempLineItem: LineItem = {
          product_code: lineItem.product_code,
          product_description: lineItem.product_description,
          quantity: parseInt(lineItem.quantity),
          unit_amount: this._numericToMinorPipe.transform(lineItem.unit_amount),
          tax_amount: this._numericToMinorPipe.transform(lineItem.tax_amount),
        };
        lineItems.push(tempLineItem);
      }
    }

    let level3: PaymentIntentLevel3 = null;
    if (this.cardPaymentForm.get('level3Form').get('shipToZip').value) {
      level3 = {
        shipping_address_zip: this.cardPaymentForm.get('level3Form').get('shipToZip').value,
        shipping_address_country: this.cardPaymentForm.get('level3Form').get('shipToCountry').value,
        shipping_amount: this.cardPaymentForm.get('level3Form').get('shipAmount').value
          ? this._numericToMinorPipe.transform(this.cardPaymentForm.get('level3Form').get('shipAmount').value)
          : null,
        shipping_from_zip: this.cardPaymentForm.get('level3Form').get('shipFromZip').value,
        duty_amount: this.cardPaymentForm.get('level3Form').get('dutyAmount').value
          ? this._numericToMinorPipe.transform(this.cardPaymentForm.get('level3Form').get('dutyAmount').value)
          : null,
        line_items: lineItems,
      };
    }
    return level3;
  }

  private createAndConfirmPaymentIntent(paymentMethod: PaymentMethod, level3?: PaymentIntentLevel3): void {
    let createParams: CreatePaymentIntentRequestParams = {
      tilledAccount: this.merchantAccount.id,
      paymentIntentCreateParams: {
        payment_method_types: [this.selectedPaymentType],
        amount: this._numericToMinorPipe.transform(this.newPaymentForm.get('amount').value),
        currency: this.newPaymentForm.get('currency').value,
        payment_method_id: paymentMethod.id,
        capture_method: PaymentIntentCreateParams.CaptureMethodEnum.AUTOMATIC,
        level3: level3,
      },
    };

    let payment$ = this._paymentAppService.createPaymentIntent(createParams, this.accountId);
    const tilled = paymentMethod.type === PaymentMethod.TypeEnum.CARD ? this.tilledCard : this.tilledBank;
    payment$.subscribe({
      next: async (result) => {
        await tilled.confirmPayment(result.client_secret, { payment_method: paymentMethod.id }).then(
          (payment) => {
            if (payment.last_payment_error) {
              this.errorTitle = 'Payment Failed';
              this.errorMessage = payment.last_payment_error.message;
              this._alertDialogError$.next(true);
              this._submittingPayment$.next(false);
            } else {
              //payment flow is complete, close dialog
              this.closeDialog(payment.id);
            }
          },
          (err) => {
            this.errorTitle = 'Payment Failed';
            if (err === 'Unauthorized: jwt expired') {
              this.errorMessage = 'Please close this payment form and try again.';
            } else {
              this.errorMessage = err;
            }
            this._alertDialogError$.next(true);
            this._submittingPayment$.next(false);
          },
        );
      },
      error: (err) => {
        this.errorTitle = 'Payment Failed';
        this.errorMessage = err?.error?.message;
        this._alertDialogError$.next(true);
        this._submittingPayment$.next(false);
      },
    });
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
