<ng-container
    *ngIf="showCard"
>
    <div
        class="w-full pb-2"
    >
        <tilled-paragraph-p3>{{label}}</tilled-paragraph-p3
        >
    </div>
    <!-- Debit Template Details -->
    <ng-container>
        <div class="w-full">
            <div
                class="
                    grid
                    sm:grid-cols-2
                    grid-cols-1
                    w-full
                    my-[5px]
                "
            >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-start
                        justify-self-center
                    "
                    [secondary]="true"
                >
                    Currency</tilled-paragraph-p3
                >

                <tilled-paragraph-p3
                    class="
                        sm:justify-self-end
                        justify-self-center
                    "
                >
                    {{ currency | uppercase }}
                </tilled-paragraph-p3>
            </div>
            <div
                class="
                    grid
                    sm:grid-cols-2
                    grid-cols-1
                    w-full
                    my-[5px]
                "
            >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-start
                        justify-self-center
                    "
                    [secondary]="true"
                >
                    Fee
                    Type</tilled-paragraph-p3
                >

                <tilled-paragraph-p3
                    class="
                        sm:justify-self-end
                        justify-self-center
                    "
                >
                    {{ feeType | snakeCaseSplit | titlecase }}
                </tilled-paragraph-p3>
            </div>
            <mat-divider
                class="w-full mt-3 mb-4"
            ></mat-divider>
            <div
                class="
                    grid
                    sm:grid-cols-2
                    grid-cols-1
                    w-full
                    my-[5px]
                "
            >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-start
                        justify-self-center
                    "
                    [secondary]="true"
                    >Transaction
                    Fee</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-end
                        justify-self-center
                    "
                >
                    {{ transactionFee | minorUnitsToCurrency : currency }}
                </tilled-paragraph-p3>
            </div>
            <div
                class="
                    grid
                    sm:grid-cols-2
                    grid-cols-1
                    w-full
                    my-[5px]
                "
            >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-start
                        justify-self-center
                    "
                    [secondary]="true"
                    >Return
                    Fee</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-end
                        justify-self-center
                    "
                >
                    {{ returnFee | minorUnitsToCurrency : currency }}
                </tilled-paragraph-p3>
            </div>
            <mat-divider
                class="w-full mt-3 mb-4"
            ></mat-divider>
            <div
                class="
                    grid
                    sm:grid-cols-2
                    grid-cols-1
                    w-full
                    my-[5px]
                "
            >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-start
                        justify-self-center
                    "
                    [secondary]="true"
                    >Monthly Account
                    Fee</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-end
                        justify-self-center
                    "
                >
                    {{ accountMonthlyFee | minorUnitsToCurrency : currency }}
                </tilled-paragraph-p3>
            </div>
            <div
                class="
                    grid
                    sm:grid-cols-2
                    grid-cols-1
                    w-full
                    my-[5px]
                "
            >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-start
                        justify-self-center
                    "
                    [secondary]="true"
                    >Monthly Account
                    Minimum
                    Fee</tilled-paragraph-p3
                >
                <tilled-paragraph-p3
                    class="
                        sm:justify-self-end
                        justify-self-center
                    "
                >
                    {{ accountMonthlyMinimumFee | minorUnitsToCurrency : currency }}
                </tilled-paragraph-p3>
            </div>
        </div>
    </ng-container>
</ng-container>