import { Injectable } from '@angular/core';
import { BehaviorSubject, first, map, ReplaySubject, Subscription, tap, Subject, Observable, catchError } from 'rxjs';
import {
  CreateUserInvitationRequestParams,
  DeleteUserInvitationRequestParams,
  DeleteUserRequestParams,
  ListAllUserInvitationsRequestParams,
  ListAllUsersRequestParams,
  ResendUserInvitationRequestParams,
  UpdateUserRequestParams,
  User,
  UserInvitation,
  UsersService,
} from '../../../../projects/tilled-api-client/src';
import { DEFAULT_PAGE_LIMIT } from '../constants';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class UsersAppService {
  private _users$ = new BehaviorSubject<User[]>(null);
  private _usersCount$ = new BehaviorSubject<number>(null);
  private _userInvitations$ = new ReplaySubject<UserInvitation[]>();
  private _userInvitationsCount$ = new ReplaySubject<number>();
  private invitationResponse = new Subject<UserInvitation>();

  public users$: Observable<User[]> = this._users$.asObservable();
  public usersCount$: Observable<number> = this._usersCount$.asObservable();
  public userInvitations$: Observable<UserInvitation[]> = this._userInvitations$.asObservable();
  public userInvitationsCount$: Observable<number> = this._userInvitationsCount$.asObservable();
  public userInvitationResponse$: Observable<UserInvitation> = this.invitationResponse.asObservable();

  private allUsersSub: Subscription;

  constructor(private _usersService: UsersService, private _alertService: AlertService) {}

  public getAllUsers(params: ListAllUsersRequestParams): void {
    this.allUsersSub = this._usersService
      .listAllUsers(params)
      .pipe(
        tap((result) => this._usersCount$.next(result.total)),
        map((result) => result.items),
        first(),
      )
      .subscribe({
        next: (users) => {
          this._users$.next(users);
        },
        error: (err) => {
          if (err?.error?.statusCode === 400) {
            const message: TilledAlert = {
              message: err?.error?.message,
              title: 'Failed',
              type: 'error',
            };
            this._alertService.showAlert(message);
          } else {
            // generic catch all for error responses
            const message: TilledAlert = {
              message: 'Could not load all users',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          }
        },
      });
  }

  public deleteUser(params: DeleteUserRequestParams, retrieveParams?: ListAllUsersRequestParams): void {
    this._usersService.deleteUser(params).subscribe({
      next: (res) => {
        const retParams: ListAllUsersRequestParams = {
          tilledAccount: params.tilledAccount,
          limit: retrieveParams?.limit ?? DEFAULT_PAGE_LIMIT,
          offset: retrieveParams?.offset ?? 0,
        };

        const message: TilledAlert = {
          message: "User '" + params.id + "' was deleted successfully",
          title: 'User deleted',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        this.getAllUsers(retParams);
      },
      error: (err) => {
        if (err?.error?.statusCode === 400) {
          const message: TilledAlert = {
            message: err?.error?.message,
            title: 'Failed',
            type: 'error',
          };
          this._alertService.showAlert(message);
        } else {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not delete user',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      },
    });
  }

  public updateUser(params: UpdateUserRequestParams, retrieveParams?: ListAllUsersRequestParams): void {
    this._usersService.updateUser(params).subscribe({
      next: (res) => {
        const retParams: ListAllUsersRequestParams = {
          tilledAccount: params.tilledAccount,
          limit: retrieveParams?.limit ?? DEFAULT_PAGE_LIMIT,
          offset: retrieveParams?.offset ?? 0,
        };
        const message: TilledAlert = {
          message: "User '" + res.email + "' was updated successfully",
          title: 'User updated',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        this.getAllUsers(retParams);
      },
      error: (err) => {
        if (err?.error?.statusCode === 400) {
          const message: TilledAlert = {
            message: err?.error?.message,
            title: 'Failed',
            type: 'error',
          };
          this._alertService.showAlert(message);
        } else {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not update user',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      },
    });
  }

  public getAllUserInvitations(params: ListAllUserInvitationsRequestParams): void {
    this._usersService
      .listAllUserInvitations(params)
      .pipe(
        tap((result) => this._userInvitationsCount$.next(result.total)),
        map((result) => result.items),
      )
      .subscribe({
        next: (userInvs) => {
          this._userInvitations$.next(userInvs);
        },
        error: (err) => {
          if (err?.error?.statusCode === 400) {
            const message: TilledAlert = {
              message: err?.error?.message,
              title: 'Failed',
              type: 'error',
            };
            this._alertService.showAlert(message);
          } else {
            // generic catch all for error responses
            const message: TilledAlert = {
              message: 'Could not load all user invitations',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          }
        },
      });
  }

  public sendUserInvitation(
    params: CreateUserInvitationRequestParams,
    retrieveParams?: ListAllUsersRequestParams,
  ): void {
    this._usersService
      .createUserInvitation(params)
      .pipe(map((result) => result))
      .subscribe({
        next: (userInv) => {
          const retParams: ListAllUsersRequestParams = {
            tilledAccount: params.tilledAccount,
            limit: retrieveParams?.limit ?? DEFAULT_PAGE_LIMIT,
            offset: retrieveParams?.offset ?? 0,
          };

          const message: TilledAlert = {
            message: "User invitation for '" + userInv.email + "' was sent successfully",
            title: 'User invitation sent',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);

          this.getAllUserInvitations(retParams);
        },
        error: (err) => {
          if (err?.error?.statusCode === 400) {
            const message: TilledAlert = {
              message: err?.error?.message,
              title: 'Failed',
              type: 'error',
            };
            this._alertService.showAlert(message);
          } else {
            // generic catch all for error responses
            const message: TilledAlert = {
              message: 'Could not send user invitation',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          }
        },
      });
  }

  public deleteUserInvitation(params: DeleteUserInvitationRequestParams): void {
    this._usersService.deleteUserInvitation(params).subscribe({
      next: (res) => {
        const retParams: ListAllUsersRequestParams = {
          tilledAccount: params.tilledAccount,
          limit: DEFAULT_PAGE_LIMIT,
          offset: 0,
        };

        const message: TilledAlert = {
          message: "User invitation '" + params.id + "' was deleted successfully",
          title: 'User invitation deleted',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        this.getAllUserInvitations(retParams);
      },
      error: (err) => {
        if (err?.error?.statusCode === 400) {
          const message: TilledAlert = {
            message: err?.error?.message,
            title: 'Failed',
            type: 'error',
          };
          this._alertService.showAlert(message);
        } else {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not delete user invitation',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      },
    });
  }

  public resendUserInvitation(params: ResendUserInvitationRequestParams): void {
    this._usersService.resendUserInvitation(params).subscribe({
      next: (result) => {
        const message: TilledAlert = {
          message: "User invitation '" + params.id + "' was resent successfully",
          title: 'User invitation sent',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);
      },
      error: (err) => {
        if (err?.error?.statusCode === 400) {
          const message: TilledAlert = {
            message: err?.error?.message,
            title: 'Failed',
            type: 'error',
          };
          this._alertService.showAlert(message);
        } else {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not resend user invitation',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      },
    });
  }

  public unsubscribeFromUserService(): void {
    this.allUsersSub.unsubscribe();
  }

  public inviteUser(params: CreateUserInvitationRequestParams): void {
    this._usersService
      .createUserInvitation(params)
      .pipe(
        catchError((err) => {
          this.invitationResponse.next(err);
          throw 'Error creating user invitation for merchant application ' + JSON.stringify(err);
        }),
      )
      .subscribe((response) => this.invitationResponse.next(response));
  }
}
