<div class="flex flex-col flex-auto min-w-100">
    <div class="flex justify-end">
        <mat-icon
            style="cursor: pointer"
            (click)="closeDialog()"
            svgIcon="mat_outline:close"
        ></mat-icon>
    </div>
    <ng-container>
        <div class="grid w-full justify-items-center px-4">
            <!-- Title -->
            <tilled-heading-h2 class="pb-2">Add New Merchant</tilled-heading-h2>
            <tilled-paragraph-p3
                >Enter the company’s basic information and set up their
                pricing.</tilled-paragraph-p3
            >
            <tilled-paragraph-p3
                >Shift4 will generate a link to an application you can share
                with your</tilled-paragraph-p3
            >
            <tilled-paragraph-p3 class="pb-4">customer.</tilled-paragraph-p3>

            <!-- Form -->
            <ng-container>
                <div class="min-w-100">
                    <form [formGroup]="connectedAccountForm">
                        <mat-form-field
                            floatLabel="always"
                            appearance="standard"
                            class="flex flex-col w-full -mb-4"
                        >
                            <tilled-label-l1 secondary="true"
                                >BUSINESS NAME</tilled-label-l1
                            >
                            <input
                                data-test-id="merchant-account-owner"
                                class="text-p3 text-secondary pt-4"
                                matInput
                                formControlName="name"
                                placeholder="Enter the business name"
                            />
                            <mat-error
                                class="text-sm"
                                color="warn"
                                *ngIf="
                                    connectedAccountForm
                                        .get('name')
                                        .hasError('required')
                                "
                                >A business name is required</mat-error
                            >
                        </mat-form-field>
                        <div *ngIf="isSandbox" class="w-100 pt-6">
                            <fuse-alert
                                [type]="'basic'"
                                [appearance]="'outline'"
                                [dismissible]="false"
                                [dismissed]="false"
                                [name]="'connectedAccountAlert'">
                                <mat-icon class="icon-size-5"
                                    fuseAlertIcon
                                    [svgIcon]="'heroicons_solid:information-circle'">
                                </mat-icon>
                                <tilled-heading-h6 fuseAlertTitle>Sandbox Environment</tilled-heading-h6>
                                <div class="flex flex-row">
                                    <tilled-paragraph-p3>In the sandbox, start the account name with "*" to automatically activate it (e.g. *The Surf Shop).</tilled-paragraph-p3>
                                </div>
                            </fuse-alert>
                        </div>
                        <mat-form-field
                            floatLabel="always"
                            appearance="standard"
                            class="flex flex-col w-full -mb-4"
                        >
                            <tilled-label-l1 secondary="true"
                                >BUSINESS OWNER'S EMAIL</tilled-label-l1
                            >
                            <input
                                data-test-id="merchant-account-email"
                                class="text-p3 text-secondary pt-4"
                                matInput
                                formControlName="email"
                                placeholder="Enter the business owner's email"
                            />
                            <mat-error
                                class="text-sm"
                                color="warn"
                                *ngIf="
                                    connectedAccountForm
                                        .get('email')
                                        .hasError('email') ||
                                    connectedAccountForm
                                        .get('email')
                                        .hasError('required')
                                "
                                >A valid email is required</mat-error
                            >
                        </mat-form-field>

                        <mat-form-field
                            floatLabel="always"
                            appearance="standard"
                            class="w-full pb-6"
                        >
                            <tilled-label-l1 secondary="true"
                                >REGION</tilled-label-l1
                            >
                            <mat-select
                                data-test-id="merchant-account-region"
                                class="text-p3 text-secondary pt-4"
                                [formControlName]="'region'"
                                (selectionChange)="
                                    updatePricingTemplates($event)
                                "
                                placeholder="Select a region"
                                [disabled]="isLoading"
                            >
                                <mat-option [value]="'usd'"
                                    >United States</mat-option
                                >
                                <mat-option [value]="'cad'">Canada</mat-option>
                            </mat-select>
                            <mat-error
                                class="text-sm"
                                color="warn"
                                *ngIf="
                                    connectedAccountForm
                                        .get('region')
                                        .hasError('required')
                                "
                                >A region is required</mat-error
                            >
                        </mat-form-field>
                        <div class="flex flex-col w-full">
                            <div class="pb-4">
                                <mat-slide-toggle
                                    data-test-id="merchant-account-card-processing"
                                    color="primary"
                                    (change)="cardToggled($event)"
                                    [(ngModel)]="cardIsChecked"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <tilled-paragraph-p3
                                        >Credit and debit card
                                        processing</tilled-paragraph-p3
                                    >
                                </mat-slide-toggle>
                                <ng-container *ngIf="cardIsChecked">
                                    <div
                                        class="flex flex-col flex-auto min-w-0"
                                    >
                                        <mat-form-field
                                            floatLabel="always"
                                            appearance="standard"
                                            class="flex flex-col w-full pb-2"
                                        >
                                            <mat-select
                                                data-test-id="merchant-account-card-pricing"
                                                class="text-p3 text-secondary"
                                                [formControlName]="
                                                    'cardPricingTemplate'
                                                "
                                                (selectionChange)="
                                                    updateSelectedCard($event)
                                                "
                                                placeholder="Select card pricing"
                                            >
                                                <mat-option
                                                    *ngFor="
                                                        let pricingTemplate of cardPricingTemplates
                                                    "
                                                    [value]="pricingTemplate.id"
                                                    >{{
                                                        pricingTemplate.name
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                            <mat-error
                                                class="text-sm"
                                                color="warn"
                                                *ngIf="
                                                    connectedAccountForm.get(
                                                        'cardPricingTemplate'
                                                    ).invalid
                                                "
                                                >Card pricing is required if
                                                selected</mat-error
                                            >
                                        </mat-form-field>
                                        <ng-container
                                            *ngIf="selectedCardTemplate"
                                        >
                                            <card-pricing
                                                [showCard]="true"
                                                [currency]="selectedCardTemplate.currency"
                                                [feeType]="selectedCardTemplate.fee_type"
                                                [visaRate]="selectedCardTemplate.visa_rate"
                                                [amexRate]="selectedCardTemplate.amex_rate"
                                                [transactionFee]="selectedCardTemplate.transaction_fee"
                                                [chargebackFee]="selectedCardTemplate.chargeback_fee"
                                                [retrievalFee]="selectedCardTemplate.retrieval_fee"
                                                [reversalFee]="selectedCardTemplate.reversal_fee"
                                                [accountMonthlyFee]="selectedCardTemplate.account_monthly_fee"
                                                [accountMonthlyMinimumFee]="selectedCardTemplate.account_monthly_minimum_fee"
                                            ></card-pricing>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="pb-4">
                                <mat-slide-toggle
                                    data-test-id="merchant-account-dd-option"
                                    color="primary"
                                    (change)="debitToggled($event)"
                                    [(ngModel)]="debitIsChecked"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <tilled-paragraph-p3
                                        >Direct debit
                                        processing</tilled-paragraph-p3
                                    >
                                </mat-slide-toggle>
                                <ng-container *ngIf="debitIsChecked">
                                    <div
                                        class="flex flex-col flex-auto min-w-0"
                                    >
                                        <mat-form-field
                                            floatLabel="always"
                                            appearance="standard"
                                            class="flex flex-col w-full pb-2"
                                        >
                                            <mat-select
                                                data-test-id="merchant-account-dd-pricing"
                                                class="text-p3 text-secondary"
                                                [formControlName]="
                                                    'debitPricingTemplate'
                                                "
                                                (selectionChange)="
                                                    updateSelectedDebit($event)
                                                "
                                                placeholder="Select direct debit pricing"
                                            >
                                                <mat-option
                                                    *ngFor="
                                                        let pricingTemplate of debitPricingTemplates
                                                    "
                                                    [value]="pricingTemplate.id"
                                                    >{{
                                                        pricingTemplate.name
                                                    }}</mat-option
                                                >
                                            </mat-select>
                                            <mat-error
                                                class="text-sm"
                                                color="warn"
                                                *ngIf="
                                                    connectedAccountForm.get(
                                                        'debitPricingTemplate'
                                                    ).invalid
                                                "
                                                >Direct debit pricing is
                                                required if selected</mat-error
                                            >
                                        </mat-form-field>
                                        <ng-container
                                            *ngIf="selectedDebitTemplate"
                                        >
                                            <debit-pricing
                                                [showCard]="true"
                                                [currency]="selectedDebitTemplate.currency"
                                                [feeType]="selectedDebitTemplate.fee_type"
                                                [transactionFee]="selectedDebitTemplate.transaction_fee"
                                                [returnFee]="selectedDebitTemplate.return_fee"
                                                [accountMonthlyFee]="selectedDebitTemplate.account_monthly_fee"
                                                [accountMonthlyMinimumFee]="selectedDebitTemplate.account_monthly_minimum_fee"
                                            ></debit-pricing>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>

        <!-- Add Actions -->
        <div class="grid w-full justify-items-end">
            <!-- Save -->
            <tilled-button
                data-test-id="add-merchant-save-Btn"
                bgColor="primary"
                [rounded]="true"
                buttonText="Add Merchant"
                (buttonClick)="addMerchantClicked()"
                [whiteText]="true"
            ></tilled-button>
        </div>
    </ng-container>
</div>
