/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiKey { 
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The actual value of the API key to be included in the `tilled-api-key` header for authentication. `secret` keys will have a redacted value except when initially created.
     */
    value: string;
    /**
     * The string that you assign to describe the resource. It defaults to \'`type` Key\' (e.g. \'Secret Key\' and \'Publishable Key\')
     */
    name: string;
    /**
     * The type of API key.  `secret` can be used to authenticate all endpoints. `publishable` is used in your frontend application and can only access a handful of endpoints (e.g. confirm a Payment Intent).
     */
    type: ApiKey.TypeEnum;
    /**
     * The id of the associated account.
     */
    account_id: string;
    /**
     * Time at which the key was last used to authenticate with the API.
     */
    last_used?: string;
    /**
     * The list of scopes to define the access for this key. You may specify `[\'*\']` to enable all scopes, except those that require explicit selection.
     */
    scopes: Array<ApiKey.ScopesEnum>;
}
export namespace ApiKey {
    export type TypeEnum = 'publishable' | 'secret';
    export const TypeEnum = {
        PUBLISHABLE: 'publishable' as TypeEnum,
        SECRET: 'secret' as TypeEnum
    };
    export type ScopesEnum = '*' | 'accounts:read' | 'accounts:write' | 'accounts:delete' | 'api_keys:read' | 'api_keys:write' | 'balance_transactions:read' | 'charges:read' | 'customers:read' | 'customers:write' | 'events:read' | 'payment_intents:read' | 'payment_intents:write' | 'payment_methods:read' | 'payment_methods:write' | 'payouts:read' | 'payouts:write' | 'platform_fees:read' | 'product_codes:read' | 'refunds:read' | 'refunds:write' | 'users:read' | 'users:write' | 'webhook_endpoints:read' | 'webhook_endpoints:write' | 'apple_pay_domains:read' | 'apple_pay_domains:write' | 'bank_accounts:read' | 'bank_accounts:write' | 'files:read' | 'files:write' | 'user_invitations:read' | 'user_invitations:write' | 'subscriptions:read' | 'subscriptions:write' | 'disputes:read' | 'pricing_templates:read' | 'pricing_templates:write' | 'checkout_sessions:read' | 'checkout_sessions:write';
    export const ScopesEnum = {
        STAR: '*' as ScopesEnum,
        ACCOUNTSREAD: 'accounts:read' as ScopesEnum,
        ACCOUNTSWRITE: 'accounts:write' as ScopesEnum,
        ACCOUNTSDELETE: 'accounts:delete' as ScopesEnum,
        API_KEYSREAD: 'api_keys:read' as ScopesEnum,
        API_KEYSWRITE: 'api_keys:write' as ScopesEnum,
        BALANCE_TRANSACTIONSREAD: 'balance_transactions:read' as ScopesEnum,
        CHARGESREAD: 'charges:read' as ScopesEnum,
        CUSTOMERSREAD: 'customers:read' as ScopesEnum,
        CUSTOMERSWRITE: 'customers:write' as ScopesEnum,
        EVENTSREAD: 'events:read' as ScopesEnum,
        PAYMENT_INTENTSREAD: 'payment_intents:read' as ScopesEnum,
        PAYMENT_INTENTSWRITE: 'payment_intents:write' as ScopesEnum,
        PAYMENT_METHODSREAD: 'payment_methods:read' as ScopesEnum,
        PAYMENT_METHODSWRITE: 'payment_methods:write' as ScopesEnum,
        PAYOUTSREAD: 'payouts:read' as ScopesEnum,
        PAYOUTSWRITE: 'payouts:write' as ScopesEnum,
        PLATFORM_FEESREAD: 'platform_fees:read' as ScopesEnum,
        PRODUCT_CODESREAD: 'product_codes:read' as ScopesEnum,
        REFUNDSREAD: 'refunds:read' as ScopesEnum,
        REFUNDSWRITE: 'refunds:write' as ScopesEnum,
        USERSREAD: 'users:read' as ScopesEnum,
        USERSWRITE: 'users:write' as ScopesEnum,
        WEBHOOK_ENDPOINTSREAD: 'webhook_endpoints:read' as ScopesEnum,
        WEBHOOK_ENDPOINTSWRITE: 'webhook_endpoints:write' as ScopesEnum,
        APPLE_PAY_DOMAINSREAD: 'apple_pay_domains:read' as ScopesEnum,
        APPLE_PAY_DOMAINSWRITE: 'apple_pay_domains:write' as ScopesEnum,
        BANK_ACCOUNTSREAD: 'bank_accounts:read' as ScopesEnum,
        BANK_ACCOUNTSWRITE: 'bank_accounts:write' as ScopesEnum,
        FILESREAD: 'files:read' as ScopesEnum,
        FILESWRITE: 'files:write' as ScopesEnum,
        USER_INVITATIONSREAD: 'user_invitations:read' as ScopesEnum,
        USER_INVITATIONSWRITE: 'user_invitations:write' as ScopesEnum,
        SUBSCRIPTIONSREAD: 'subscriptions:read' as ScopesEnum,
        SUBSCRIPTIONSWRITE: 'subscriptions:write' as ScopesEnum,
        DISPUTESREAD: 'disputes:read' as ScopesEnum,
        PRICING_TEMPLATESREAD: 'pricing_templates:read' as ScopesEnum,
        PRICING_TEMPLATESWRITE: 'pricing_templates:write' as ScopesEnum,
        CHECKOUT_SESSIONSREAD: 'checkout_sessions:read' as ScopesEnum,
        CHECKOUT_SESSIONSWRITE: 'checkout_sessions:write' as ScopesEnum
    };
}


