import { Pipe, PipeTransform } from '@angular/core';
import { PaymentIntent } from '../../../../../projects/tilled-api-client/src';

/**
 * Takes a PaymentIntent.PaymentMethodTypesEnum
 */
@Pipe({
  name: 'paymentMethod',
})
export class PaymentMethodPipe implements PipeTransform {
  /**
   *
   * @param paymentMethod PaymentIntent.PaymentMethodTypesEnum
   * @returns
   */
  transform(paymentMethod: PaymentIntent.PaymentMethodTypesEnum): string {
    if (paymentMethod === PaymentIntent.PaymentMethodTypesEnum.CARD) {
      return 'Card';
    } else if (paymentMethod === PaymentIntent.PaymentMethodTypesEnum.ACH_DEBIT) {
      return 'ACH Debit';
    } else if (paymentMethod === PaymentIntent.PaymentMethodTypesEnum.EFT_DEBIT) {
      return 'EFT Debit';
    }
    return '';
  }
}
