<merchant-app-card
    [stepNumber]="!forConsole ? '5' : null"
    [title]="!forConsole ? 'Business Bank Account Details' : null"
    [description]="!forConsole ? 'Enter the bank account information where funds will be deposited and debited' : null"
    [backButton]="!forConsole"
    (backClick)="onBackClicked($event)"
    (continueClick)="onContinueClicked($event)"
    [continueButton]="!forConsole && !(disabled$ | async)"
>
    <form
        data-test-id="bank-account-step"
        class="w-full"
        [formGroup]="bankAccountForm"
    >
        <!-- Bank Account Info Alert -mx-6 -mt-2 -->
        <div class="">
            <fuse-alert
                data-test-id="bankAccountAlert"
                [type]="'basic'"
                [appearance]="'outline'"
                [dismissible]="false"
                [dismissed]="false"
                [name]="'bankAccountAlertBox'">
                <mat-icon class="icon-size-5"
                    fuseAlertIcon
                    [svgIcon]="'heroicons_solid:information-circle'">
                </mat-icon>
                <tilled-heading-h6 fuseAlertTitle>Bank Account Requirements</tilled-heading-h6>
                <div class="flex flex-row">
                    &bull;&nbsp;<tilled-paragraph-p3>Your bank account must be registered with the same legal name or DBA as your merchant account</tilled-paragraph-p3>
                </div>
                <div class="flex flex-row">
                    &bull;&nbsp;<tilled-paragraph-p3>It must be able to receive both ACH credits and debits</tilled-paragraph-p3>
                </div>
            </fuse-alert>
        </div>

        <!-- Bank Account Details -->
        <div class="grid sm:grid-cols-2 grid-cols-1 w-full gap-x-6">
            <!-- first row -->
            <div class="flex flex-col col-span-1">
                <tilled-label-l1 [secondary]="true" class="pt-11">
                    Account Type
                </tilled-label-l1>
                <mat-form-field appearance="standard" class="w-full -mt-4">
                    <mat-select
                        data-test-id="account-type"
                        class="text-p3 text-secondary"
                        [formControlName]="'accountType'"
                    >
                        <mat-option [value]="'checking'">Checking</mat-option>
                        <mat-option [value]="'savings'">Savings</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex flex-col col-span-1"></div>
            <!-- second row -->
            <div class="flex flex-col col-span-1">
                <tilled-input
                    data-test-id="account-holder"
                    class="w-full"
                    name="accountHolder"
                    label="Account Holder or Business Name"
                    tilledLabel="true"
                ></tilled-input>
            </div>
            <div class="flex flex-col col-span-1">
                <tilled-input
                    data-test-id="bank-name"
                    class="w-full "
                    name="bankName"
                    label="Bank Name"
                    tilledLabel="true"
                ></tilled-input>
            </div>
            <!-- third row -->
            <div class="flex flex-col col-span-1">
                <tilled-input
                    data-test-id="account-number"
                    class="w-full"
                    name="accountNumber"
                    label="Account Number"
                    tilledLabel="true"
                    maxLength="20"
                ></tilled-input>
            </div>
            <div class="flex flex-col col-span-1">
                <tilled-input
                    data-test-id="routing-number"
                    class="w-full "
                    name="routingNumber"
                    [label]="routingNumberLabel"
                    tilledLabel="true"
                    maxLength="9"
                    errors="{{bankAccountForm.get('routingNumber').hasError('invalidRoutingNumber')}}"
                    errorMessage="{{routingNumberLabel}} is invalid."
                ></tilled-input>
            </div>
        </div>
        
    </form>
</merchant-app-card>
