<div
    class="
        rounded-3xl
        shadow-light-shadow
        min-w-60 min-h-40
        pt-14
        px-5
        hover:border
        hover:border-tilled-secondary
        cursor-pointer
        {{ styles }}
    "
    (onClick)="onButtonClick()"
>
    <div class="">
        <mat-icon
            [class]="
                selected ? 'text-tilled-neutral-100' : 'text-tilled-secondary'
            "
            [svgIcon]="icon"
        ></mat-icon>
    </div>
    <div>
        <tilled-heading-h5 [secondary]="!selected" [white]="selected">{{
            text
        }}</tilled-heading-h5>
    </div>
</div>
