<tilled-table
    [data]="(viewModels$ | async)!"
    [getPageCallback]="
        type === paymentsSummary1 ? getPaymentsReportRuns : getPayoutsReportRuns
    "
    [dataLength]="reportRunsCount$ | async"
    data-test-id="downloads-table"
    [startingPageLimit]="5"
    [accountId]="accountId"
    [hideColumns]="hideColumns"
    [hideZeroState]="true"
></tilled-table>
