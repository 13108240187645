<tilled-table
    [data]="(payoutsViewModel$ | async)!"
    [getPageCallback]="getPayouts"
    [getClickCallback]="rowClickedCallback"
    [dataLength]="payoutsCount$ | async"
    data-test-id="payouts-table"
    [noDataMainText]="'No payout data available'"
    [noDataSecondaryText]="''"
    [hideColumns]="hideColumns"
></tilled-table>
