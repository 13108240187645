import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import {
  Account,
  AccountsService,
  GetAccountRequestParams,
  ListConnectedAccountsRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-merchant-selector',
  templateUrl: './merchant-selector.component.html',
  styleUrls: ['./merchant-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantSelectorComponent extends ComponentBase implements OnInit {
  @Input() includeAllOption = false;
  @Input() includeSelfOption = false;
  @Input() additonalClasses: string;
  @Input() labelName = 'MERCHANT';
  @Input() shouldDisableAccount: (account: Account) => boolean;
  @Output() setMerchantAccount: EventEmitter<Account> = new EventEmitter();
  @Input() inputAppearance: string = null;
  @Input() includeSearch = true;

  @ViewChild('merchantSelectorInput')
  public merchantSelectorInput: ElementRef;
  public filteredMerchants: Observable<Account[]>;
  public disabledAccountIds: string[] = [];
  public showClear = false;
  public myControl = new FormControl();
  public selectedAccount: Account;
  public isLoading = false;
  public showRecentMerchants: boolean;
  public recentMerchantAccounts: Observable<Account[]>;
  public recentlyViewedMerchantIds = [];

  constructor(
    private _authService: AuthService,
    private _accountsService: AccountsService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.filteredMerchants = this.getFilteredMerchants(null);

    this.myControl.setValue(this.filteredMerchants);
    const snapshotAccountId = this._activatedRoute.snapshot.queryParamMap.get('accountId') || null;
    if (snapshotAccountId != null) {
      const getAccountParams: GetAccountRequestParams = {
        tilledAccount: snapshotAccountId,
      };
      this._accountsService
        .getAccount(getAccountParams)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.selectedAccount = res;
          this.myControl.setValue(res);
        });
    }
  }

  getFilteredMerchants(q: string): Observable<Account[]> {
    if (!q) {
      // just emit null so the search clears and sets back to
      // the partner account id.
      this.setMerchantAccount.emit(null);
    }

    this.isLoading = true;
    const listConnectedAccountsParams: ListConnectedAccountsRequestParams = {
      tilledAccount: this._authService.user.account_id,
      q: q,
      metadata: { name: 'asc' },
      offset: 0,
      limit: 100,
    };
    let paginatedAccounts$ = this._accountsService.listConnectedAccounts(listConnectedAccountsParams);

    let currentAccount$: Observable<any> = of('noop');

    if (this.includeSelfOption) {
      const snapshotAccountId = this._activatedRoute.snapshot.queryParamMap.get('accountId') || null;
      const getAccountParams: GetAccountRequestParams = {
        tilledAccount: snapshotAccountId,
      };
      currentAccount$ = this._accountsService.getAccount(getAccountParams);
    }

    return forkJoin({
      paginatedAccounts: paginatedAccounts$,
      currentAccount: currentAccount$,
    }).pipe(
      map((result) => {
        this.isLoading = false;
        const accounts = result.paginatedAccounts.items;
        if (this.includeSelfOption) {
          // Feels like we should make sure that '?q' filter
          // actually works for result.currentAccount.name
          // but since so many partner accounts have an empty name
          // we'll leave it in for now.
          accounts.unshift(result.currentAccount);
        }

        this.recentMerchantAccounts = this.getRecentMerchantAccounts(accounts);

        this.disabledAccountIds = [];
        if (this.shouldDisableAccount) {
          for (const acct of accounts) {
            if (this.shouldDisableAccount(acct)) {
              this.disabledAccountIds.push(acct.id);
            }
          }
        }

        return accounts.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
      }),
    );
  }

  displayFn(account: Account) {
    return account?.name;
  }

  changeInput(value) {
    this.isLoading = true;
    this.filteredMerchants = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      switchMap(() =>
        typeof value === 'string' ? this.getFilteredMerchants(value) : this.getFilteredMerchants(value?.name),
      ),
    );

    if (value) {
      this.showClear = true;
    } else {
      this.showClear = false;
    }
    this.isLoading = false;
  }

  merchantSelected(event: MatAutocompleteSelectedEvent): void {
    this.showClear = true;
    this.selectedAccount = event.option.value;
    this.setMerchantAccount.emit(this.selectedAccount);
    this.addRecentlyViewedMerchants(this.selectedAccount.id);
    (this.merchantSelectorInput.nativeElement as HTMLElement).blur();
  }

  clearSearchText() {
    this.showClear = false;
    this.myControl.setValue(null);
    this.setMerchantAccount.emit(null);
  }

  // Get recent merchants from storage
  getRecentMerchantIds() {
    this.isLoading = true;
    if (localStorage.getItem('recent-merchants')) {
      this.recentlyViewedMerchantIds = JSON.parse(localStorage.getItem('recent-merchants'));
    }
    this.isLoading = false;
  }

  getRecentMerchantAccounts(accounts): Observable<Account[]> {
    this.getRecentMerchantIds();
    if (this.recentlyViewedMerchantIds !== null) {
      if (this.recentlyViewedMerchantIds.length > 0) {
        const recentAccounts = accounts.filter((a) => this.recentlyViewedMerchantIds.includes(a.id));

        if (recentAccounts.length === 0) {
          this.showRecentMerchants = false;
        } else {
          this.showRecentMerchants = true;
        }

        return recentAccounts.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
      }
    }
  }

  // Set recent merchants to storage
  addRecentlyViewedMerchants(accountId: string) {
    if (this.recentlyViewedMerchantIds && this.recentlyViewedMerchantIds.length >= 10) {
      this.removeOldestMerchant();
    }
    if (!this.merchantExistsInRecentlyViewed(accountId)) {
      this.recentlyViewedMerchantIds.push(accountId);
    }

    localStorage.setItem('recent-merchants', JSON.stringify(this.recentlyViewedMerchantIds));
    // this.recentMerchantAccounts = this.getRecentMerchantAccounts();
  }

  // Reverse order and pop the oldest when trying to add 11th item
  removeOldestMerchant() {
    let reverseList = [];
    for (let i = 0; this.recentlyViewedMerchantIds.length > 0; i++) {
      i = this.recentlyViewedMerchantIds.pop();
      reverseList.push(i);
    }
    reverseList.pop();
    for (let i = 0; reverseList.length > 0; i++) {
      i = reverseList.pop();
      this.recentlyViewedMerchantIds.push(i);
    }
  }

  //Don't add one if it's already on the list
  merchantExistsInRecentlyViewed(accountId: string): boolean {
    if (this.recentlyViewedMerchantIds && this.recentlyViewedMerchantIds.includes(accountId)) {
      return true;
    } else {
      return false;
    }
  }
}
