import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { environment } from 'environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { ApiModule, Configuration } from '../../projects/tilled-api-client/src';
import { httpInterceptorProviders } from './core/interceptors';
import { AuthService } from './core/services/auth.service';
import { BrandingService } from './core/services/branding.service';

export const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

export function initTailwindThemeConfig(brandingService: BrandingService) {
  return () => brandingService.setBranding();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),

    // Tilled API Client
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.api,
          accessToken: () => {
            return AuthService.getAccessToken();
          },
        }),
    ),
  ],
  providers: [
    httpInterceptorProviders,
    // This calls the branding service faster than AppComponent ngOnInit
    BrandingService,
    {
      provide: APP_INITIALIZER,
      useFactory: initTailwindThemeConfig,
      deps: [BrandingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // This fixes all "empty" fields to return null instead of empty string.
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null) => {
        fn(value === '' ? null : value);
      };
    };
  }
}
